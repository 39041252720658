import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unslugify'
})
export class UnslugifyPipe implements PipeTransform {

  transform(value: any): any {
    if (!value) {
      return value;
    }
    return value
      .split('-')
      .join(' ')
      .replace(/\w\S*/g, (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
  }

}
