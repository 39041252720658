<div class="address-bg overflow-hidden border-radius-address height-100 width-100 pb-3">
  <div class="address">
    <!-- Modal header start -->
    <div class="modal__header">
      <div class="closeBtn" (click)="closeModal()">
        <span class="custom-icon-arrow-left"></span><label>{{ !newAddress ? "Save New Address" : "Change Delivery Address" }}</label>
      </div>
    </div>
    <!--Modal header End-->
    <!--Modal Body Start-->
    <div class="modal__body">
      <div class="container">
        <div  [ngClass]="!newAddress ? 'd-none' : 'location__title pb-3'">
          <div class="row">
            <div class="location-image ">
              <img class="img-fluid" src="assets/imgs/angels/Location-icon.png" alt="APC Photo 1">
            </div>
            <div class="location__description">
              {{ addressDetails?.address_1 }}, {{ addressDetails?.barangay?.title }}, {{ addressDetails?.city?.title }}
            </div>
          </div>
        </div>
        <div [ngClass]="!newAddress ? 'body_title pb-5' : 'd-none'">Recepients Details</div>
        <form [formGroup]="addressForm" class="inputstyle">
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group row">
                <label class="col-sm-12 form-label">First Name *</label>
                <div class="col-sm-12">
                  <input 
                    maxlength="50" 
                    class="form-control"
                    type="text" 
                    formControlName="recipient_first_name"
                    [class.is-invalid]="fieldErrorMessage(addressForm, 'recipient_first_name')"
                  >
                  <div class="invalid-feedback">
                    {{ fieldErrorMessage(addressForm, "recipient_first_name") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group row">
                <label class="col-sm-12 form-label">Last Name *</label>
                <div class="col-sm-12">
                  <input 
                    maxlength="50" 
                    class="form-control" 
                    type="text" 
                    formControlName="recipient_last_name"
                    [class.is-invalid]="fieldErrorMessage(addressForm, 'recipient_last_name')"
                  >
                  <div class="invalid-feedback">
                    {{ fieldErrorMessage(addressForm, "recipient_last_name") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-12 form-label">Email *</label>
            <div class="col-sm-12 col-md-12">
              <input 
                maxlength="50" 
                class="form-control" 
                type="email" 
                formControlName="recipient_email"
                [class.is-invalid]="fieldErrorMessage(addressForm, 'recipient_email')"
              >
              <div class="invalid-feedback">
                {{ fieldErrorMessage(addressForm, "recipient_email") }}
              </div>
            </div>
          </div> 
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group row">
                <label class="col-sm-12 form-label">Mobile No. </label>
                <div class="col-sm-12">
                  <input 
                    maxlength="13"
                    minlength="11" 
                    appContactNumber
                    class="form-control" 
                    type="tel" 
                    formControlName="recipient_mobile"
                    placeholder="09XXXXXXXXX"
                    [class.is-invalid]="fieldErrorMessage(addressForm, 'recipient_mobile')"
                  >
                  <div class="invalid-feedback">
                    {{ fieldErrorMessage(addressForm, "recipient_mobile") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group row">
                <label class="col-sm-12 form-label">Landline No. </label>
                <div class="col-sm-12">
                  <input 
                    maxlength="10"
                    minlength="9" 
                    appContactNumber
                    class="form-control" 
                    type="tel" 
                    formControlName="recipient_landline"
                    [class.is-invalid]="fieldErrorMessage(addressForm, 'recipient_landline')"
                    placeholder="02XXXXXXXX"
                  >
                  <div class="invalid-feedback">
                    {{ fieldErrorMessage(addressForm, "recipient_landline") }}
                  </div>
                </div>
              </div>
            </div>
          </div>  
          <div class="form-group row">
            <label class="col-sm-12 form-label">Save Address Name as: *</label>
            <div class="col-sm-12">
              <input 
                maxlength="50" 
                class="form-control" 
                type="text" 
                placeholder="ex. Jenna's House" 
                formControlName="name"
                [class.is-invalid]="
                fieldErrorMessage(addressForm, 'name')"
              >
              <div class="invalid-feedback">
                {{ fieldErrorMessage(addressForm, "name") }}
              </div>
            </div>
          </div> 
          <div class="body_title py-5">Delivery Address</div>   
          <div class="form-group row">
            <label class="col-sm-12 form-label">Address 1 *</label>
            <div class="col-sm-12">
              <input 
                maxlength="150" 
                class="form-control" 
                type="text" 
                formControlName="delivery_address"
                [class.is-invalid]="
                fieldErrorMessage(addressForm, 'delivery_address')"
              >
              <div class="invalid-feedback">
                {{ fieldErrorMessage(addressForm, "delivery_address") }}
              </div>
            </div>
          </div>   
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group row">
                <label class="col-sm-12 form-label">Province *</label>
                <div class="col-sm-12">
                  <!-- <input maxlength="150" class="form-control" type="text" formControlName="delivery_state"> -->

                  <div ngbDropdown>
                    <button
                      [attr.disabled]="!this.branchLocation.delivery_state || null"
                      class="btn form-dropdown"
                      ngbDropdownToggle
                    >
                      <label>{{
                        addressForm.get("delivery_city").value?.title ||
                          "Select Province"
                      }}</label>
                    </button>
                    <div ngbDropdownMenu>
                      <button
                        ngbDropdownItem
                        *ngFor="let province of this.branchLocation.delivery_state"
                        (click)="setBranch(province, 'delivery_city')"
                      >
                        {{ province?.title }}
                      </button>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group row">
                <label class="col-sm-12 form-label">City *</label>
                <div class="col-sm-12">
                  <div ngbDropdown>
                    <button
                      [attr.disabled]="
                        !this.addressForm.get('delivery_city')?.value || null
                      "
                      class="btn form-dropdown"
                      ngbDropdownToggle
                    >
                      <label>{{
                        addressForm.get("delivery_barangay").value?.title ||
                          "Select City"
                      }}</label>
                    </button>
                    <div ngbDropdownMenu>
                      <button
                        ngbDropdownItem
                        *ngFor="
                          let city of this.addressForm.get('delivery_city')?.value
                            ?.cities
                        "
                        (click)="setBranch(city, 'delivery_barangay')"
                      >
                        {{ city?.title }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group row">
                <label class="col-sm-12 form-label">Barangay *</label>
                <div class="col-sm-12">
                  <div ngbDropdown>
                    <button
                      [attr.disabled]="
                        !this.addressForm.get('delivery_barangay')?.value || null
                      "
                      class="btn form-dropdown"
                      ngbDropdownToggle
                    >
                      <label>{{
                        addressForm.get("branch").value?.title || "Select Barangay"
                      }}</label>
                    </button>
                    <div ngbDropdownMenu>
                      <button
                        ngbDropdownItem
                        *ngFor="
                          let brgy of this.addressForm.get('delivery_barangay')
                            ?.value?.barangays
                        "
                        (click)="setBranch(brgy, 'branch')"
                      >
                        {{ brgy?.title }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group row">
                <label class="col-sm-12 form-label">Company</label>
                <div class="col-sm-12">
                  <input 
                    maxlength="50" 
                    class="form-control" 
                    type="text" 
                    formControlName="delivery_company"
                    [class.is-invalid]="
                    fieldErrorMessage(addressForm, 'delivery_company')"              
                  >
                  <div class="invalid-feedback">
                    {{ fieldErrorMessage(addressForm, "delivery_company") }}
                  </div>
                </div>
              </div>
            </div>
          </div>       
        </form>
      </div>
    </div>
    <!--Modal Body End-->
    <!--Modal Footer Start-->
    <div class="modal__footer d-flex justify-content-center" *ngIf="!newAddress">
      <button class="btn btn-success btn-address" [disabled]="
      !addressForm.valid" type="submit" (click)="register()">Save to Address Book</button>
    </div>
    <div class="modal__footer d-flex justify-content-center" *ngIf="newAddress">
      <button class="btn btn-success btn-address width-35 mx-3" (click)="register()">Confirm Delivery Address</button>
      <a class="buttonstyle width-35 mx-3" routerLink="/saved-addresses" (click)="closeModal()">Saved Address Book</a>
    </div>
    <!--Modal Footer End-->
  </div>
</div>
