import { ImagePipe } from './image/image.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SanitizeUrlPipe } from './sanitize-url/sanitize-url.pipe';
import { TimeFormatterPipe } from './time-formatter/time-formatter.pipe';
import { HtmlStripperPipe } from './html-stripper/html-stripper.pipe';
import { UnslugifyPipe } from './unslugify/unslugify.pipe';
import { EncodeURIComponentPipe } from './encode-uricomponent/encode-uricomponent.pipe';
import { ParseIntPipe } from './parse-int/parse-int.pipe';
import { PromoPriceDifferencePipe } from './promo-price-difference/promo-price-difference.pipe';
import { FreeItemComponentPipe } from './free-item/free-item.pipe';
import { PriceComponentPipe } from './price/price.pipe';
import { FilterComponentPipe } from './filter/filter.pipe';
import { SortComponentPipe } from './sort/sort.pipe';
import { SafePipe } from './safe.pipe';
import { SanitizeHtmlPipe } from './sanitize-html/sanitize-html.pipe';

@NgModule({
  declarations: [
    ImagePipe,
    SanitizeUrlPipe,
    TimeFormatterPipe,
    HtmlStripperPipe,
    UnslugifyPipe,
    EncodeURIComponentPipe,
    ParseIntPipe,
    PromoPriceDifferencePipe,
    FreeItemComponentPipe,
    PriceComponentPipe,
    FilterComponentPipe,
    SortComponentPipe,
    SafePipe,
    SanitizeHtmlPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ImagePipe,
    TimeFormatterPipe,
    SanitizeUrlPipe,
    HtmlStripperPipe,
    UnslugifyPipe,
    EncodeURIComponentPipe,
    ParseIntPipe,
    PromoPriceDifferencePipe,
    FreeItemComponentPipe,
    PriceComponentPipe,
    FilterComponentPipe,
    SortComponentPipe,
    SafePipe,
    SanitizeHtmlPipe,
  ]
})
export class PipesModule { }
