import * as tslib_1 from "tslib";
import { isPlatformBrowser } from '@angular/common';
import { Validators } from '@angular/forms';
import { OnInit } from '@angular/core';
export class SelectBranchComponent {
    constructor(apiService, storeService, modalService, formBuilder, loaderService, cartService, toastService, platformId) {
        this.apiService = apiService;
        this.storeService = storeService;
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.loaderService = loaderService;
        this.cartService = cartService;
        this.toastService = toastService;
        this.platformId = platformId;
        this.fullAddress = '';
        this.disableDropdownButtons = false;
        this.savedSuccess = false;
    }
    onKeydownHandler(event) {
        this.modalService.dismissAll();
    }
    ngOnInit() {
        console.log('select branch user log', this.user);
        this.branchForm = this.formBuilder.group({
            province: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
            city: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
            brgy: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
            branch: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
            selected_branch: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
        });
        // this.getBranches();
        this.setChosenBranchData();
        if (isPlatformBrowser(this.platformId)) {
            this.getProvinces();
        }
    }
    // async getBranches() {
    //   try {
    //     const branchList = await this.apiService.request({
    //       method: 'GET',
    //       url: '/branches'
    //     });
    //     const sortedBranch = branchList.data.sort((a, b) => a.attributes.title.localeCompare(b.attributes.title));
    //     this.branches = sortedBranch;
    //   } catch (err) {
    //     console.log(err);
    //   }
    // }
    setChosenBranchData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.fullAddress && !this.user) {
                this.branchForm.get('fullAddress').setValue(this.fullAddress);
                this.branchForm.get('fullAddress').updateValueAndValidity();
            }
            else if (this.user) {
                if (this.user.addresses.length) {
                    const userAddress = this.user.addresses[0].address_1;
                    this.branchForm.get('fullAddress').setValue(userAddress);
                }
            }
            const branchLocation = yield this.storeService.getItem('branchLocation');
            if (!branchLocation) {
                return;
            }
            this.disableDropdownButtons = true;
            this.branchForm.setValue(Object.assign({}, branchLocation));
            this.branchForm.updateValueAndValidity();
        });
    }
    getProvinces() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // this.loaderService.showAsyncLoader();
                const provinces = yield this.apiService.request({
                    method: 'GET',
                    url: '/provinces',
                    jsona: true
                });
                this.branchForm.get('province').setValue(provinces);
                this.branchForm.get('province').updateValueAndValidity();
                // this.loaderService.hideAsyncLoader();
                console.log(provinces);
            }
            catch (err) {
                // this.loaderService.hideAsyncLoader();
                this.toastService.showDangerToast('Failed to load locations, Please try again.');
                this.modalService.dismissAll();
                console.log(err);
            }
        });
    }
    setBranch(branchObj, type) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.branchForm.get(type).value) {
                let keys = [];
                switch (type) {
                    case 'province':
                        keys = ['city', 'brgy', 'branch', 'selected_branch'];
                        break;
                    case 'city':
                        keys = ['brgy', 'branch', 'selected_branch'];
                        break;
                    case 'brgy':
                        keys = ['branch', 'selected_branch'];
                        break;
                    case 'branch':
                        keys = ['selected_branch'];
                        break;
                    default:
                        break;
                }
                keys.map(key => {
                    this.branchForm.get(key).setValue(null);
                    this.branchForm.get(key).updateValueAndValidity();
                });
            }
            this.branchForm.get(type).setValue(branchObj);
            this.branchForm.get(type).updateValueAndValidity();
            if (type === 'branch') { // temporary fix only
                const firstBranch = branchObj.branches[0];
                this.branchForm.get('selected_branch').setValue(firstBranch);
                this.branchForm.get('selected_branch').updateValueAndValidity();
            }
        });
    }
    setSelectedBranch() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.branchForm.valid) {
                return;
            }
            try {
                const selectedBranch = this.branchForm.get('selected_branch').value;
                const user = yield this.storeService.getItem('user');
                yield this.storeService.setItem('branchLocation', this.branchForm.value);
                yield this.storeService.setItem('brgy_segment', this.branchForm.value.branch.segment);
                this.loaderService.showAsyncLoader();
                const storedBranchSegment = yield this.storeService.getItem('branch');
                if (storedBranchSegment !== selectedBranch.segment) {
                    if (Array.isArray(this.cartService.cartStorage)) {
                        if (this.cartService.cartStorage.length) {
                            if (user) {
                                yield this.cartService.removeAllCartItems();
                            }
                            else {
                                yield this.cartService.emptyCart();
                            }
                        }
                    }
                }
                yield this.storeService.setItem('branch', selectedBranch.segment);
                yield this.storeService.removeItem('checkout_details');
                this.savedSuccess = true;
                setTimeout(() => {
                    // this.modalService.dismissAll();
                    this.loaderService.hideAsyncLoader();
                    window.location.reload();
                }, 500);
            }
            catch (err) {
                this.loaderService.hideAsyncLoader();
                this.toastService.showDangerToast('Failed to change branch, Please try again.');
                console.log(err);
            }
        });
    }
    removeDisabledState() {
        this.disableDropdownButtons = false;
    }
    closeModal() {
        console.log('close');
        this.modalService.dismissAll();
    }
}
