import { ScrollListenerService } from '../../services/scroll-listener/scroll-listener.service';
import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, PLATFORM_ID, Inject, Input } from '@angular/core';

@Component({
  selector: 'app-back-to-top',
  templateUrl: './back-to-top.component.html',
  styleUrls: ['./back-to-top.component.scss']
})
export class BackToTopComponent implements OnInit {

  scrollTopValue = 0;

  @Input() routerUrl;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private scrollListenerService: ScrollListenerService,
  ) { }

  ngOnInit() {
    this.scrollListenerService.scrollListenerSubject.subscribe(scrollY => {
      this.scrollTopValue = scrollY;
    });
  }

  scrollTop() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    }
  }

}
