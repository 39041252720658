import * as tslib_1 from "tslib";
import * as i0 from "@angular/core";
import * as i1 from "../api/api.service";
import * as i2 from "../store/store.service";
export class BranchLocationService {
    constructor(apiService, storeService) {
        this.apiService = apiService;
        this.storeService = storeService;
        this.isUser = false;
    }
    getProvinces() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.provinces) {
                return this.provinces;
            }
            try {
                const provinces = yield this.apiService.request({
                    method: 'GET',
                    url: '/provinces',
                    jsona: true
                });
                this.provinces = provinces;
                return provinces;
            }
            catch (err) {
                console.log(err);
            }
        });
    }
    getCities(province) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log(province);
            if (this.cities) {
                return this.cities;
            }
            if (!province) {
                return;
            }
            try {
                const cities = yield this.apiService.request({
                    method: 'GET',
                    url: `/provinces/${province}?include=cities`,
                    jsona: true
                });
                this.cities = cities;
                return cities;
            }
            catch (err) {
                console.log(err);
            }
        });
    }
    getBarangays(city) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.barangays) {
                return this.barangays;
            }
            if (!city) {
                return;
            }
            try {
                const barangays = yield this.apiService.request({
                    method: 'GET',
                    url: `/cities/${city}?include=barangays`,
                    jsona: true
                });
                this.barangays = barangays;
                return barangays;
            }
            catch (err) {
                console.log(err);
            }
        });
    }
    getBranches(barangay) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.branches) {
                return this.branches;
            }
            if (!barangay) {
                return;
            }
            try {
                const branches = yield this.apiService.request({
                    method: 'GET',
                    url: `/barangays/${barangay}?include=branches`,
                    jsona: true
                });
                this.branches = branches;
                return branches;
            }
            catch (err) {
                console.log(err);
            }
        });
    }
    getProfileLocations() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.profileLocations) {
                return this.profileLocations;
            }
            try {
                const profileLocations = yield this.apiService.request({
                    method: 'GET',
                    url: '/profile-locations',
                    jsona: true
                });
                this.profileLocations = profileLocations;
                return profileLocations;
            }
            catch (err) {
                console.log(err);
            }
        });
    }
    setUserBranchLocation(isLogin) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const deliveryType = yield this.storeService.getItem('deliveryType');
            if (deliveryType === 'pick-up') {
                return;
            }
            const user = yield this.storeService.getItem('user');
            const branchLocation = yield this.storeService.getItem('branchLocation');
            if (!user) {
                return;
            }
            if (branchLocation && !isLogin) {
                if (!branchLocation.isUser) {
                    return;
                }
            }
            if (!user.profile.province || !user.profile.city || !user.profile.barangay) {
                return;
            }
            const provinces = yield this.getProvinces();
            // const cities = await this.getCities();
            // const barangays = await this.getBarangays();
            const userProfileLocations = {
                province: user.profile.province,
                city: user.profile.city,
                barangay: user.profile.barangay,
                address_1: user.profile.address.address_1,
                company: user.profile.address.company,
                last_name: user.profile.last_name,
                first_name: user.profile.first_name,
                email: user.email,
                mobile: user.profile.address.mobile,
                id: user.profile.address.id
            };
            console.log(user);
            const filteredBranchLocation = {
                branch: null,
                brgy: null,
                city: null,
                province: provinces,
                selected_branch: null,
                isUser: true
            };
            filteredBranchLocation.city = filteredBranchLocation.province.filter(province => province.id === userProfileLocations.province.id)[0];
            console.log(filteredBranchLocation.city);
            filteredBranchLocation.city = yield this.getCities(filteredBranchLocation.city.id);
            if (!filteredBranchLocation.city) {
                return;
            }
            filteredBranchLocation.brgy = filteredBranchLocation.city.cities.filter(city => city.id === userProfileLocations.city.id)[0];
            filteredBranchLocation.brgy = yield this.getBarangays(filteredBranchLocation.brgy.id);
            if (!filteredBranchLocation.brgy) {
                return;
            }
            filteredBranchLocation.branch = filteredBranchLocation.brgy.barangays.filter(brgy => brgy.id === userProfileLocations.barangay.id)[0];
            if (filteredBranchLocation.branch && filteredBranchLocation.branch.id) {
                filteredBranchLocation.branch = yield this.getBranches(filteredBranchLocation.branch.id);
                filteredBranchLocation.selected_branch = filteredBranchLocation.branch.branches[0];
                yield this.storeService.setItem('savedAddress', userProfileLocations);
                yield this.storeService.setItem('branchLocation', filteredBranchLocation);
                yield this.storeService.setItem('branch', filteredBranchLocation.selected_branch.segment);
                yield this.storeService.setItem('brgy_segment', filteredBranchLocation.branch.segment);
                this.branch = filteredBranchLocation.selected_branch.segment;
            }
            this.branchLocation = filteredBranchLocation;
        });
    }
    getBranchData(branchSegment) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const selectedBranch = yield this.apiService.request({
                    method: 'GET',
                    url: `/branches/${branchSegment}`,
                    jsona: true
                });
                const branchLocation = yield this.storeService.getItem('branchLocation');
                branchLocation.selected_branch = selectedBranch;
                yield this.storeService.setItem('branchLocation', branchLocation);
            }
            catch (err) {
                console.log(err);
            }
        });
    }
    setServiceBranch() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.branch = yield this.storeService.getItem('branch');
            this.branchLocation = yield this.storeService.getItem('branchLocation');
        });
    }
    getStoresUrl() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const provinces = yield this.apiService.request({
                method: 'GET',
                url: '/province-branches-for-location-page',
                jsona: true
            });
            console.log('provinces => ', provinces);
            if (!provinces || provinces.length <= 0)
                return;
            this.defaultStoresUrl = `/locations/${provinces[0].id}`;
        });
    }
}
BranchLocationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BranchLocationService_Factory() { return new BranchLocationService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.StoreService)); }, token: BranchLocationService, providedIn: "root" });
