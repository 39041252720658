import { isPlatformBrowser } from '@angular/common';
import { BranchLocationService } from './../../services/branch-location/branch-location.service';
import { ApiService } from 'src/app/services/api/api.service';
import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  year: number;
  location = {
    title: '',
    segment: ''
  };
  currentRoute = '/';
  email: FormControl
  socialLinks;
  constructor(
    public apiService: ApiService,
    public branchLocationService: BranchLocationService,
    public router: Router,
    private toastService: ToastService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    
    router.events.subscribe((val) => {
      // see also
      // console.log(val instanceof NavigationEnd);
      this.currentRoute = router.url;
      // console.log('current route => ', this.currentRoute);
      // console.log('router => ', router.url)
    });
    this.year = new Date().getFullYear();

    this.email = new FormControl('', [Validators.required, Validators.email]);
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.setDefaultLocation();
    }
    this.getSocialLinks();  
  }


  async getProvinces() {
    try {
      const branchProvinces = await this.apiService.request({
        method: 'GET',
        url: '/province-branches-for-location-page',
        jsona: true
      });
      const filteredBranchProvinces = branchProvinces.filter(branchProvince => branchProvince.branches.length);
      console.log(filteredBranchProvinces);
      return filteredBranchProvinces;
      // this.appendToMenu('Stores', filteredBranchProvinces);
    } catch (err) {
      console.log(err);
    }
  }

  // appendToMenu(title, arr) {
  //   this.menus.map(menu => {
  //     if (menu.title === title) {
  //       menu['children'] = [...arr]
  //     }
  //   });
  // }

  async setDefaultLocation() {
    let provinces = await this.getProvinces();
    console.log('provinces =>', provinces);
    // if (!provinces) {
    //   await this.branchLocationService.getProvinces();
    //   provinces = this.branchLocationService.provinces;
    // }
    if (provinces && provinces.length && provinces.length > 0) {
      this.location.title = provinces[0].title;
      this.location.segment = provinces[0].segment;
    }
  }

  async subscribe() {
    try {
      if (this.email.invalid) {
        this.toastService.showDangerToast('Invalid email')
        return;
      }
      const { message }:any = await this.apiService.postRequest({url:'/newsletter/subscribe', body:{email: this.email.value}})
      console.log('message =>', message)
      if (message) {
        this.toastService.showSuccessToast(message);
        return;
      }
      this.toastService.showSuccessToast('You are now subscribed to our news letter!')
    } catch (err) {
      this.apiService.showErrors(err);
    }
  }

  async getSocialLinks() {
    const socialLinks = await this.apiService.request({
      method: 'GET',
      url: '/value-store/social',
      // jsona: true
    });
    console.log('social links => ', socialLinks);
    this.socialLinks = socialLinks;
  }

}
