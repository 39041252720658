/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chili-count.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./chili-count.component";
var styles_ChiliCountComponent = [i0.styles];
var RenderType_ChiliCountComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChiliCountComponent, data: {} });
export { RenderType_ChiliCountComponent as RenderType_ChiliCountComponent };
function View_ChiliCountComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "custom-icon-chili mr-2 text-primary"]], null, null, null, null, null))], null, null); }
export function View_ChiliCountComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChiliCountComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chiliArr; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ChiliCountComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chili-count", [], null, null, null, View_ChiliCountComponent_0, RenderType_ChiliCountComponent)), i1.ɵdid(1, 114688, null, 0, i3.ChiliCountComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChiliCountComponentNgFactory = i1.ɵccf("app-chili-count", i3.ChiliCountComponent, View_ChiliCountComponent_Host_0, { chiliCount: "chiliCount" }, {}, []);
export { ChiliCountComponentNgFactory as ChiliCountComponentNgFactory };
