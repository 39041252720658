import { ConfirmDialogComponent } from './../confirm-dialog/confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { BranchLocationService } from './../../services/branch-location/branch-location.service';
import { UserService } from './../../services/user/user.service';
import { ToastService } from '../../services/toast/toast.service';
import { CartService } from '../../services/cart/cart.service';
import { LoaderService } from '../../services/loader.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StoreService } from '../../services/store/store.service';
import { ApiService } from 'src/app/services/api/api.service';
import { Component, OnInit, Output, EventEmitter, Input, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { FilterComponentPipe } from 'src/app/pipes/filter/filter.pipe';
import { ModalService } from 'src/app/services/modal.service';
import { AccountAddressesComponent } from '../account-addresses/account-addresses.component';

@Component({
  selector: 'app-location-widget',
  templateUrl: './location-widget.component.html',
  styleUrls: ['./location-widget.component.scss']
})
export class LocationWidgetComponent implements OnInit {

  deliveryType = 'delivery';
  selectedBranch: any;
  branchForm: FormGroup;
  fullAddress = '';
  disableDropdownButtons = false;
  savedSuccess = false;

  provinces: any;
  branches: any;

  isLoading: boolean;

  addresses = {
    delivery: null,
    billing: null
  };

  @Input() user;
  @Input() isOnMenu = false;
  @Input() isOnCheckout = false;
  @Output() onCloseWidget  = new EventEmitter();
  addressDetails: any;
  branchLocation: void;
  branch: any;

  constructor(
    private apiService: ApiService,
    private storeService: StoreService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private loaderService: LoaderService,
    public cartService: CartService,
    private toastService: ToastService,
    private userService: UserService,
    private branchLocationService: BranchLocationService,
    private router: Router,
    private filterPipe: FilterComponentPipe,
    private customModalService: ModalService,
    @Inject(PLATFORM_ID) private platformId,
  ) { }

  async ngOnInit() {
    await this.cartService.isBranchValid();
    this.branchForm = this.formBuilder.group({
      province: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
      city: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
      brgy: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
      branch: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
      selected_branch: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
      // fullAddress: ['', Validators.compose([Validators.required, Validators.maxLength(50), FormValidationService.NoWhitespaceValidator])]
    });

    // this.getBranches();

    if (isPlatformBrowser(this.platformId)) {
      this.asyncInit();

      this.userService.userSubject.subscribe(async user => {
        await this.branchLocationService.setUserBranchLocation();
        await this.setChosenBranchData();
      });
    }
  }

  async getBranches() {
    try {
      const branchList = await this.apiService.request({
        method: 'GET',
        url: '/branches',
        jsona: true
      });
      const sortedBranch = branchList.sort((a, b) => a.title.localeCompare(b.title));
      this.branches = sortedBranch;
      console.log('branches => ', this.branches);
    } catch (err) {
      console.log(err);
    }
  }

  async asyncInit() {
    const deliveryType: any = await this.storeService.getItem('deliveryType');    
    const branchStore: any = await this.storeService.getItem('branchLocation')
    
    await this.userService.getUser(true);
    const userStore: any = await this.storeService.getItem('user');
    console.log(userStore);

    this.branch = branchStore
    if (deliveryType) {
      this.deliveryType = deliveryType;
    }
    if(userStore) {
      this.user = true
      if (userStore.addresses['length']) {
        userStore.addresses.filter(address => {
          if (address.type === 'delivery') {
            this.addresses.delivery = address;
          } else {
            this.addresses.billing = address;
          }
        });
      }
    } else {
      this.user = false
    }

    // if (this.deliveryType === 'delivery') {
    //   await this.getProvinces();
    // } else {
    //   this.getBranches();
    // }

    this.addressDetails = userStore
    
    
    await this.setChosenBranchData();

    await this.deliveryTypeChange(this.deliveryType);
    
    if (this.isOnCheckout) {
      this.disableDropdownButtons = false;
    }
  }

  async setChosenBranchData() {

    const branchLocation: any = await this.storeService.getItem('branchLocation');
    if (!branchLocation) {
      return;
    }
    // if (!branchLocation.city) {
    //   return;
    // }
    this.disableDropdownButtons = true;
    delete branchLocation.isUser;
    this.branchForm.setValue({
      ...branchLocation
    });
    this.branchForm.updateValueAndValidity();

  }

  async getProvinces() {
    try {
      // this.loaderService.showAsyncLoader();
      this.isLoading = true;
      const provinces = await this.apiService.request({
        method: 'GET',
        url: '/provinces',
        jsona: true
      });
      this.provinces = provinces;
      this.branchForm.get('province').setValue(provinces);
      this.branchForm.get('province').updateValueAndValidity();
      console.log(provinces);
      await this.updateBranchLocationStore(provinces);
      // this.loaderService.hideAsyncLoader();
      this.isLoading = false;
    } catch (err) {
      // this.loaderService.hideAsyncLoader();
      this.isLoading = false;
      this.toastService.showDangerToast('Failed to load locations, Please try again.');
      this.modalService.dismissAll();
      console.log(err);
    }
  }

  async setBranch(branchObj, type) {
    if (this.branchForm.get(type).value) {
      let keys = [];
      switch (type) {
        case 'province':
          keys = ['city', 'brgy', 'branch', 'selected_branch'];
          break;
        case 'city':
          keys = ['brgy', 'branch', 'selected_branch'];
          break;
        case 'brgy':
          keys = ['branch', 'selected_branch'];
          break;
        case 'branch':
          keys = ['selected_branch'];
          break;
        case 'selected_branch':
          keys = [];
          break;
        default:
          break;
      }
      keys.map(key => {
        this.branchForm.get(key).setValue(null);
        this.branchForm.get(key).updateValueAndValidity();
      });
    }   

    if (branchObj.type === 'provinces') {
      let provinces = await this.apiService.request({
        method: 'GET',
        url: `/provinces/${branchObj.id}?include=cities`,
        jsona: true
      });
      this.provinces = provinces;
      console.log(provinces)
      console.log(this.branchForm.value)
      this.branchForm.get('city').setValue(provinces);
      this.branchForm.get('city').updateValueAndValidity();
      await this.updateBranchLocationStore(provinces);
      console.log(this.branchForm.value)
    } else if (branchObj.type === 'cities') {
      let city = await this.apiService.request({
        method: 'GET',
        url: `/cities/${branchObj.id}?include=barangays`,
        jsona: true
      });
      console.log(city)
      console.log(this.branchForm.value)
      this.branchForm.get(type).setValue(city);
      this.branchForm.get(type).updateValueAndValidity();
      console.log(this.branchForm.value)
    } else if (branchObj.type === 'barangays') {
      let barangay = await this.apiService.request({
        method: 'GET',
        url: `/barangays/${branchObj.id}?include=branches`,
        jsona: true
      });
      branchObj = barangay
      console.log(barangay)
      console.log(this.branchForm.value)
      this.branchForm.get(type).setValue(barangay);
      this.branchForm.get(type).updateValueAndValidity();
      console.log(this.branchForm.value)
    } else {
      this.branchForm.get(type).setValue(branchObj);
      this.branchForm.get(type).updateValueAndValidity();
    }

    if (type === 'branch' && this.deliveryType === 'delivery') { // temporary fix only
      const firstBranch = branchObj.branches[0];
      console.log(firstBranch);      
      this.branchForm.get('selected_branch').setValue(firstBranch);
      this.branchForm.get('selected_branch').updateValueAndValidity();
    }

    if (type === 'selected_branch' && this.deliveryType === 'pick-up') {
      this.branchForm.get('selected_branch').setValue(branchObj);
      this.branchForm.get('selected_branch').updateValueAndValidity();
    }
  }

  async setSelectedBranch() {
    if (!this.branchForm.valid && this.deliveryType === 'delivery') {
      return;
    }
    if (!this.branchForm.get('selected_branch').value && this.deliveryType === 'pick-up') {
      return;
    }
    try {

      console.log('branch form: ', this.branchForm.value);
      
      if (this.deliveryType === 'delivery') {
        await this.storeService.setItem('brgy_segment', this.branchForm.value.branch.segment);
      }

      const selectedBranch = this.branchForm.get('selected_branch').value;
      
      const user = await this.storeService.getItem('user');
      await this.storeService.setItem('branchLocation', {...this.branchForm.value, isUser: false});
      await this.storeService.setItem('deliveryType', this.deliveryType);
      this.cartService.changeDeliveryType(this.deliveryType);
      this.loaderService.showAsyncLoader();

      const storedBranchSegment = await this.storeService.getItem('branch');
      if (storedBranchSegment !== selectedBranch.segment) {

        if (this.deliveryType === 'pick-up') {
          const branchForm = this.branchForm.value;
          ['branch', 'brgy', 'city'].filter(key => branchForm[key] = null);
          await this.storeService.setItem('branchLocation', {...branchForm, isUser: false});
          await this.storeService.removeItem('brgy_segment');
        }


        if (Array.isArray(this.cartService.cartStorage)) {
          if (this.cartService.cartStorage.length) {
            if (user) {
              await this.cartService.removeAllCartItems();
            } else {
              await this.cartService.emptyCart();
            }
          }
        }

      }

      await this.storeService.setItem('branch', selectedBranch.segment);
      await this.storeService.removeItem('checkout_details');
      // await this.modifyCheckoutDetails(this.branchForm.value);
      this.savedSuccess = true;
      setTimeout(() => {
        // this.modalService.dismissAll();
        this.loaderService.hideAsyncLoader();
        if (this.isOnCheckout) {
          this.modalService.dismissAll();
          if (storedBranchSegment !== selectedBranch.segment) {
            this.router.navigate(['/menu/1']).then(() => {
              window.location.reload();
            });
          } else {
            window.location.reload();
          }
          return;
        }
        // window.location.reload();
        this.customModalService.openApcCardInputModal(true)
      }, 500);
    } catch (err) {
      this.loaderService.hideAsyncLoader();
      this.toastService.showDangerToast('Failed to change branch, Please try again.');
      console.log(err);
    }
  }

  async modifyCheckoutDetails(branchValue) {
    const checkoutDetails: any = await this.storeService.getItem('checkout_details');
    if (!checkoutDetails) {
      return;
    }
    checkoutDetails.delivery_barangay = branchValue.branch;
    checkoutDetails.delivery_city = branchValue.brgy;
    checkoutDetails.delivery_state = branchValue.city;
  }

  async updateBranchLocationStore(provinces) {
    const branchLocation: any = await this.storeService.getItem('branchLocation');
    if (!branchLocation) {
      return;
    }
    if (!provinces) {
      return;
    }
    if (!branchLocation.city) {
      return;
    }
    console.log(provinces);
    

    branchLocation.province = provinces;

    await this.storeService.setItem('branchLocation', branchLocation);
  }

  removeDisabledState(type) {
    if (Array.isArray(this.cartService.cartStorage)) {
      if (this.cartService.cartStorage.length) {
        this.disableDropdownButtons = false;
        return;
      }
    }
    this.disableDropdownButtons = false;
  }

  async openSavedAddress() {
    if (isPlatformBrowser(this.platformId)) {

      const modalComponent = this.modalService.open(AccountAddressesComponent, {
        ariaLabelledBy: 'modal-basic-title',
        windowClass: 'modal-cart',
        backdropClass: 'menuitem-backdrop',
        centered: true,
      });
      
      const newAddress = true
      modalComponent.componentInstance.modalCtrl = modalComponent;
      modalComponent.componentInstance.newAddress = newAddress;
      modalComponent.componentInstance.addressDetails = this.addressDetails;
    }
  }

  closeWidget() {
    this.onCloseWidget.emit('');
  }

  async changeLocation(type?) {
    try {
      const modalCtrl = this.modalService.open(ConfirmDialogComponent, {
        ariaLabelledBy: 'modal-basic-title',
        windowClass: 'postcode-confirmation-modal',
        size: 'lg',
        centered: true
      });
      // If you Change your location, all items in the cart might be <b>removed.</b>
      modalCtrl.componentInstance.confirmationMessage = `
        Product availability may vary if you change your current location.
        <br/><br/>
        Do you want to proceed?
      `;
      modalCtrl.componentInstance.title = 'Notice';
      modalCtrl.componentInstance.buttonText.accept = `Yes`;
      modalCtrl.result.then(res => {
        if (!res) {
          return;
        }
        this.router.navigate(['/saved-addresses']);
        this.modalService.dismissAll()
      });
    } catch(err) {
      console.log(err);      
    }
  }

  async deliveryTypeChange(ev) {
    // this.branchForm.reset();
    try {
      // this.loaderService.showAsyncLoader();      
        
      const token = await this.storeService.getItem('token');
      const branchLocation: any = await this.storeService.getItem('branchLocation');
      console.log('des',this.deliveryType, this.disableDropdownButtons, branchLocation);
      if (!branchLocation || !branchLocation['city']) {
        this.disableDropdownButtons = false;
      }      
      

      if (ev === 'delivery' && !this.provinces) {        
        console.log(this.branch, this.addressDetails);  
        await this.getProvinces();             
        // branchLocation.branch = this.addressDetails.profile.barangay
        // branchLocation.brgy = this.addressDetails.profile.city
        // branchLocation.city = this.addressDetails.profile.province
        // console.log('branch location => ', branchLocation, this.addressDetails);                 
        // await this.storeService.setItem('branchLocation', branchLocation);
        if (!this.branch.brgy && this.user) {
          this.changeLocation()
        }
      
      }

      if (ev === 'pick-up' && !this.branches) {
        await this.getBranches();
        console.log('branch location => ', branchLocation, this.addressDetails);
        
        let selectedBranchExists = false;
        this.filterPipe.transform(this.branches, 'pick_up', true).forEach(b => {
          console.log(b);
          console.log(branchLocation.selected_branch.id);
          console.log(b.id == branchLocation.selected_branch.id)
          if (b.id == branchLocation.selected_branch.id) {
            selectedBranchExists = true;
          }
        })
        if (!selectedBranchExists) {
          this.disableDropdownButtons = false;
          this.branchForm.get('selected_branch').setValue(null);
        } else {
          this.disableDropdownButtons = true;
        }
      }

      if (this.provinces) {
        this.branchForm.get('province').setValue(this.provinces);
      }

      await this.storeService.setItem('deliveryType', this.deliveryType);
      this.cartService.changeDeliveryType(this.deliveryType);
      if ((!branchLocation || !branchLocation['city']) && this.deliveryType === 'delivery') {
        return;
      }
      //await this.cartService.recalculateTotal();
      console.log('des',this.disableDropdownButtons);
      
    } catch (err) {
      console.log(err);
    } finally {
      // this.loaderService.hideAsyncLoader();
    }
    // console.log(ev);
  }


}
