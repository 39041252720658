import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api/api.service';
import { BranchLocationService } from 'src/app/services/branch-location/branch-location.service';
import { FormdataService } from 'src/app/services/formdata.service';
import { GlobalService } from 'src/app/services/global.service';
import { LoaderService } from 'src/app/services/loader.service';
import { StoreService } from 'src/app/services/store/store.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { UserService } from 'src/app/services/user/user.service';
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss']
})
export class AddressesComponent implements OnInit {

  modalCtrl: any
  addressForm: FormGroup;
  addressDetails: any
  branchLocations = {
    provinces: [],
    city: [],
    barangay: []
  };
  provinces: any;
  

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    this.closeModal();
  }

  constructor(
    private modalService: NgbModal,
    private apiService: ApiService,
    private storeService: StoreService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private formDataService: FormdataService,
    private userService: UserService,
    private loaderService: LoaderService,
    private router: Router,
    private branchLocationService: BranchLocationService,
    @Inject(PLATFORM_ID) private platformId
  ) { 
    this.addressForm = this.formBuilder.group ({
      name: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
      recipient_first_name: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
      recipient_last_name: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
      recipient_email: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
      recipient_mobile: ['', Validators.compose([Validators.maxLength(13), Validators.minLength(11), Validators.pattern(GlobalService.REGEX_PATTERNS.phoneNumber)])],
      recipient_landline: ['',Validators.compose([Validators.maxLength(10), Validators.minLength(9), Validators.pattern(GlobalService.REGEX_PATTERNS.telephoneNumber)])],
      delivery_address: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],      
      delivery_city: ['', Validators.required],
      delivery_barangay: ['', Validators.required],
      delivery_company: ['', Validators.maxLength(50)],
      delivery_state: ['', Validators.required],
    })
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.loadAccountPage();
      this.getProvinces();
    }
  }
  
  closeModal() {    
    console.log("Click");
    this.modalCtrl.close();
    //this.modalService.dismissAll()
    
  }

  async loadAccountPage() {
    const socialLogin = await this.storeService.getItem('social_login');

    const token: any = await this.storeService.getItem('token');
    if (!token) {
      this.router.navigate(['404']);
      return;
    }

    this.getAddress();  
  }

  async getAddress() {
    try {
      const token = await this.storeService.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      const address = await this.apiService.request({
        method: 'GET',
        url: `/v2/auth/user/saved-addresses/default/edit`,
        jsona: true,
        headers
      });
      this.loaderService.hide();


      const addressForm = {};
      console.log('check', address); 
      console.log('addressDetails: ', this.addressDetails);
           

      this.addressForm.patchValue({
        recipient_first_name: this.addressDetails.first_name,
        recipient_last_name: this.addressDetails.last_name,
        recipient_email: this.addressDetails.email,
        recipient_mobile: this.addressDetails.mobile,
        recipient_landline: this.addressDetails.landline,        
        name: this.addressDetails.name,
        delivery_address: this.addressDetails.address_1,
        delivery_state: this.addressDetails.province,
        delivery_city: this.addressDetails.city,
        delivery_barangay: this.addressDetails.barangay,
        delivery_company: this.addressDetails.company,        
      });

      console.log(this.addressForm.value);
      
    }catch (err) {
      console.log(err);
      if (err.status !== 401) {
        this.showDangerToast(err.error);
      }
      console.log(err);
    }
  }

  async updateAddress() {
    console.log(this.addressForm.value);

    try{
      let landline = this.addressForm.get('recipient_landline').value
      
    if (landline) {
      //let landline2 = landline.replace(/\s/g, "");
      let firstPart = landline.substring(0, 2);
      let secondPart = landline.substring(2, 6);
      let thirdPart = landline.substring(6, 10);
      landline = `${firstPart} ${secondPart} ${thirdPart}`
    }
    console.log('landline =>', landline);

    const token = await this.storeService.getItem('token');
    const headers = { Authorization: `Bearer ${token}` };
    this.loaderService.showAsyncLoader();
    const payload = {
      ...this.addressForm.value,     
      recipient_landline: landline,
      fill_type: this.addressDetails.fill_type,
      delivery_barangay: this.addressForm.value.delivery_barangay.id
    };
    
    console.log(payload);

    const res = await this.apiService.putRequest({
      url: `/v2/auth/user/saved-addresses/${this.addressDetails.id}`,
      body: payload,
      headers,
    })    

    this.branchLocationService.setUserBranchLocation(true);
    setTimeout(() => {            ;
      this.loaderService.hideAsyncLoader();
      window.location.reload();
    }, 1000);
    this.showSuccessToast('Saved Address updated successfully.');    
    } catch (err) {
      console.log(err);
      this.loaderService.hideAsyncLoader();
      if (err.status === 401) {
        return;
      }

      const hasMessageKey = Object.keys(err.error).includes('message');
      // if (hasMessageKey) {
      //   this.toastService.showDangerToast(err.error.message);
      //   return;
      // }
      const errorFields = Object.keys(err.error.errors);
      errorFields.filter(key => {
        err.error.errors[key].filter(errMessage => {
          this.toastService.showDangerToast(errMessage);
        });
      });      
    }
  }

  async setBranch(locationObj, type) {
    console.log(locationObj)
    console.log(type)
    if (this.addressForm.get(`delivery_${type}`).value) {
      let keys = [];
      switch (type) {
        case 'state':
          keys = ['city', 'barangay'];
          this.branchLocations.city = locationObj.cities;
          break;
        case 'city':
          this.branchLocations.barangay = locationObj.barangays;
          keys = ['barangay'];
          break;
        case 'barangay':
          keys = [];
          break;
        default:
          break;
      }
      keys.map(key => {
        this.addressForm.get(`delivery_${key}`).setValue(null);
      });
    }
    
    console.log(this.addressForm.value)

    console.log('type => ', locationObj.type)

    if (locationObj.type === 'provinces') {
      let provinces = await this.apiService.request({
        method: 'GET',
        url: `/provinces/${locationObj.id}?include=cities`,
        jsona: true
      });
      this.provinces = provinces;
      console.log(provinces)
      console.log(this.addressForm.value)
      this.addressForm.get(`delivery_${type}`).setValue(provinces);
      this.addressForm.get(`delivery_${type}`).updateValueAndValidity();
      this.branchLocations.city = provinces.cities;
      // await this.updateBranchLocationStore(provinces);
      console.log(this.addressForm.value)
    } else if (locationObj.type === 'cities') {
      let city = await this.apiService.request({
        method: 'GET',
        url: `/cities/${locationObj.id}?include=barangays`,
        jsona: true
      });
      console.log(this.addressForm.value)
      this.addressForm.get(`delivery_${type}`).setValue(city);
      this.addressForm.get(`delivery_${type}`).updateValueAndValidity();
      this.branchLocations.barangay = city.barangays;
      console.log(this.branchLocations.barangay)
      console.log(this.addressForm.value)
    } else if (locationObj.type === 'barangays') {
      let barangay = await this.apiService.request({
        method: 'GET',
        url: `/barangays/${locationObj.id}?include=branches`,
        jsona: true
      });
      locationObj = barangay
      console.log(barangay)
      console.log(this.addressForm.value)
      this.addressForm.get(`delivery_${type}`).setValue(barangay);
      this.addressForm.get(`delivery_${type}`).updateValueAndValidity();
      console.log(this.addressForm.value)
    } else {
      this.addressForm.get(`delivery_${type}`).setValue(locationObj);
      this.addressForm.get(`delivery_${type}`).updateValueAndValidity();
    }

    // this.addressForm.get(`delivery_${type}`).setValue(locationObj);
    // this.addressForm.get(`delivery_${type}`).updateValueAndValidity();
  }

  async getProvinces() {
    const provinces = await this.branchLocationService.getProvinces();
    // this.provinces = provinces;
    this.branchLocations.provinces = provinces;
    console.log(provinces);
  }

  isFormFieldInvalid(formGroup: FormGroup, fieldName) {
    return formGroup.get(fieldName).errors && (formGroup.get(fieldName).dirty || formGroup.get(fieldName).touched);
  }

  fieldErrorMessage(formGroup: FormGroup, fieldName) {
    const isInvalid = this.isFormFieldInvalid(formGroup, fieldName);
    let message: string;
    if (isInvalid) {
      const keys = Object.keys(formGroup.get(fieldName).errors);
      if (formGroup.get(fieldName).errors.pattern) {
        message = formGroup.get(fieldName).errors.pattern.requiredPattern.toString() === EMAIL_REGEX.toString()
          ? 'Please enter a valid email address.'
          : formGroup.get(fieldName).errors.pattern.requiredPattern.toString() === GlobalService.REGEX_PATTERNS.phoneNumber.toString() ? 'Please enter a valid phone number' : formGroup.get(fieldName).errors.pattern.requiredPattern.toString() === GlobalService.REGEX_PATTERNS.telephoneNumber.toString() ? 'Please enter a valid landline number' : 'Please enter a valid name';
      } else if (fieldName === 'landline' && keys.includes('minlength') && keys.includes('maxlength')) {
        message = 'Invalid Landline No.';
      } else {
        message = 'This field is required';
      }
    } else {
      message = '';
    }
    return message;
  }

  showSuccessToast(message) {
    this.toastService.show(message, { classname: 'bg-success text-light' });
  }

  showDangerToast(message) {
    this.toastService.show(message, { classname: 'bg-danger text-light' });
  }


}
