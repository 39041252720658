/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toast-container.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "./toast-container.component";
import * as i6 from "../../services/toast/toast.service";
var styles_ToastContainerComponent = [i0.styles];
var RenderType_ToastContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToastContainerComponent, data: {} });
export { RenderType_ToastContainerComponent as RenderType_ToastContainerComponent };
function View_ToastContainerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_ToastContainerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToastContainerComponent_3)), i1.ɵdid(1, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.textOrTpl; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ToastContainerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "toast-message"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.textOrTpl; _ck(_v, 0, 0, currVal_0); }); }
function View_ToastContainerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ngb-toast", [["aria-atomic", "true"], ["header", "Notification"], ["role", "alert"]], [[8, "className", 0], [1, "aria-live", 0], [2, "toast", null], [2, "show", null]], [[null, "hide"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("hide" === en)) {
        var pd_0 = (_co.close(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } if (("hide" === en)) {
        var pd_1 = (_co.toastService.remove(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_NgbToast_0, i3.RenderType_NgbToast)), i1.ɵdid(1, 1622016, null, 1, i4.NgbToast, [[8, null], i4.NgbToastConfig], { delay: [0, "delay"], autohide: [1, "autohide"], header: [2, "header"] }, { hideOutput: "hide" }), i1.ɵqud(335544320, 1, { contentHeaderTpl: 0 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ToastContainerComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["text", 2]], 0, 0, null, View_ToastContainerComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_4 = (_v.context.$implicit.delay || 5000); var currVal_5 = true; var currVal_6 = "Notification"; _ck(_v, 1, 0, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.isTemplate(_v.context.$implicit); var currVal_8 = i1.ɵnov(_v, 5); _ck(_v, 4, 0, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.classname; var currVal_1 = i1.ɵnov(_v, 1).ariaLive; var currVal_2 = true; var currVal_3 = true; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_ToastContainerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToastContainerComponent_1)), i1.ɵdid(1, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.toastService.toasts; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ToastContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-toast-container", [], [[2, "ngb-toasts", null]], null, null, View_ToastContainerComponent_0, RenderType_ToastContainerComponent)), i1.ɵdid(1, 114688, null, 0, i5.ToastContainerComponent, [i6.ToastService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); }); }
var ToastContainerComponentNgFactory = i1.ɵccf("app-toast-container", i5.ToastContainerComponent, View_ToastContainerComponent_Host_0, {}, {}, []);
export { ToastContainerComponentNgFactory as ToastContainerComponentNgFactory };
