import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog/confirm-dialog.component';
export class FavoriteItemComponent {
    constructor(userService, modalService, apiService, storeService, cartService, toastService, router, modalRef, loaderService) {
        this.userService = userService;
        this.modalService = modalService;
        this.apiService = apiService;
        this.storeService = storeService;
        this.cartService = cartService;
        this.toastService = toastService;
        this.router = router;
        this.modalRef = modalRef;
        this.loaderService = loaderService;
        this.moreInfo = false;
        this.setInput = '';
        this.isAPCSet = true;
        this.addItemState = false;
        this.isAvail = false;
        this.disableChangeName = false;
        this.disableBtn = false;
        this.apcCard = new FormControl("", [
            Validators.required,
            Validators.pattern("^[0-9]*$"),
            Validators.minLength(3),
        ]);
    }
    ngOnInit() {
        this.editFavorite();
    }
    closeModal() {
        this.modalService.dismissAll();
        this.storeService.removeItem('favSetStore');
        if (this.addItemState) {
            this.router.navigate(['/favorite']);
        }
    }
    getProduct(hashedId, i) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const token = yield this.storeService.getItem('token');
                const branch = yield this.storeService.getItem("branch");
                let headers = {};
                headers = {
                    "x-branch": branch || "all",
                };
                let url = `/v2/products/${hashedId}`;
                const apcCardCode = yield this.storeService.getItem("apc");
                if (this.cartService.hasApcCategory() || apcCardCode) {
                    url += '?only_apc_variant_buy1_take1=1';
                }
                let res = yield this.apiService.request({
                    method: 'GET',
                    url,
                    headers,
                    jsona: true
                });
                console.log(res);
                this.products[i].is_available = res.is_available;
            }
            catch (err) {
                console.log(err);
            }
        });
    }
    editFavorite() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // console.log("ress data => ", productItem);
            this.apcCardCode = yield this.storeService.getItem("apc");
            const token = yield this.storeService.getItem("token");
            console.log("apc card ", this.apcCardCode, token);
            const branch = yield this.storeService.getItem("branch");
            const branchLocation = yield this.storeService.getItem("branchLocation");
            const deliveryType = yield this.storeService.getItem("deliveryType");
            let headers = {};
            headers = {
                "x-branch": branch || "all",
            };
            try {
                const brgy = deliveryType === "delivery"
                    ? yield this.storeService.getItem("brgy_segment")
                    : null;
                const voucherCodes = (yield this.storeService.getItem("voucher_codes")) || [];
                headers = Object.assign({}, headers, { Authorization: `Bearer ${token}` });
                let res = yield this.apiService.request({
                    method: 'GET',
                    url: `/v2/auth/user/favorite-sets/${this.index.id}/edit`,
                    headers,
                });
                let favoriteProdFormat = this.apiService.jsonaFormatter(res);
                this.favoriteSet = favoriteProdFormat;
                console.log("ress edit => ", this.favoriteSet, this.menuSet, this.products);
                this.setInput = this.favoriteSet.name;
                let favProd = [];
                favoriteProdFormat['favorite-set-products'].map((item, index) => {
                    console.log(item, index);
                    if (!item.extra.apc_card || !item.extra.apc_card.product_take1 || item.product.apc_card_double_deal) {
                        this.isAPCSet = false;
                    }
                    else {
                        item.product.apc_card_product = true;
                    }
                    if (item.extra.apc_card) {
                        item.product.apc_card = item.extra.apc_card;
                    }
                    if (item.price.amount != 0) {
                        item.product.quantity = item.quantity || 1;
                        item.product.originalPrice = {
                            price: item.price,
                            quantity: item.quantity || 1
                        };
                    }
                    else {
                        item.product.quantity = item.product.minimum_quantity || 1;
                    }
                    item.product.extra = Object.assign({ dietary_requirements: '' }, item.product.extra);
                    item.product.selected_sub_category = item.product.sub_categories ? item.product.sub_categories[0] : null;
                    item.product.selectedAdditionalProducts = item.extra.additional_products;
                    item.product.selectedVariants = item.extra.variants;
                    item.product.disabled_variants = [];
                    item.product.voucher_code = '';
                    item.product.price = this.getTotalPrice(item.product);
                    item.product = this.setProductVariants(item.product);
                    item.product.toggle = this.moreInfo;
                    favProd.push(item.product);
                    this.getProduct(item.product.id, index);
                });
                this.products = favProd;
                if (this.products[0].apc_card_product) {
                    this.isAPCSet = true;
                }
                if (this.menuSet) {
                    let dataExist = this.products;
                    console.log(dataExist);
                    let itemIncluded = dataExist.find(data => data.id === this.menuProduct.id);
                    console.log('not');
                    this.products = this.menuSet;
                    // if (!itemIncluded) {
                    //   console.log('not');          
                    //   this.products = this.menuSet                
                    // } else {
                    //   this.toastService.show(`${this.menuProduct.title} already exists in the favorite set.`, {
                    //     classname: "bg-danger text-light",
                    //   });
                    // }
                }
                console.log(this.products);
            }
            catch (error) {
                console.log(error);
            }
        });
    }
    getTotalPrice(product) {
        console.log(product);
        let variants = product.selectedVariants;
        if (!variants.length) {
            if (product.originalPrice) {
                let amount = (product.originalPrice.price.amount * product.quantity);
                let format = (Math.round(amount) / 100).toFixed(2);
                console.log(amount, format);
                return { amount: `${amount}`, currency: "PHP", formatted: `₱${format.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` };
                // product.price.amount = amount   
                // product.price.formatted = `₱${format.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` 
            }
            //return {amount: `${amount}`, currency: "PHP", formatted: `₱${format.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
            // return product.price
        }
        if (variants) {
            let amount = (variants[1].price.amount * variants[1].quantity);
            let format = (Math.round(amount) / 100).toFixed(2);
            console.log(amount, format);
            return { amount: `${amount}`, currency: "PHP", formatted: `₱${format.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` };
        }
    }
    deleteSet() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const token = yield this.storeService.getItem("token");
            const branch = yield this.storeService.getItem("branch");
            let headers = {};
            headers = {
                "x-branch": branch || "all",
            };
            headers = Object.assign({}, headers, { Authorization: `Bearer ${token}` });
            try {
                const removeFavorite = yield this.apiService.request({
                    method: 'DELETE',
                    url: `/v2/auth/user/favorite-sets/${this.favoriteSet.id}`,
                    headers,
                });
                this.toastService.show(`${this.favoriteSet.id} successfully deleted set.`, {
                    classname: "bg-success text-light",
                });
                this.modalService.dismissAll();
            }
            catch (error) {
                console.log(error);
            }
        });
    }
    addSetCart() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const token = yield this.storeService.getItem("token");
            const branch = yield this.storeService.getItem("branch");
            const branchLocation = yield this.storeService.getItem("branchLocation");
            const deliveryType = yield this.storeService.getItem("deliveryType");
            let headers = {};
            headers = {
                "x-branch": branch || "all",
            };
            try {
                const brgy = deliveryType === "delivery"
                    ? yield this.storeService.getItem("brgy_segment")
                    : null;
                const voucherCodes = (yield this.storeService.getItem("voucher_codes")) || [];
                console.log(this.products);
                let productFav = this.favoriteSet;
                headers = Object.assign({}, headers, { Authorization: `Bearer ${token}` });
                let res = yield this.apiService.postRequest({
                    url: `/v2/auth/user/favorite-sets/add-to-cart/${productFav.id}`,
                    headers,
                });
                console.log("ress add cart => ", res);
                this.favorite = res.data;
                let unavailable = res.stock;
                let unavail = [];
                unavailable.map(s => {
                    console.log(s);
                    if (s.available === false) {
                        unavail.push(s.product_title + ' ');
                    }
                });
                this.cartService.setCartStorage(res.result);
                this.toastService.show(`Successfully added ${productFav.name} to cart except: ${unavail}`, {
                    classname: "bg-success text-light",
                });
                //await this.cartService.setTake1Items((this.cartService.hasApcCategory() || this.userService.apcCardNumber));
                setTimeout(() => {
                    this.modalService.dismissAll();
                    console.log("wew");
                    this.router.navigate(['/menu/1']).then(() => {
                        //window.location.reload();
                    });
                }, 500);
            }
            catch (error) {
                console.log(error);
            }
        });
    }
    cartSeperator() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const token = yield this.storeService.getItem("token");
            if (this.cartService.allCartEgc) {
                const modalComponent = this.modalService.open(ConfirmDialogComponent, {
                    ariaLabelledBy: 'modal-basic-title',
                    windowClass: 'confirm-dialog-modal',
                    centered: true
                });
                modalComponent.componentInstance.title = 'Hold up!';
                modalComponent.componentInstance.confirmationMessage = 'E-Gift should be purchased seperately. All products/items in your cart will be removed. Do you want to proceed?';
                modalComponent.result.then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    if (res) {
                        if (token) {
                            try {
                                this.loaderService.showAsyncLoader();
                                yield this.cartService.removeAllCartItems();
                                this.loaderService.hideAsyncLoader();
                            }
                            catch (err) {
                                console.log(err);
                                this.loaderService.hideAsyncLoader();
                            }
                        }
                        //this.cartService.emptyCart();
                        yield this.addSetCart();
                        // if (this.router.url.includes('checkout') || this.router.url.includes('review-order')) {
                        //   this.router.navigate(['menu/1']);
                        // }
                        //this.toggleCart();
                    }
                }));
                return;
            }
            yield this.addSetCart();
        });
    }
    updateFavoriteSet() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log("ress data => ", this.products);
            const token = yield this.storeService.getItem("token");
            const branch = yield this.storeService.getItem("branch");
            const branchLocation = yield this.storeService.getItem("branchLocation");
            let headers = {};
            headers = {
                "x-branch": branch || "all",
            };
            let setItem = this.products;
            const setItemPayload = [];
            setItem.filter(item => {
                console.log(item);
                let product = {
                    product: item.id,
                    quantity: item.quantity,
                    apc_card_code: this.apcCardCode,
                };
                if (item.variants && item.variants.length) {
                    let error = false;
                    item.extra.variants.forEach(v => {
                        if (v.quantity < 1) {
                            this.toastService.showDangerToast(`${v.title} has a minimum quantity of 1`);
                            error = true;
                        }
                    });
                    if (error)
                        return;
                    product.variants = item.variants.map((variant) => {
                        return { id: variant.id, quantity: variant.quantity };
                    });
                }
                if (!item.apc_card || !item.apc_card.product_take1) {
                    const selectedVariants = item.selectedVariants;
                    if (selectedVariants && selectedVariants.length) {
                        product.variants = selectedVariants.map(v => {
                            let tempId = v.id.replace(item.id, '');
                            console.log('temp id =>', tempId);
                            let id = tempId.split('-').filter(s => !!s).join('-');
                            return { id, quantity: v.quantity };
                        });
                    }
                }
                else {
                    product.take1 = item.apc_card.product_take1.route_key;
                    let error = false;
                    item.selectedVariants.forEach(v => {
                        if (v.quantity < 1) {
                            this.toastService.showDangerToast(`${v.title} has a minimum quantity of 1`);
                            error = true;
                        }
                    });
                    if (error)
                        return;
                    product.take1_variants = item.apc_card.product_take1.variants.map((variant) => {
                        let tempId = variant.id.replace(item.apc_card.product_take1.route_key, '').replace(item.id, '');
                        console.log('temp id =>', tempId);
                        let id = tempId.split('-').filter(s => !!s).join('-');
                        return { id, quantity: variant.quantity };
                    });
                    const selectedVariants = item.selectedVariants;
                    if (selectedVariants && selectedVariants.length) {
                        product.variants = selectedVariants.map(v => {
                            let tempId = v.id.replace(item.apc_card.product_take1.route_key, '').replace(item.id, '');
                            console.log('temp id =>', tempId);
                            let id = tempId.split('-').filter(s => !!s).join('-');
                            return { id, quantity: v.quantity };
                        });
                    }
                    if (item.additional_products && item.additional_products.length) {
                        product.additional_products = item.additional_products.map((addon) => addon.id);
                    }
                }
                console.log(product);
                setItemPayload.push(product);
            });
            console.log(setItemPayload);
            try {
                const reqBody = {
                    favorite_set: [...setItemPayload],
                    name: this.setInput
                };
                headers = Object.assign({}, headers, { Authorization: `Bearer ${token}` });
                console.log("ress update => ", this.products);
                console.log(reqBody);
                let res = yield this.apiService.putRequest({
                    url: `/v2/auth/user/favorite-sets/${this.favoriteSet.id}`,
                    body: reqBody,
                    headers,
                });
                this.modalService.dismissAll();
                if (this.addItemState) {
                    setTimeout(() => {
                        //this.modalService.dismissAll();  
                        this.router.navigate(['/favorite']).then(() => {
                            this.storeService.setItem('updateFavSet', { activeTab: 'favorite_sets' });
                        });
                    }, 500);
                }
                const successMessage = `Successfully updated favorite sets.`;
                this.toastService.showSuccessToast(successMessage);
            }
            catch (err) {
                console.log(err);
                if (err.status === 401) {
                    return;
                }
                const hasMessageKey = Object.keys(err.error).includes('message');
                // if (hasMessageKey) {
                //   this.toastService.showDangerToast(err.error.name);
                //   return;
                // }
                const errorFields = Object.keys(err.error.errors);
                errorFields.filter(key => {
                    err.error.errors[key].filter(errMessage => {
                        this.toastService.showDangerToast(errMessage);
                    });
                });
            }
        });
    }
    inputChanged(value, i) {
        console.log(value, i, this.products[i]);
        this.addItemState = true;
        //this.products[i].selectedVariants[1] = value    
        let productTotal = this.products[i];
        this.products[i].price = this.getTotalPrice(productTotal);
        console.log(this.products[i], this.getTotalPrice(productTotal));
    }
    close() {
        this.modalService.dismissAll();
        console.log(this.favoriteSet, this.index.id, this.isAPCSet);
        this.storeService.setItem('state', { state: true, id: this.index.id, isAPCSet: this.isAPCSet });
        this.storeService.setItem('favSetStore', this.products);
        this.router.navigate(['/menu/1/angels-pizza']);
    }
    removeItem(index) {
        this.products.splice(index, 1);
        console.log("ress remove ", this.products, index);
        this.addItemState = true;
        if (this.products.length === 0) {
            this.disableBtn = true;
        }
    }
    showVariantsTake1(i) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.moreInfo = !this.moreInfo;
            if (this.moreInfo == false) {
                this.products[i].toggle = false;
            }
            else {
                this.products[i].toggle = true;
            }
            console.log(this.products);
        });
    }
    saveApcCardNumber() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                console.log("saving...");
                if (!this.apcCard.valid) {
                    return;
                }
                yield this.userService.setApcCardNumber(this.apcCard.value);
                this.apcCard.reset();
                this.storeService.setItem('apcProd', this.index);
                yield this.emptyCart();
                window.location.reload();
            }
            catch (err) {
                console.log(err);
            }
        });
    }
    resetApcCardNumber() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.userService.resetApcCardNumber();
            this.storeService.setItem('NonApcProd', this.index);
            yield this.emptyCart();
        });
    }
    emptyCart() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.cartService.cartStorage || !this.cartService.cartStorage.length) {
                window.location.reload();
                return;
            }
            const token = (yield this.storeService.getItem("token")) || null;
            if (token) {
                try {
                    this.loaderService.showAsyncLoader();
                    yield this.cartService.removeAllCartItems();
                    this.loaderService.hideAsyncLoader();
                }
                catch (err) {
                    console.log(err);
                    this.loaderService.hideAsyncLoader();
                }
            }
            yield this.cartService.emptyCart();
            window.location.reload();
        });
    }
    changeSetName() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.disableChangeName = !this.disableChangeName;
        });
    }
    changeValueName(newValue) {
        if (newValue) {
            this.addItemState = true;
        }
    }
    setProductVariants(product) {
        const variants = product.variants;
        if (!variants.length) {
            return product;
        }
        product.variants = this.groupItemByType(variants, 'variants');
        return product;
    }
    groupItemByType(arr, type) {
        const result = arr.reduce((r, a) => {
            a = this.addStateKeys(a);
            if (type === 'variants') {
                if (a.variants.length) {
                    a.variants.map(variant => {
                        variant = this.addStateKeys(variant);
                    });
                }
            }
            r[a.type] = r[a.type] || [];
            r[a.type].push(a);
            return r;
        }, Object.create(null));
        return result;
    }
    addStateKeys(obj) {
        obj.selected = false;
        obj.disabled = false;
        return obj;
    }
}
