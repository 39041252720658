import { Component, OnInit, Input, HostListener } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-location-widget-modal',
  templateUrl: './location-widget-modal.component.html',
  styleUrls: ['./location-widget-modal.component.scss']
})
export class LocationWidgetModalComponent implements OnInit {
  modalCtrl: any;
  @Input() user;
  @Input() isOnMenu = false;
  @Input() isOnCheckout = false;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    // if (!this.isOnMenu || this.isOnCheckout) {
    this.closeModal();
    // }
  }
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
  }

  closeModal() {
    console.log(this.modalCtrl);
    if (this.modalCtrl) {
      this.modalCtrl.close({ modalState: false });
    } else {
      this.activeModal.dismiss();
    }
  }

}
