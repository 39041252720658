import { DirectivesModule } from './../../directives/directives.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { GlobalService } from 'src/app/services/global.service';
import { PipesModule } from './../../pipes/pipes.module';
import { SwiperModule, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    HomeRoutingModule,
    ComponentsModule,
    SwiperModule,
    PipesModule,
    FormsModule,
    NgbModule,
    DirectivesModule,
    LazyLoadImageModule
  ],
  providers: [
    { provide: SWIPER_CONFIG, useValue: GlobalService.DEFAULT_SWIPER_CONFIG }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HomeModule { }
