import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
export class TermsConditionsComponent {
    constructor(loaderService, apiService, modalService) {
        this.loaderService = loaderService;
        this.apiService = apiService;
        this.modalService = modalService;
    }
    ngOnInit() {
        this.loadAgreements();
    }
    loadAgreements() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.apiService.getAgreements();
            this.loaderService.hide();
        });
    }
    closeModal() {
        this.modalService.dismissAll();
    }
}
