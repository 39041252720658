import { AppService } from './../../services/app/app.service';
import { BranchLocationService } from './../../services/branch-location/branch-location.service';
import { UserService } from './../../services/user/user.service';
import { StoreService } from './../../services/store/store.service';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { ToastService } from './../../services/toast/toast.service';
import { GlobalService } from './../../services/global.service';
import { FormValidationService } from './../../services/validation/form-validation.service';
import { ApiService } from '../../services/api/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Inject, PLATFORM_ID, Input, Output, EventEmitter } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';
import { RecaptchaComponent } from 'ng-recaptcha';
import { Title } from '@angular/platform-browser';
// tslint:disable-next-line: max-line-length
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})

export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  responseErrors: Array<string> = [];
  postCodes: any;
  isSameAddress = false;
  isShowPass = {
    password: false,
    confirm_pass: false
  };
  isCaptchaValid = false;
  branchLocation = {
    delivery_state: null,
    delivery_city: null,
    delivery_barangay: null,
    branch: null,
    selected_branch: null
  };

  reCaptcha: any;

  @Output() closeModal = new EventEmitter();
  @Output() openSignInModal = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    public globalService: GlobalService,
    private toastService: ToastService,
    private router: Router,
    private storeService: StoreService,
    private userService: UserService,
    private loaderService: LoaderService,
    private title: Title,
    private branchLocationService: BranchLocationService,
    private appService: AppService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.reCaptcha = GlobalService.RE_CAPTCHA_CONFIG;
    this.registerForm = this.formBuilder.group({
      last_name: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
      first_name: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
      email: ['', Validators.compose([Validators.required, Validators.pattern(EMAIL_REGEX)])],
      mobile: ['', Validators.compose([Validators.maxLength(13), Validators.minLength(11), Validators.pattern(GlobalService.REGEX_PATTERNS.phoneNumber)])],
      landline: ['', Validators.compose([Validators.maxLength(10), Validators.minLength(9), Validators.pattern(GlobalService.REGEX_PATTERNS.telephoneNumber)])],
      delivery_address_1: ['', Validators.compose([Validators.required, Validators.maxLength(150)])],
      delivery_address_2: ['', Validators.maxLength(150)],
      delivery_city: ['', Validators.required],
      delivery_state: ['', Validators.required],
      delivery_barangay: ['', Validators.required],
      //delivery_postal_code: ['4000', Validators.compose([Validators.required, Validators.maxLength(50)])],
      // delivery_company: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
      delivery_company: ['', Validators.maxLength(50)],
      is_same_address: [true],
      billing_address_1: [{ value: '', disabled: this.isSameAddress }, Validators.maxLength(150)],
      billing_address_2: [{ value: '', disabled: this.isSameAddress }, Validators.maxLength(150)],
      billing_city: [{ value: '', disabled: this.isSameAddress }, Validators.maxLength(50)],
      billing_state: [{ value: '', disabled: this.isSameAddress }, Validators.maxLength(50)],
      billing_postal_code: [{ value: '', disabled: this.isSameAddress }, Validators.maxLength(50)],
      billing_company: [{ value: '', disabled: this.isSameAddress }, Validators.maxLength(50)],
      password: ['', Validators.compose([Validators.required, Validators.maxLength(50), Validators.minLength(8)])],
      password_confirmation: ['', Validators.compose([Validators.required, Validators.maxLength(50), Validators.minLength(8)])],
      timezone: ['Asia/Manila', Validators.required],
      branch: [''],      
      selected_branch: ['']
    }, { validator: FormValidationService.MatchPassword });
    let siteTitle = 'Register';
    if (this.appService.siteName) {
      siteTitle = `${siteTitle} - ${this.appService.siteName}`;
    }
    this.title.setTitle(siteTitle);
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      // this.setBrowserTimezone(); // now set default as Asia/Manila
      // this.getPostCodes();

      // this.registerForm.get('is_same_address').valueChanges.subscribe(isSameAddress => {
      //   this.isSameAddress = isSameAddress;

      //   const billingFields = ['billing_address_1', 'billing_city', 'billing_state', 'billing_postal_code', 'billing_company'];
      //   billingFields.filter(key => {
      //     if (!isSameAddress) {
      //       this.registerForm.get(key).setValidators(Validators.compose([Validators.required, Validators.maxLength(50)]));
      //     } else {
      //       this.registerForm.get(key).clearValidators();
      //     }
      //     this.registerForm.get(key).updateValueAndValidity();
      //   });
      // });

      this.provincesInit();
    }
    this.loaderService.hide();
  }

  async provincesInit() {
    const provinces = await this.branchLocationService.getProvinces();
    this.registerForm.get('delivery_state').setValue(provinces);
    this.branchLocation.delivery_state = provinces;
    console.log(provinces);
  }

  async setBranch(branchObj, type) {
    let keys = [];
    switch (type) {
      case 'delivery_state':
        keys = ['delivery_city', 'delivery_barangay', 'branch', 'selected_branch'];
        break;
      case 'delivery_city':
        keys = ['delivery_barangay', 'branch', 'selected_branch'];
        this.branchLocation.delivery_city = branchObj.cities;
        break;
      case 'delivery_barangay':
        keys = ['branch', 'selected_branch'];
        this.branchLocation.delivery_barangay = branchObj.barangays;
        console.log(branchObj.barangays);
        break;
      case 'branch':
        keys = ['selected_branch'];
        this.branchLocation.selected_branch = branchObj.branches;
        break;
      default:
        break;
    }
    keys.map(key => {
      this.branchLocation[key] = null;
      this.registerForm.get(key).setValue(null);
      this.registerForm.get(key).updateValueAndValidity();
    });

    this.registerForm.get(type).setValue(branchObj);
    this.registerForm.get(type).updateValueAndValidity();

    if (branchObj.type === 'provinces') {
      let provinces = await this.apiService.request({
        method: 'GET',
        url: `/provinces/${branchObj.id}?include=cities`,
        jsona: true
      });
      // this.provinces = provinces;
      console.log(provinces)
      console.log(this.registerForm.value)
      this.registerForm.get(type).setValue(provinces);
      this.registerForm.get(type).updateValueAndValidity();
      // await this.updateBranchLocationStore(provinces);
      console.log(this.registerForm.value)
    } else if (branchObj.type === 'cities') {
      let city = await this.apiService.request({
        method: 'GET',
        url: `/cities/${branchObj.id}?include=barangays`,
        jsona: true
      });
      console.log(city)
      console.log(this.registerForm.value)
      this.registerForm.get(type).setValue(city);
      this.registerForm.get(type).updateValueAndValidity();
      console.log(this.registerForm.value)
    } else if (branchObj.type === 'barangays') {
      let barangay = await this.apiService.request({
        method: 'GET',
        url: `/barangays/${branchObj.id}?include=branches`,
        jsona: true
      });
      branchObj = barangay
      console.log(barangay)
      console.log(this.registerForm.value)
      this.registerForm.get(type).setValue(barangay);
      this.registerForm.get(type).updateValueAndValidity();
      console.log(this.registerForm.value)
    } else {
      this.registerForm.get(type).setValue(branchObj);
      this.registerForm.get(type).updateValueAndValidity();
    }

    if (type === 'branch') { // temporary fix only
      const firstBranch = branchObj.branches[0];
      this.registerForm.get('selected_branch').setValue(firstBranch);
      this.registerForm.get('selected_branch').updateValueAndValidity();
    }
    console.log(this.registerForm.value);
  }

  async register() {
    const isSameAddress = this.registerForm.get('is_same_address').value;
    this.registerForm.patchValue({ is_same_address: isSameAddress });
    // console.log(this.registerForm.value);
    // return;
        // return;
    if (this.registerForm.valid) {
      try {

        let landline = this.registerForm.get('landline').value;
        if (landline) {
          let firstPart = landline.substring(0, 2);
          let secondPart = landline.substring(2, 6);
          let thirdPart = landline.substring(6, 10);
          landline = `${firstPart} ${secondPart} ${thirdPart}`
        }
        console.log('landline =>', landline);
        
        

        const registerPayload = {
          // ...this.registerForm.value,                    
          //delivery_city: this.registerForm.value.delivery_barangay.title,
          //delivery_state: this.registerForm.value.delivery_city.title,
          last_name: this.registerForm.value.last_name,
          first_name: this.registerForm.value.first_name,
          email: this.registerForm.value.email,
          password: this.registerForm.value.password,
          password_confirmation: this.registerForm.value.password_confirmation,
          mobile: this.registerForm.value.mobile,
          is_same_address: true,
          name: 'Home',
          //province_id: this.registerForm.value.delivery_city.id,
          //city_id: this.registerForm.value.delivery_barangay.id,
          //barangay_id: this.registerForm.value.branch.id,                  
          delivery_address_1: this.registerForm.value.delivery_address_1,
          delivery_barangay: this.registerForm.value.branch.id, 
          delivery_company: this.registerForm.value.delivery_company,
          fill_type: 'manual',  
          timezone: 'Asia/Manila',
          landline: landline,
          branch: '',      
          selected_branch: '',
        };

        const savedUserAddress = {
          province: this.registerForm.value.delivery_city,
          city: this.registerForm.value.delivery_barangay,
          barangay: this.registerForm.value.branch,
          address_1: this.registerForm.value.delivery_address_1,
          company: this.registerForm.value.delivery_company,
          last_name: this.registerForm.value.last_name,
          first_name: this.registerForm.value.first_name,
          email: this.registerForm.value.email,
          mobile: this.registerForm.value.mobile,
        }
        await this.storeService.setItem("savedAddress", savedUserAddress)        

        console.log(registerPayload);
        delete registerPayload.selected_branch;
        delete registerPayload.branch;

        this.loaderService.showAsyncLoader();
        const res = await this.apiService.postRequest({
          url: '/v2/register',
          body: registerPayload,
        });
        // const userFormatted = this.apiService.jsonaFormatter(res);
        // this.storeService.setItem('user', userFormatted);
        console.log(res);        
        const isUpdate = true;
        await this.userService.getUser(isUpdate);
        this.loaderService.hideAsyncLoader();
        this.toastService.show(
          'Successfully registered. Please check your email to verify your account.',
          {
            classname: 'bg-success text-light'
          });
        // this.router.navigate(['/login']);
        this.openSignInModal.emit();
      } catch (err) {
        if (err.status === 422) {
          const errObj = err.error.errors;
          const errorKeys = Object.keys(errObj);
          if (errorKeys.includes('delivery_postal_code')) {
            this.registerForm.get('delivery_postal_code').setErrors({invalid_postal_code: true});
          }
          if (errorKeys.includes('billing_postal_code')) {
            this.registerForm.get('billing_postal_code').setErrors({invalid_postal_code: true});
          }
        }

        this.loaderService.hideAsyncLoader();
        const errMessages = this.globalService.getApiErrors(err);
        this.responseErrors = errMessages;
      }
    }
  }

  async getPostCodes() { // unused function (use if needed)
    try {
      const res = await this.apiService.request({
        method: 'GET',
        url: '/postal-codes?limit=0',
        jsona: true
      });
      console.log(res);
      this.postCodes = res;
    } catch (err) {
      console.log(err);
    }
  }

  setBrowserTimezone() {
    if (isPlatformBrowser(this.platformId)) {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const tzFormControl = this.registerForm.get('timezone');
      tzFormControl.setValue(tz);
      tzFormControl.updateValueAndValidity();
    }
  }

  closeErrorAlert(alert) {
    this.responseErrors.splice(this.responseErrors.indexOf(alert), 1);
  }

  isFormFieldInvalid(formGroup: FormGroup, fieldName) {
    return formGroup.get(fieldName).errors && (formGroup.get(fieldName).dirty || formGroup.get(fieldName).touched);
  }

  fieldErrorMessage(formGroup: FormGroup, fieldName) {
    const isInvalid = this.isFormFieldInvalid(formGroup, fieldName);
    let message: string;
    if (isInvalid) {
      const keys = Object.keys(formGroup.get(fieldName).errors);
      if (formGroup.get(fieldName).errors.pattern) {
        message = formGroup.get(fieldName).errors.pattern.requiredPattern.toString() === EMAIL_REGEX.toString()
          ? 'Please enter a valid email address.'
          : formGroup.get(fieldName).errors.pattern.requiredPattern.toString() === GlobalService.REGEX_PATTERNS.phoneNumber.toString() ? 'Please enter a valid phone number' : formGroup.get(fieldName).errors.pattern.requiredPattern.toString() === GlobalService.REGEX_PATTERNS.telephoneNumber.toString() ? 'Please enter a valid landline number' : 'Please enter a valid name';
      } else if ((fieldName === 'password_confirmation' || fieldName === 'password') && keys.includes('minlength')) {
        message = 'Password must be at least 8 characters.';
      } else if (fieldName === 'password_confirmation') {
        message = 'Passwords do not match';
      } else if (keys.includes('invalid_postal_code')) {
        message = 'Invalid Zip Code';
      } else if (fieldName === 'mobile' && keys.includes('minlength') && keys.includes('maxlength')) {
        message = 'Invalid Mobile No.';
      } else if (fieldName === 'landline' && keys.includes('minlength') && keys.includes('maxlength')) {
        message = 'Invalid Landline No.';
      } else {
        message = 'This field is required';
      }
    } else {
      message = '';
    }
    return message;
  }

  captchaResolved(value) {
    this.isCaptchaValid = value ? true : false;
  }

}
