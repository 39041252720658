import * as i0 from "@angular/core";
export class FormValidationService {
    constructor() { }
    static MatchPassword(abstractControl) {
        const newPassword = abstractControl.get('password').value;
        const confirmPassword = abstractControl.get('password_confirmation').value;
        if (newPassword !== confirmPassword) {
            abstractControl.get('password_confirmation').setErrors({ MatchPassword: true });
        }
        else {
            if (newPassword && confirmPassword) {
                abstractControl.get('password_confirmation').setErrors(null);
            }
        }
    }
    static NoWhitespaceValidator(control) {
        if (control.value.trim().length === 0) {
            return { required: true };
        }
        return null;
    }
}
FormValidationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FormValidationService_Factory() { return new FormValidationService(); }, token: FormValidationService, providedIn: "root" });
