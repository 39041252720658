import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-chili-count',
  templateUrl: './chili-count.component.html',
  styleUrls: ['./chili-count.component.scss']
})
export class ChiliCountComponent implements OnInit {
  chiliCtr = 0;
  chiliArr = [];
  @Input()
  set chiliCount(value) {
    if (value) {
      if (typeof value === 'string') {
        value = parseInt(value, 10);
      }
      this.chiliArr = new Array(value).fill(value);
    }
  }
  constructor() { }

  ngOnInit() {
  }

}
