import { isPlatformBrowser } from "@angular/common";
import { ToastService } from "./../toast/toast.service";
import { ApiService } from "./../api/api.service";
import { StoreService } from "./../store/store.service";
import { Subject, Observable } from "rxjs";
import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { InformationDialogComponent } from "src/app/components/information-dialog/information-dialog.component";
import { GlobalService } from "../global.service";
import { AppService } from "../app/app.service";

@Injectable({
  providedIn: "root",
})
export class CartService {
  private cartSubject = new Subject<object>();
  public cartHeaderSubject = new Subject<boolean>();
  public cartMenuMobileOpen = new Subject<boolean>();
  public cartCountSubject = new Subject<number>();
  private cartQuantityChange = new Subject<object>();
  private deliveryType = new Subject<string>();
  public minimumOrder = new Subject<number>();
  public minOrder = 0;
  public buytake1Details = {
    "product_categories": [],
    "apc_card_category": "",
    "double_deal_category": "",
    "buy1_take1_variants": [],
    "price_variant_type": "",
    
  };  
  cartStorage: any;
  allCartEgc = false
  cartTotal: CartTotal;
  cartTotalObj: CartTotalObject;
  cartTotalValue = "0";
  egcEnabled;
  allCartProd = false;
  constructor(
    private storeService: StoreService,
    private apiService: ApiService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private globalService: GlobalService,
    private appService: AppService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.onCartChangedListener();
    this.egcEnabled = GlobalService.FEATURES.egc;
    this.onMinimumOrderChange().subscribe(minOrder => this.minOrder = minOrder)
  }

  /////////////// CART API ////////////////////////////////
  async getCartItems() {
    //console.log('[getCartItems] cart storage => ', this.cartStorage);
    if (isPlatformBrowser(this.platformId)) {
      const token = await this.storeService.getItem("token");
      if (!token) {
        const apcCardCode = await this.storeService.getItem("apc");
        let tempCart = await this.storeService.getItem("cart");

        if (Array.isArray(tempCart)) {
          tempCart = tempCart.map(item => {
            // if ((!apcCardCode || apcCardCode === '') && !this.hasProductCategory(this.buytake1Details.apc_card_category)) {
            //   delete item['take1'];
            // }
            return item;
          })
          this.cartStorage = tempCart;
        }
        const deliveryType = await this.storeService.getItem("deliveryType");
        const brgy =
          deliveryType === "delivery"
            ? await this.storeService.getItem("brgy_segment")
            : null;
        this.getCartTotalGuest(brgy, this.cartStorage);
        this.setCartTotalValue();
        return;
      }
      const headers = { Authorization: `Bearer ${token}` };
      try {               
        let res = await this.apiService.request({
          method: "GET",
          url: "/auth/cart",
          headers,
        });
        
        const egcRes = await this.apiService.request({
          method: "GET",
          url: '/auth/e-gift-certificate-cart',
          headers,
        });

        console.log(res, egcRes);

        // if (egcRes) {
        //   egcRes.map(item => {
        //     console.log(item);
            
        //   })
        // }
        res.data.push(...egcRes.data)
        let test = this.apiService.jsonaFormatter(egcRes)
        test.map(item => {
          console.log(item);

          let pushEgc = res.data.find(egc => egc.id === item.id)

          if(!pushEgc) {
            res.data.push(...egcRes.data)
          }
          
        })
        console.log('[cart service] egcRes => ', test);

        this.setCartStorage(res);
        this.getNotifs();
      } catch (err) {
        console.log(err);        
        if (err.status === 401) {
          return;
        }
        console.log(err);
        this.showDangerToast(err.error.message);
      }
    }
  }

  async removeCartItem(cartItem) {
    console.log('delete: ', cartItem);    
    const token = await this.storeService.getItem("token");
    const branch = await this.storeService.getItem("branch");
    const headers = {
      Authorization: `Bearer ${token}`,
      "x-branch": branch || "all",
    };
    try {
      const removeCartRespose = await this.apiService.request({
        method: "DELETE",
        url: `/auth/cart/${cartItem.id}`,
        headers,
      });

      if(cartItem.type == 'e-gift-certificate-cart') {
        const removeCartRespose = await this.apiService.request({
          method: "DELETE",
          url: `/auth/e-gift-certificate-cart/${cartItem.id}`,
          headers,
        });
      }
      await this.getCartItems();
      this.showSuccessToast(
        `${cartItem.product.title} successfully removed from cart.`
      );
      console.log(removeCartRespose);
      if (this.isApcCategory(cartItem)) {
        await this.emptyCart();
        await this.removeAllCartItems();
        window.location.reload();
      }
    } catch (err) {
      if (err.status === 401) {
        return;
      }
      this.showDangerToast("Failed to remove item to cart. Please try again.");
      console.log(err);
    }
  }

  async removeAllCartItems() {
    const token = await this.storeService.getItem("token");
    if (!token) {
      return;
    }
    if (!Array.isArray(this.cartStorage)) {
      return;
    }
    if (!this.cartStorage.length) {
      return;
    }
    const headers = { Authorization: `Bearer ${token}` };
    try {
      const removeCartRespose = await this.apiService.request({
        method: "DELETE",
        url: "/auth/cart/empty",
        headers,
      });
      //if (this.egcEnabled) {
        const removeEgcResponse = await this.apiService.request({
          method: 'DELETE',
          url: '/auth/e-gift-certificate-cart/empty',
          headers,
        })
        console.log(removeEgcResponse);
      //}
      await this.getCartItems();
      this.showSuccessToast("Successfully removed items to cart.");
      console.log(removeCartRespose);
    } catch (err) {
      if (err.status === 401) {
        return;
      }
      this.showDangerToast("Failed to clear cart items. Please try again");
      console.log(err);
    }
  }
  /////////////// CART API ////////////////////////////////

  showSuccessToast(message) {
    this.toastService.show(message, { classname: "bg-success text-light" });
  }

  showDangerToast(message) {
    this.toastService.show(message, { classname: "bg-danger text-light" });
  }

  onCartChangedListener() {
    this.onCartDataChanged().subscribe((item) => {
      if (item) {
        if (Array.isArray(this.cartStorage)) {
          this.cartStorage.push({ ...item });
        } else {
          this.cartStorage = [item];
        }
        // console.log("cartStorage", this.cartStorage);
      }
    });
  }

  onCartDataChanged(): Observable<object> {
    return this.cartSubject.asObservable();
  }

  onCartQuantityChanged(): Observable<object> {
    return this.cartQuantityChange.asObservable();
  }

  onCartCountChanged(): Observable<number> {
    return this.cartCountSubject.asObservable();
  }

  onDeliveryTypeChange(): Observable<string> {
    return this.deliveryType.asObservable();
  }

  changeDeliveryType(value: string) {
    this.deliveryType.next(value);
  }

  onMinimumOrderChange(): Observable<number> {
    return this.minimumOrder.asObservable();
  }

  changeMinimumOrder(value: number) {
    this.minimumOrder.next(value);
  }

  async setCartData(cartData, isFromCartInput?) {
    console.log('[setCartData] cartData =>', cartData)
    // let priceAmount = '0';
    if (!cartData || !cartData.price || !cartData.price.amount){ 
      cartData.price = {amount: '0'}
    }

    cartData.price.amount = parseFloat(cartData.price.amount || '0');
    console.log("set data");
    let productCategories = cartData.productCategories || [];

    console.log('[setCartData] productCategories =>', productCategories)

    if (!(await this.isInCart(cartData)) || productCategories.length > 0 && (this.isTake1ProductCategories(cartData) || this.isTake1ProductCategories(cartData))) {
      console.log(cartData);
      this.cartSubject.next(cartData);
      this.cartCountSubject.next(
        this.cartStorage ? this.cartTotalQuantity() : 0
      );
    } else {
      if (!isFromCartInput) {
        // this.cartStorage.map(cartItem => {
        //   if (cartItem.id === cartData.id) {
        //     cartItem.quantity += cartData.quantity;
        //   }
        // });
        const cartIndex = await this.isInCart(cartData, "index");
        this.cartStorage[cartIndex].quantity += cartData.quantity;

        if (cartData.extra.dietary_requirements) {
          this.cartStorage[cartIndex].extra.dietary_requirements =
            cartData.extra.dietary_requirements;
        }
      }
      this.cartQuantityChange.next(cartData);
    }

    const token = await this.storeService.getItem("token");
    if (!token) {
      this.storeService.setItem("cart", this.cartStorage);
      const deliveryType = await this.storeService.getItem("deliveryType");
      const brgy =
        deliveryType === "delivery"
          ? await this.storeService.getItem("brgy_segment")
          : null;
      // if (cartData.type !== 'e-gift-certificate-cart') {
        console.log('get cart total guest...')
         this.getCartTotalGuest(brgy, this.cartStorage);
      // }
    }
    // this.setCartTotalValue();
  }

  async preCartValidationTransform(preCartData) {
    // only used in menu-item.component.ts (for api validation)
    const cartStorageCopy = JSON.parse(JSON.stringify(this.cartStorage || []));

    let productCategories = preCartData.productCategories || [];

    console.log('[preCartValidationTransform] productCategories =>', productCategories)

    if (!(await this.isInCart(preCartData)) || productCategories.length > 0 && (this.isDoubleDealCategory(preCartData) || this.isTake1ProductCategories(preCartData))) {
      return [...cartStorageCopy, ...[preCartData]];
    }
    const cartIndex = await this.isInCart(preCartData, "index");
    cartStorageCopy[cartIndex].quantity += preCartData.quantity;
    return cartStorageCopy;
  }

  async removeItemInCart(cartItemId, index) {
    this.cartStorage.splice(index, 1);
    this.storeService.setItem("cart", this.cartStorage);
    // this.setCartTotalValue();
    const deliveryType = await this.storeService.getItem("deliveryType");
    const brgy =
      deliveryType === "delivery"
        ? await this.storeService.getItem("brgy_segment")
        : null;
    this.getCartTotalGuest(brgy, this.cartStorage);
    this.cartCountSubject.next(this.cartStorage ? this.cartTotalQuantity() : 0);
    console.log('item =>', cartItemId);
    if (this.isApcCategory(cartItemId)) {
      await this.emptyCart();
      await this.removeAllCartItems();
      window.location.reload();
    }
  }

  async voucherCodeInCart(cartItemDetails) {
    if (!Array.isArray(this.cartStorage)) {
      return "";
    }
    const token = await this.storeService.getItem("token");
    return this.cartStorage.filter((cartItem, index) => {
      if (token) {
        cartItem = cartItem.product;
      }
      if (cartItem.id === cartItemDetails.id) {
        return cartItem;
      }
    });
  }

  isInCart(cartItemDetails, type?): Promise<any> {
    // deep compare of product to cart
    // console.log('cart item details => ', cartItemDetails)
    return new Promise(async (resolve) => {
      if (!Array.isArray(this.cartStorage)) {
        resolve(false);
        return;
      }
      let isItemInCart = false;
      const token = await this.storeService.getItem("token");
      let cartItemIndex = 0;
      let filteredItem = null;
      this.cartStorage.filter((cartItem, index) => {

        // console.log('cart item => ', cartItem)

        if (cartItem.type !== 'products') {
          return
        }

        if (token) {
          cartItem = cartItem.product;
        }
        // console.log('cart item details => ', cartItemDetails)
        if (cartItem.id === cartItemDetails.id) {
          const compareStringObj = {
            itemDetailVariants: cartItemDetails.selectedVariants,
            itemDetailAdditionalProducts:
              cartItemDetails.selectedAdditionalProducts,
            cartItemVariants: cartItem.selectedVariants,
            cartItemAdditionalProducts: cartItem.selectedAdditionalProducts
          };
          let isSameVariants = false;
          let isSameAdditionalProducts = false;
          let isSamePromoCode = false;
          if (
            compareStringObj.itemDetailVariants.length ===
            compareStringObj.cartItemVariants.length
          ) {
            const variantsLength = compareStringObj.cartItemVariants.length;
            if (!variantsLength) {
              isSameVariants = true;
            }
            let ctr = 0;
            compareStringObj.itemDetailVariants.filter((itemVariant) => {
              // tslint:disable-next-line: max-line-length
              const filteredCmpArr = compareStringObj.cartItemVariants.filter(
                (cartItemVariant) =>
                  cartItemVariant.segment === itemVariant.segment
              );
              if (filteredCmpArr.length) {
                ctr++;
              }
            });
            if (ctr === variantsLength) {
              isSameVariants = true;
            }
          }
          if (
            compareStringObj.itemDetailAdditionalProducts.length ===
            compareStringObj.cartItemAdditionalProducts.length
          ) {
            const addOnsLength =
              compareStringObj.cartItemAdditionalProducts.length;
            if (addOnsLength === 0) {
              isSameAdditionalProducts = true;
            }
            let ctr = 0;
            compareStringObj.itemDetailAdditionalProducts.filter(
              (itemAddOn) => {
                // tslint:disable-next-line: max-line-length
                const filteredCmpArr = compareStringObj.cartItemAdditionalProducts.filter(
                  (cartItemAddOn) => {
                    const hasId = Object.keys(cartItemAddOn);
                    const key = hasId.includes("id") ? "id" : "segment";
                    return cartItemAddOn[key] === itemAddOn.id;
                  }
                );
                if (filteredCmpArr.length) {
                  ctr++;
                }
              }
            );
            if (ctr === addOnsLength) {
              isSameAdditionalProducts = true;
            }
          }
          // newly added rule to separate same either product, addon and variant but different voucher codes
          if (!token) {
            if (cartItem.voucher_code === cartItemDetails.voucher_code) {
              isSamePromoCode = true;
            }
          } else {
            let extractVoucherCode = "";
            if (cartItem.extra.voucher) {
              const keys = Object.keys(cartItem.extra.voucher);
              if (keys.includes("code")) {
                extractVoucherCode = cartItem.extra.voucher.code;
              }
            }
            if (extractVoucherCode === cartItemDetails.voucher_code) {
              isSamePromoCode = true;
            }
          }

          // compare selected additional products and variations
          // console.log(cartItem.voucher_code, cartItemDetails.voucher_code);
          if (isSameAdditionalProducts && isSameVariants && isSamePromoCode) {
            isItemInCart = true;
            filteredItem = cartItem;
            cartItemIndex = index;
          }
        }
      });
      switch (type) {
        case "index":
          resolve(cartItemIndex);
          break;
        case "item":
          resolve(filteredItem);
          break;
        default:
          resolve(isItemInCart);
          break;
      }
    });
  }

  itemInCart(cartItemDetails) {
    // deprecated (use `isInCart` using 'item' as type)
    return new Promise(async (resolve) => {
      if (!Array.isArray(this.cartStorage)) {
        resolve(false);
        return;
      }
      const isItemInCart = false;
      const product = this.cartStorage.filter((cartItem) => {
        const productData = Object.keys(cartItem).includes("product")
          ? cartItem.product
          : cartItem;
        const productId = Object.keys(cartItemDetails).includes("product")
          ? cartItemDetails.product
          : cartItemDetails.id;
        if (productData.id === productId) {
          return cartItem;
        }
      });
      // console.log(product.length);
      resolve(product.length ? product[0] : false);
    });
  }

  setCartTotalValue() {
    if (this.cartStorage && this.cartStorage.length) {
      const totalValue = this.cartStorage.reduce((previous, current) => {
        // tslint:disable-next-line: radix
        return previous + current.price.amount * current.quantity;
      }, 0);
      this.cartTotalValue = (totalValue * 0.01).toFixed(2);
    } else {
      this.cartTotalValue = "0";
    }
  }

  async setCartStorage(res, product?) {
    // this.cartTotal = res.meta.total;
    //console.log('res => ', res)    
    let cartDataFormatted: any = this.apiService.jsonaFormatter(res);    
    cartDataFormatted = this.modifyUserCartStructure(cartDataFormatted);
    cartDataFormatted = this.formatCartItemsForView(cartDataFormatted);
    console.log("user cart res", cartDataFormatted);
      
    // console.log("user cart res", cartDataFormatted);
    // cartDataFormatted.map(item => {
    //   // tslint:disable-next-line: radix
    //   item.price.amount = parseInt(item.price.amount);
    // });                
    
    this.cartStorage = cartDataFormatted;

    // this.cartStorage.deep_copy = product
    console.log(this.cartStorage,);       
    
    const deliveryType = await this.storeService.getItem("deliveryType");
    const brgy =
      deliveryType === "delivery"
        ? await this.storeService.getItem("brgy_segment")
        : null;
    this.getCartTotal(brgy);
    // this.setCartTotalValue(); // TODO: change to check price api

    // if (this.cartStorage.length) { // TEMP
    //   const checkoutDetails = await this.storeService.getItem('checkout_details');
    //   if (checkoutDetails) {
    //     await this.storeService.removeItem('checkout_details');
    //   }
    // }
    this.cartCountSubject.next(this.cartStorage ? this.cartTotalQuantity() : 0);
  }

  // needed for same structure in guest (for cart crud operations)
  modifyUserCartStructure(userCart) {
    // console.log('user cart => ', userCart)
    try {
      userCart.map((cartItem) => {
        if (!cartItem.product) {
          return cartItem;
        }
        const cartItemAsString = JSON.stringify(cartItem.product);
        cartItem.product = JSON.parse(cartItemAsString); // deep copy (required)

        cartItem.product.selectedVariants = cartItem.extra.variants.length
          ? cartItem.extra.variants
          : [];
        cartItem.product.selectedAdditionalProducts = cartItem.extra
          .additional_products.length
          ? cartItem.extra.additional_products
          : [];

        cartItem.product.quantity = cartItem.quantity;
        cartItem.product.extra.dietary_requirements =
          cartItem.extra.dietary_requirements;

        cartItem = this.modifyUserCartAddons(cartItem);
        cartItem = this.modifyUserCartVariants(cartItem);
      });
    } catch (err) {
      console.log(err);
    }
    // console.log('user cart => ', userCart)
    return userCart;
  }

  formatCartItemsForView(userCart) {
    userCart.map((cartItem) => {
      if (!cartItem.product) {
        return cartItem;
      }
      if (Array.isArray(cartItem.product.variants)) {
        cartItem.product.variants = this.groupItemByType(
          cartItem.product.variants,
          "variants"
        );
      }
      if (Array.isArray(cartItem.product.additionalProducts)) {
        cartItem.product.additionalProducts = this.groupItemByType(
          cartItem.product.additionalProducts,
          "additionalProducts"
        );
      }
    });
    return userCart;
  }

  modifyUserCartVariants(cartItem) {
    //console.log('cart item => ', cartItem)
    if (!cartItem) {
      return cartItem;
    }
    if (!cartItem.product.variants.length) {
      return cartItem;
    }
    cartItem.product.disabled_variants = [];
    let variantLimitArr = {};
    if (cartItem.product.extra.maximum_variant_selection) {
      variantLimitArr = Object.keys(
        cartItem.product.extra.maximum_variant_selection
      );
    }
    if (cartItem.product.selectedVariants.length) {
      cartItem.product.variants.map((variant) => {
        variant.selected = false;
        variant.disabled = false;

        const filteredSelected = cartItem.product.selectedVariants.filter(
          (selectedAddon) => selectedAddon.segment === variant.segment
        );
        if (filteredSelected.length) {
          // console.log('filtered selected => ', filteredSelected)
          variant.selected = true;
          variant.quantity = filteredSelected[0].quantity;
        }
        if (variant.variants.length) {
          variant.variants.map((childVariant) => {
            childVariant.selected = false;
            childVariant.disabled = false;
            const childSelected = cartItem.product.selectedVariants.filter(
              (selectedAddon) => selectedAddon.segment === childVariant.segment
            );
            if (childSelected.length) {
              // console.log('child selected => ', childSelected)
              childVariant.selected = true;
              childVariant.quantity = childSelected[0].quantity;
            }
          });
        }
      });
      cartItem = this.variantLimitChecker(cartItem, variantLimitArr);
      // console.log(cartItem)
    }
    return cartItem;
  }

  modifyUserCartAddons(cartItem) {
    if (!cartItem) {
      return cartItem;
    }
    if (!cartItem.product.selectedAdditionalProducts.length) {
      return cartItem;
    }
    if (!cartItem.product.additionalProducts.length) {
      return cartItem;
    }

    cartItem.product.additionalProducts.map((addons) => {          
      addons.selected = false;
      addons.disabled = false;
      const filteredSelected = cartItem.product.selectedAdditionalProducts.filter(
        (selectedAddon) => selectedAddon.id === addons.id
      );
      if (filteredSelected.length) {
        addons.selected = true;
      }
    });
    return cartItem;
  }

  groupItemByType(arr, type?) {
    const result = arr.reduce((r, a) => {
      // a = this.addStateKeys(a);

      // if (type === 'variants') {
      //   if (a.variants.length) {
      //     a.variants.map(variant => {
      //       variant = this.addStateKeys(variant);
      //     });
      //   }
      // }

      r[a.type] = r[a.type] || [];
      r[a.type].push(a);
      return r;
    }, Object.create(null));
    return result;
  }

  addStateKeys(obj) {
    obj.selected = false;
    obj.disabled = false;
    obj.disabled_variants = [];
    return obj;
  }

  itemsWithSameType(cartItem, itemType) {
    return cartItem.product.selectedVariants.filter((selectedVariats) => {
      if (selectedVariats.type === itemType) {
        return selectedVariats;
      }
    });
  }

  variantLimitChecker(cartItem, variantLimitArr) {
    if (cartItem.product.disabled_variants) {
      variantLimitArr.filter((variantType) => {
        const selectedItemsByTypes = this.itemsWithSameType(
          cartItem,
          variantType
        );
        let typeLimitList = {};
        if (cartItem.product.extra.maximum_variant_selection) {
          typeLimitList = cartItem.product.extra.maximum_variant_selection;
        }

        // disable variants on limit
        if (selectedItemsByTypes.length >= typeLimitList[variantType]) {
          this.changeDisabledState(cartItem, "variants", true);
          cartItem.product.disabled_variants.push(variantType);
        }
      });
    }
    return cartItem;
  }

  changeDisabledState(cartItem, type, state) {
    cartItem.product[type].map((productAddon) => {
      if (!productAddon.selected) {
        productAddon.disabled = state;
      }
      if (type === "variants") {
        if (productAddon.variants.length) {
          productAddon.variants.map((subVariant) => {
            if (!subVariant.selected) {
              subVariant.disabled = state;
              // console.log("cartUser isDisabled", subVariant.disabled);
            }
          });
        }
      }
    });
    return cartItem;
  }

  cartTotalQuantity() {
    if (this.cartStorage) {
      return this.cartStorage.reduce((a, b) => a + (b["quantity"] || 0), 0);
    } else {
      return 0;
    }
  }

  async deletePromoCode(item) {
    const branch = await this.storeService.getItem("branch");
    const token = await this.storeService.getItem("token");

    let headers = {};

    headers = {
      "x-branch": branch || "all",
    };

    headers = { ...headers, Authorization: `Bearer ${token}` };

    const cartRes = await this.apiService.request({
      method: "DELETE",
      url: `/auth/cart/vouchers/${item.id}`,
      headers,
    });
    // console.log(cartRes);
    await this.getCartItems();
  }

  async updateCartItem(item) {
    console.log('updatedCart = ',item);
    //this.getCartItems()
    const branch = await this.storeService.getItem("branch");
    const token = await this.storeService.getItem("token");    
    let headers = {};

    headers = {
      "x-branch": branch || "all",
    };

    headers = { ...headers, Authorization: `Bearer ${token}` };

    const cartRes = await this.apiService.putRequest({
      url: `/auth/cart/${item.id}`,
      body: {        
        ...item,
      },
      headers,
    });

    let test = this.apiService.jsonaFormatter(cartRes)
    console.log("update user cart", test);
    
    this.setCartStorage(cartRes);    
  }

  async updateCart() {
    // recursive update (DEPRECATED)
    const token = await this.storeService.getItem("token");
    try {
      const updatePayload = {
        carts: [],
      };
      let isQuantityInvalid = false;
      if (Array.isArray(this.cartStorage)) {
        this.cartStorage.map((item) => {
          const minQty =
            (token ? item.product.minimum_quantity : item.minimum_quantity) ||
            1;
          const productName = token ? item.product.title : item.title;
          if (item) {
            if (item.quantity < minQty) {
              isQuantityInvalid = true;
              this.showDangerToast(
                `${productName} has a minimum quantity of ${minQty}`
              );
              return;
            }
            updatePayload.carts.push({
              id: item.id,
              quantity: item.quantity,
              dietary_requirements: item.extra.dietary_requirements,
              sub_category: item.selected_sub_category,
            });
          }
        });
        if (isQuantityInvalid) {
          return;
        }
        const branch = await this.storeService.getItem("branch");
        let headers = {};
        headers = {
          "x-branch": branch || "all",
        };

        headers = { ...headers, Authorization: `Bearer ${token}` };
        await new Promise(async (resolve) => {
          this.cartStorage.filter(async (item) => {
            await this.apiService.putRequest({
              url: `/auth/cart/${item.id}`,
              body: {
                quantity: item.quantity,
                dietary_requirements: item.extra.dietary_requirements,
              },
              headers,
            });
          });
        });
        this.showSuccessToast("Cart Successfully Updated.");
      }
    } catch (err) {
      console.log(err);
      this.showDangerToast("Failed to update cart. Please try again.");
    }
  }

  async checkProductStock(product) {
    const branch = await this.storeService.getItem("branch");
    let headers = {};

    headers = {
      "x-branch": branch || "all",
    };

    return this.apiService.postRequest({
      url: `/products/${product.id}/stock`,
      body: { quantity: product.quantity },
      jsona: true,
      headers,
    });
  }

  async checkBulkProductStock() {
    const branch = await this.storeService.getItem("branch");
    const token = await this.storeService.getItem("token");
    let headers = {};

    headers = {
      "x-branch": branch || "all",
    };
    const cartPayload = [];

    this.cartStorage.map((item) => {
      if (token) {
        cartPayload.push({
          id: item.product.id,
          quantity: item.quantity,
        });
      } else {
        cartPayload.push({
          id: item.id,
          quantity: item.quantity,
        });
      }
    });

    return this.apiService.postRequest({
      url: `/products/stock`,
      body: { products: cartPayload },
      // jsona: true,
      headers,
    });
  }

  async updateGuestCart(product, index) {
    this.cartStorage[index] = product;
    await this.storeService.setItem("cart", this.cartStorage);
    const deliveryType = await this.storeService.getItem("deliveryType");
    const brgy =
      deliveryType === "delivery"
        ? await this.storeService.getItem("brgy_segment")
        : null;
    await this.getCartTotalGuest(brgy, this.cartStorage);
    this.cartCountSubject.next(this.cartStorage ? this.cartTotalQuantity() : 0);
  }

  async checkVoucherValidator(voucherCode) {
    let isDuplicateVoucherCode = false;
    const cartItems = this.cartStorage;
    if (!Array.isArray(cartItems)) {
      return false;
    }
    if (!cartItems.length) {
      return false;
    }
    const token = await this.storeService.getItem("token");
    cartItems.filter((cartItem) => {
      let cartItemVoucherCode = "";
      if (token) {
        const keys = Object.keys(cartItem.extra.voucher);
        if (keys.includes("code")) {
          cartItemVoucherCode = cartItem.extra.voucher.code;
        }
      } else {
        cartItemVoucherCode = cartItem.voucher_code;
      }
      if (cartItemVoucherCode === voucherCode) {
        isDuplicateVoucherCode = true;
      }
    });
    return isDuplicateVoucherCode;
  }

  async getCartTotalGuest(barangay, cartItems) {
    console.log('cart item: ', cartItems);  
    console.log('get cart total guest... ', cartItems)
    const voucherCodes: any =
      (await this.storeService.getItem("voucher_codes")) || [];
    if (!cartItems) {
      return;
    }
    try {

      const apcCardCode = await this.storeService.getItem("apc");
      const cartPayload = [];
      let egcCartPayload = [];
      cartItems.filter((item) => {

        if (item.type === 'e-gift-certificate-cart') {
          console.log('item => ', item);
          egcCartPayload.push({"amount": item.price.amount/100, "quantity": item.quantity})
          return false;
        }
        

        let product: any = {
          product: item.id,
          quantity: item.quantity,
          // variants: item.selectedVariants.map(variant => variant.segment),
          // additional_products: item.selectedAdditionalProducts.map(addon => addon.id)
        };
        
        
        

        if (item.selectedVariants && item.selectedVariants.length) {


          let error = false;
          item.selectedVariants.forEach(v => {
            if (v.quantity < 1) {
              this.showDangerToast(`${v.title} has a minimum quantity of 1`);
              error = true;
            }
          });
          if (error) return;

          product.variants = item.selectedVariants.map((variant) => {
            return { id: variant.segment, quantity: variant.quantity };
          });
        }

        console.log('[cart service] get cart total guest take1 => ', item.take1);
        if (item.take1 && ((apcCardCode || this.hasApcCategory()) || (!(apcCardCode || this.hasApcCategory()) && this.isDoubleDealCategory(item)))) {
          console.log('[get cart total guest] take1 => ', item.take1)
          product.take1 = item.take1.id;

          const selectedVariants = item.take1.selectedVariants
          console.log('selected variants =>', selectedVariants)
  
          if (selectedVariants && selectedVariants.length) {
            product.take1_variants = selectedVariants.map(v => {
              if (!v.id || !item.id || !item.take1.id) return
              let tempId = v.id.replace(item.id,'').replace(item.take1.id,'');
              console.log('temp id =>', tempId);
              let id = tempId.split('-').filter(s => !!s).join('-');
              return {id, quantity: 1}
            })
          }
        }
        if (item.selectedAdditionalProducts && item.selectedAdditionalProducts.length) {
          product.additional_products = item.selectedAdditionalProducts.map(
            (addon) => addon.id
          );
        }
        if (item.voucher_code && item.voucher_code.length) {
          product.voucher_code = item.voucher_code;
        }
        cartPayload.push(product);
      });
      if (!cartPayload.length && !egcCartPayload.length) {
        return;
      }

      let payload: any = {
        cart: [...cartPayload,],        
        e_gift_certificates: [...egcCartPayload],             
      };
      if (apcCardCode) {
        payload.apc_card_code = apcCardCode;
      }
      console.log(payload)      

      if (voucherCodes.length) {
        payload.voucher_codes = voucherCodes;
      }
      

      if (this.hasProducts(cartPayload)) { 
  
        const deliveryType = await this.storeService.getItem("deliveryType");        
        if (deliveryType) {
          payload.claim_type = deliveryType || "delivery";
          
        }
  
        if (barangay && deliveryType === "delivery") {
          payload.barangay = barangay;

        }
      } else {
        ['claim_type', 'barangay'].filter(key => {
          delete payload[key];
        });        
      }

      if (payload.claim_type === 'pick-up') {
        payload.fill_type = 'map'
        payload.branch_distance = '1'        
      }

      // if (this.isOnlyEgc()) {
      //   this.allCartEgc = true
      //   if(barangay) {
      //     payload.barangay = barangay     
      //     payload.fill_type = 'manual'         
      //   }
      // } else {
      //   this.allCartEgc = false
      //   if(barangay) {
      //     payload.barangay = barangay     
      //     payload.fill_type = 'manual'         
      //   }
      // }
      

      if(payload.cart.length != 0) {
        this.allCartProd = true   
        this.allCartEgc = false     
        if(barangay) {
          payload.barangay = barangay     
          payload.fill_type = 'manual'         
        }
      }

      if(payload.e_gift_certificates.length != 0) {
        this.allCartEgc = true   
        this.allCartProd = false     
        if(barangay) {
          payload.barangay = barangay     
          payload.fill_type = 'manual'         
        }
      }

      const branch = await this.storeService.getItem("branch");

      payload = this.clean(payload);
      const headers = {
        // "x-branch": this.hasProducts() ? branch || "all" : "all",
        "x-branch": await this.getBranch(payload) ? branch : 'all',
      };

      console.log('payload => ', payload);

      const cartTotal: any = await this.apiService.postRequest({
        url: "/auth/cart/order-details/guest",
        body: payload,
        headers,
      });
      // const cartTotalKeys = Object.keys(cartTotal);
      // cartTotalKeys.map((key: any) => {
      //   // tslint:disable-next-line: radix
      //   cartTotal[key].amount = (parseInt(cartTotal[key].amount) * 0.01);
      // });
      this.cartTotalObj = cartTotal;
      console.log('get cart total guest');
      console.log('cart total obj => ', this.cartTotalObj);
      console.log('store cart => ', await this.storeService.getItem('cart'))
    } catch (err) {
      console.log(err);
      const hasMessageKey = Object.keys(err.error).includes("message");
      if (hasMessageKey) {
        if (err.error.message.includes("Header X-Branch value")) {
          await this.resetBranchLocation();
          return;
        }
      }

      console.log(err);
      if (err.status === 401) {
        return;
      }
      // this.apiService.showErrors(err);
      this.customErrMsgCheckTotalGuest(err);
    }
  }

  async getCartTotal(barangay) {
    
    // if (!barangay) {
    //   return;
    // }        
    
    try {
      const token = await this.storeService.getItem("token");
      const branch = await this.storeService.getItem("branch");
      const deliveryType = await this.storeService.getItem("deliveryType");
      const voucherCodes: any =
        (await this.storeService.getItem("voucher_codes")) || [];
      const apcCardCode = await this.storeService.getItem("apc");
      if (!branch && deliveryType === "delivery") {
        return;
      }
      // const headers = {
      //   Authorization: `Bearer ${token}`,
      //   "x-branch": this.hasProducts() ? branch || "all" : "all",
      // };

      

      console.log('get cart total ...', this.cartStorage);
      let payload: any = {};

      // if (this.hasProducts() || (!this.hasProducts() && !this.hasEgc())) { 
      if (this.hasProducts()) {         
        this.allCartProd = true
        if (deliveryType) {
          payload.claim_type = deliveryType || "delivery";
        }
  
        if (barangay && deliveryType === "delivery") {
          payload.barangay = barangay;
          payload.fill_type = 'manual'
        }
      } else {
        this.allCartProd = false
      }

      if (this.isOnlyEgc()) {
        this.allCartEgc = true        
      } else {
        this.allCartEgc = false        
      }
      
          
      
      if (!payload.claim_type) {
        return;
      }

      if (payload.claim_type === 'pick-up') {
        payload.fill_type = 'map'
        payload.branch_distance = '1'        
      }

      if (voucherCodes.length) {
        payload.voucher_codes = voucherCodes;
      }
      if (apcCardCode) {
        payload.apc_card_code = apcCardCode;
      }
      
      payload = this.clean(payload);
      const headers = {
        Authorization: `Bearer ${token}`,
        // "x-branch": this.hasProducts() ? branch || "all" : "all",
        "x-branch": await this.getBranch(payload),
      };
      console.log('get cart total...', payload)     


      const cartTotal: any = await this.apiService.postRequest({
        url: "/auth/cart/order-details",
        body: payload,
        headers,
      });
      
      
      // const cartTotalKeys = Object.keys(cartTotal);
      // cartTotalKeys.map((key: any) => {
      //   // tslint:disable-next-line: radix
      //   cartTotal[key].amount = (parseInt(cartTotal[key].amount) * 0.01);
      // });
      this.cartTotalObj = cartTotal;
      console.log('cart total => ', this.cartTotalObj)
      console.log('store cart => ', await this.storeService.getItem('cart'))
      
      //this.getCartItems()
      // this.setMinimumPrice(postalCode, cartTotal);
    } catch (err) {
      const hasMessageKey = Object.keys(err.error).includes("message");
      if (hasMessageKey) {
        if (err.error.message.includes("Header X-Branch value")) {
          await this.resetBranchLocation();
          return;
        }
      }

      if (err.status === 401) {
        return;
      }
      const {isTake1Invalid}: any = await this.apiService.showErrors(err);
      if (isTake1Invalid) {
        await this.removeAllCartItems();
        await this.emptyCart();
        window.location.reload();
      }
    }
  }

  async recalculateTotal() {
    const user = await this.storeService.getItem("user");
    const brgy = (await this.storeService.getItem("brgy_segment")) || null;
    if (user) {
      this.getCartTotal(brgy);
      return;
    }
    this.getCartTotalGuest(brgy, this.cartStorage);
  }

  async emptyCart(ignoreToast?) {
    const cartStore = await this.storeService.getItem("cart");
    if (cartStore) {
      if (!ignoreToast) {
        this.showSuccessToast("Items are removed on the cart.");
      }
      this.cartTotalValue = "0";
      await this.storeService.removeItem("cart");
      await this.storeService.removeItem("voucher_codes");
      this.cartTotalObj = null;
      this.cartStorage = [];
    }
  }

  sortArrayByObjectProp(arr = [], prop) {
    if (arr.length < 2) {
      return arr;
    }
    return arr.sort((a, b) => a[prop].localeCompare(b[prop]));
  }

  async guestCartValidationChecker(barangay, cartItems, apcCardCode?) {
    if (!cartItems) {
      return;
    }
    const cartPayload = [];

    // const apc_card_code = this.userService.apcCardNumber;
    cartItems.filter((item) => {
      console.log('item => ', item);
      if (item.type === 'e-gift-certificate-cart') { return false }
      let product: any = {
        product: item.id,
        quantity: item.quantity,
        // take1: item.take1,
        // variants: item.selectedVariants.map(variant => variant.segment),
        // additional_products: item.selectedAdditionalProducts.map(addon => addon.id)
      };
      if (item.selectedVariants.length) {
        product.variants = item.selectedVariants.map((variant) => {
          return { id: variant.segment, quantity: variant.quantity };
        });
      }
      console.log('[cart service] guest cart validation checker take1 => ', item.take1);
      if (item.take1 && ((apcCardCode || this.hasApcCategory()) || (!(apcCardCode || this.hasApcCategory) && this.isDoubleDealCategory(item)))) {
        console.log('[guest cart validation checker] take1 => ', item.take1)
        product.take1 = item.take1.id;

        const selectedVariants = item.take1.selectedVariants
        console.log('selected variants =>', selectedVariants)

        if (selectedVariants && selectedVariants.length) {
          product.take1_variants = selectedVariants.map(v => {
            if (!v.id || !item.id || !item.take1.id) return
            let tempId = v.id.replace(item.id,'').replace(item.take1.id,'');
            console.log('temp id =>', tempId);
            let id = tempId.split('-').filter(s => !!s).join('-');
            return {id, quantity: 1}
          })
        }
      }

      
      console.log("product variants => ", product.variants);
      if (item.selectedAdditionalProducts.length) {
        product.additional_products = item.selectedAdditionalProducts.map(
          (addon) => addon.id
        );
      }
      if (item.voucher_code.length) {
        product.voucher_code = item.voucher_code;
      }
      cartPayload.push(product);
    });
    if (!cartPayload.length) {
      return;
    }
    let payload: any = {
      cart: [...cartPayload],
      // apc_card_code: apcCardCode
    };
    if (apcCardCode) {
      payload.apc_card_code = apcCardCode;
    }

    if (this.hasProducts(cartPayload)) { 
      this.allCartProd = true
      const deliveryType = await this.storeService.getItem("deliveryType");
      if (deliveryType) {
        payload.claim_type = deliveryType || "delivery";
      }
  
      if (barangay && deliveryType === "delivery") {
        payload.barangay = barangay;
        payload.fill_type = 'manual'
      }
    } else {
      this.allCartProd = false
    }

    if (payload.claim_type === 'pick-up') {
      payload.fill_type = 'map'
      payload.branch_distance = '1'        
    }

    // if (this.isOnlyEgc()) {
    //   this.allCartEgc = true
    //   if(barangay) {
    //     payload.barangay = barangay     
    //     payload.fill_type = 'manual'         
    //   }
    // } else {
    //   this.allCartEgc = false
    //   if(barangay) {
    //     payload.barangay = barangay     
    //     payload.fill_type = 'manual'         
    //   }
    // }

    const voucherCodes: any =
      (await this.storeService.getItem("voucher_codes")) || [];
    if (voucherCodes.length) {
      payload.voucher_codes = voucherCodes;
    }

    const branch = await this.storeService.getItem("branch");
    payload = this.clean(payload);
    const headers = {
      // "x-branch": this.hasProducts() ? branch || "all" : "all",
      "x-branch": await this.getBranch(payload),
    };
    const cartTotal: any = await this.apiService.postRequest({
      url: "/auth/cart/order-details/guest",
      body: payload,
      headers,
    });
  }

  customErrMsgCheckTotalGuest(err) {
    const hasMessageKey = Object.keys(err.error).includes("message");
    if (hasMessageKey) {
      this.toastService.showDangerToast(err.error.message);
      return;
    }
    const errorFields = Object.keys(err.error);
    const cartIndexShown = [];
    const regexNum = /\d+/g;
    errorFields.filter((key) => {
      err.error[key].filter(async (errMessage) => {
        const cartIndex = key.match(regexNum);
        if (cartIndex.length) {
          if (!cartIndexShown.includes(cartIndex[0])) {
            if (!this.cartStorage) {
              return;
            }
            if (!this.cartStorage.length) {
              return;
            }
            const product = this.cartStorage.splice(cartIndex[0], 1);
            await this.storeService.setItem("cart", this.cartStorage);
            cartIndexShown.push(cartIndex[0]);

            // tslint:disable-next-line: max-line-length
            this.toastService.showDangerToast(
              `Product ${product[0].title} has been removed to your cart because the product has been updated.`
            );
            return;
          }
        }

        this.toastService.showDangerToast(errMessage);
      });
    });
  }

  async getNotifs() {
    try {
      const token = await this.storeService.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const res = await this.apiService.request({
        url: "/notifications",
        method: "GET",
        jsona: true,
        headers,
      });
      if (res.length) {
        res.filter((notif) => {
          if (notif.type == "warning") {
            this.toastService.showDangerToast(notif.message);
          } 
        });
      }
      console.log("notifs", res);
    } catch (err) {
      console.log(err);
    }
  }

  async resetBranchLocation() {
    await this.storeService.removeItem("branch");
    await this.storeService.removeItem("branchLocation");
    await this.storeService.removeItem("deliveryType");
    await this.emptyCart(true);
    window.location.reload();
    return;
  }

  get cart() { return this.cartTotalObj; }
  
  async isBranchValid() {
    try {
      const branch = await this.storeService.getItem('branch');
      let deliveryType: any = await this.storeService.getItem('deliveryType');
  
      if (!branch || !deliveryType) {
        console.log('branch/delivery type is undefined')
        return;
      }
  
      deliveryType = deliveryType.replace('-', '_');
      
      console.log('branch => ', branch);
      console.log('deliveryType => ', deliveryType);
  
      const {data} = await this.apiService.request({
        method: 'GET',
        url: `/branches/${branch}`
      });
  
      if (!data) {
        console.log('empty response');
      }
  
      let enabled;
  
      if (data) {
        enabled = data.attributes 
        && data.attributes.extra 
        && data.attributes.extra[deliveryType] 
        && data.attributes.extra[deliveryType].status;
      }
  
      console.log('enabled => ', enabled);
  
      if (!enabled || !data) {
        throw "Branch not available"
        // modalCtrl.componentInstance.title = title;
      }
    } catch (err) {

      const modalCtrl = this.modalService.open(InformationDialogComponent, {
        ariaLabelledBy: "modal-basic-title",
        windowClass: "information-dialog-container",
        size: "lg",
        centered: true,
      });
      modalCtrl.componentInstance.message = `Hi! Delivery service is currently not available in your area, you may try our pick up option instead or call this number ${GlobalService.PHONE_NUMBER}.`;
      modalCtrl.result.then(async () => {
        await this.resetBranchLocation();
      });
    }
    
  }


  // async getBranches() {
  //   try {
  //     const branchList = await this.apiService.request({
  //       method: 'GET',
  //       url: '/branches',
  //       jsona: true
  //     });
  //     const sortedBranch = branchList.sort((a, b) => a.title.localeCompare(b.title));
  //     this.branches = sortedBranch;
  //     console.log('branches => ', this.branches);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  isOnlyEgc() {
    let value = this.hasValue(
      { arr: this.cartStorage, field: 'type', value: 'carts', exists: false }    );
    return value;
  }

  hasEgc() {
    let value = this.hasValue(
        { arr: this.cartStorage, field: 'type', value: 'e-gift-certificate-cart' }    );
    return value;
  }

  hasProducts(cart?) {
    let value = this.hasValue(
        { arr: this.cartStorage, field: 'type', value: 'carts' }    ) 
      || this.hasValue(
        { arr: this.cartStorage, field: 'type', value: 'products' }    );
    // console.log('has products => ', value);
    // console.log('cart storage => ', this.cartStorage);
    
    if (cart) {
      value = cart && cart.length > 0
    }
    return value;
  }

  hasProduct(id) {
    let value = this.hasValue(
        { arr: this.cartStorage, field: 'id', value: id }    ) 
      || this.hasValue(
        { arr: this.cartStorage, field: 'product.id', value: id }    );
    // console.log('has products => ', value);
    console.log('[cart.service.hasProduct] cart storage => ', this.cartStorage);
    
    // if (cart) {
    //   value = cart && cart.length > 0
    // }
    return value;
  }

  hasProductCategory(productCategoryId, cartProducts?) {
    let value = false;
    let products = cartProducts || this.cartStorage;
    if (!products || !products.length) return false;
    for (let i = 0; products.length > i; i++) {

      value = this.isProductCategory(products[i], productCategoryId);

      // let productCategories = products[i].product.productCategories || products[i].productCategories;
      // if (products[i].product && products[i].product.productCategories && products[i].product.productCategories.length) {
      //   value = this.hasValue({ arr: products[i].product.productCategories, field: 'id', value: productCategoryId })
      // }

      // if (!value && products[i].productCategories && products[i].productCategories.length) {
      //   value = this.hasValue({ arr: products[i].productCategories, field: 'id', value: productCategoryId })
      // }

      if (value) return true;
    }
    return false;
  }

  isProductCategory(product, productCategoryId) {
    let value = false;
    // let productCategories = products[i].product.productCategories || products[i].productCategories;
    if (product.product && product.product.productCategories && product.product.productCategories.length) {
      value = this.hasValue({ arr: product.product.productCategories, field: 'id', value: productCategoryId })
    }

    if (!value && product.productCategories && product.productCategories.length) {
      value = this.hasValue({ arr: product.productCategories, field: 'id', value: productCategoryId })
    }
    // console.log('value =>', value, productCategoryId)
    return value;
  }

  async setTake1Items(active) {
    console.log('set take 1 items...', active)
    const token = await this.storeService.getItem('token');
    console.log('[set take 1 items] cart storage => ', this.cartStorage);

    const getProduct = async (hashedId) => {
      try {
        const branch = await this.storeService.getItem('branch');
        let headers = {};
  
        headers = {
          'x-branch': branch || 'all'
        };
        

      let url = `/v2/products/${hashedId}`

      const apcCardCode = await this.storeService.getItem("apc");
        
        if (this.hasProductCategory(this.buytake1Details.apc_card_category) || apcCardCode) {
          url += '?only_apc_variant_buy1_take1=1';
        }

        return await this.apiService.request({
          method: 'GET',
          url,
          headers,
          jsona: true
        });
      } catch (err) {
        console.log(err);
        
      }
    }

    const getTake1Items = async (productId, variantId) => {
      try {
  
        let headers = {};
  
        const branch = await this.storeService.getItem("branch");
        headers = {
          "x-branch": branch || "all",
        };
  
        return await this.apiService.request({
          url: `/v2/products/take1?product=${productId}&variant=${variantId}`,
          method: 'GET',
          headers,
          jsona: true
        });

      } catch (err) {
        console.log(err);
      }
    }
    
    if (!this.cartStorage || this.cartStorage <= 0) return this.cartStorage;
    this.cartStorage = await Promise.all(this.cartStorage.map(async item => {
      let take1ItemSelected;
      try {
        console.log('item: ',item, this.cartStorage);
        if(item.type !== "e-gift-certificate-cart") {
                  
          let {variantId} = this.getSelectedVariant(item, this.buytake1Details.price_variant_type);
          let tempId = variantId.replace(item.id,'').replace(item.take1.id,'');
          console.log('temp id =>', tempId);
          variantId = tempId.split('-').filter(s => !!s).join('-');
        

          let take1Items;
          if (variantId && item.id) take1Items = await getTake1Items(item.id, variantId);
    
          // checks if using apc card
          if (take1Items && take1Items.length > 0) {
            take1ItemSelected = take1Items[0];
          }
          
        }
      } catch (err) {
        console.log(err);
      }
      if (active) {
        if (!token) {
    
          // console.log('take 1 items => ', res);
          const product = await getProduct(item.id);
          console.log('[set take 1 items] product => ', product);

          if (product && (product.apc_card_buy1take1_available)) {

            if (item.take1) {
              return item;
            }
            
            return {
              ...item,
              take1: take1ItemSelected
            }
          }


          // items.take1 = 
        } else {
          const product = await getProduct(item.product.id)
  
          if (product && (product.apc_card_buy1take1_available)) {
            item.product.take1 = take1ItemSelected.id

            const selectedVariants = item.take1.selectedVariants
            console.log('selected variants =>', selectedVariants)
    
            if (selectedVariants && selectedVariants.length) {
              product.take1_variants = selectedVariants.map(v => {
                if (!v.id || !item.id || !item.take1.id) return
                let tempId = v.id.replace(item.id,'').replace(item.take1.id,'');
                console.log('temp id =>', tempId);
                let id = tempId.split('-').filter(s => !!s).join('-');
                return {id, quantity: 1}
              })
            }
            // this.updateCartItem(item)
          }
        }

        return item;
      } else {
        if (!token) {
    
          // console.log('take 1 items => ', res);
          const product = await getProduct(item.id);
          console.log('[set take 1 items] product => ', product);

          if (product && (product.apc_card_double_deal)) {

            if (item.take1) {
              return item;
            }
            
            return {
              ...item,
              take1: take1ItemSelected
            }
          }


          // items.take1 = 
        } else {
          //const product = await getProduct(item.product.id)

          const product = await getProduct(item.id)
  
          if (product && (product.apc_card_double_deal)) {
            item.product.take1 = take1ItemSelected.id

            const selectedVariants = item.take1.selectedVariants
            console.log('selected variants =>', selectedVariants)
    
            if (selectedVariants && selectedVariants.length) {
              product.take1_variants = selectedVariants.map(v => {
                if (!v.id || !item.id || !item.take1.id) return
                let tempId = v.id.replace(item.id,'').replace(item.take1.id, '');
                console.log('temp id =>', tempId);
                let id = tempId.split('-').filter(s => !!s).join('-');
                return {id, quantity: 1}
              })
            }
            // this.updateCartItem(item)
          }
        }

        // return item;
      }
      console.log('[set take 1 items] authenticated...')
      if (item.take1) {
        console.log('has take 1')
        delete item['take1']
      }

      return item;
    }))
    console.log('[set take 1 items] cart storage => ', this.cartStorage);
    await this.storeService.setItem("cart", this.cartStorage);
    const deliveryType = await this.storeService.getItem("deliveryType");
    const brgy =
      deliveryType === "delivery"
        ? await this.storeService.getItem("brgy_segment")
        : null;
    
    if (!token) await this.getCartTotalGuest(brgy, this.cartStorage);
    this.cartCountSubject.next(this.cartStorage ? this.cartTotalQuantity() : 0);

    
  }

  getSelectedVariant(product, type) {
    console.log("selectedVariats", product, type);


    if (!product.variants[type] || product.variants[type].length <= 0) {
      return null;
    }
    
    let selectedVariants = product.variants[type].filter(v => v.selected);
    return selectedVariants[0] || null;
  }

  hasValue({ arr, field, value, exists = true }: { arr; field; value; exists?: boolean; }) {
    if (!arr || !arr.length) return false;
    for (let i = 0; arr.length > i; i++) {
      let f = field.split('.');

      if (f && f.length === 1 && arr[i][f[0]] === value) {
        return exists;
      } else if (f && f.length === 2 && arr[i][f[0]] && arr[i][f[0]][f[1]] && arr[i][f[0]][f[1]] === value) {
        return exists;
      }
    }
    return !exists;
  }

  async getBranch(payload) {
    const token = await this.storeService.getItem("token");
    const branch = await this.storeService.getItem('branch');
    if (!token) {
      return payload && payload.cart && payload.cart.length > 0 || this.isOnlyEgc() ? branch : 'all'
    }
    // return this.hasProducts() || (!this.hasProducts() && !this.hasEgc()) ? branch : 'all'
    return this.hasProducts() || this.isOnlyEgc() ? branch : 'all'
  }

  clean(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '' || obj[propName].length === 0) {
        delete obj[propName]
      }
    }
    return obj;
  }

  async getMinimumOrder() {
    const deliveryType = await this.storeService.getItem("deliveryType");
    if (!this.appService.siteSettings || !this.appService.siteSettings) return;
    const minimumOrder = deliveryType === 'pick-up' ? this.appService.siteSettings.minimum_order_pick_up : this.appService.siteSettings.minimum_order_delivery;
    return minimumOrder
  }



  async getBuy1Take1Details() {
    try {
      const res = await this.apiService.request({
        url: '/buy1_take1_details',
        method: 'GET',
      });
      console.log('[app.service.getBuy1Take1Details] res =>', res)
      this.buytake1Details = res;
      // console.log('site settings =>', this.siteSettings);
      // const metaConfig = {
      //   title: this.siteSettings.meta_tag_title,
      //   description: this.siteSettings.meta_tag_description,
      //   author: this.siteSettings.meta_tag_author,
      //   image: this.siteSettings.meta_tag_logo
      // };
      // this.siteName = this.siteSettings.site_title || '';
      // this.updateOgTags(metaConfig);
      // if (isPlatformBrowser(this.platformId)) {
      //   this.addFavicon(this.siteSettings.site_favicon);
      // }
    } catch (err) {
      console.log(err);
    }
  }

  isApcCategory(item) {
    return this.isProductCategory(item, this.buytake1Details.apc_card_category)
  }

  hasApcCategory() {
    return this.hasProductCategory(this.buytake1Details.apc_card_category)
  }

  isDoubleDealCategory(item) {
    return this.isProductCategory(item, this.buytake1Details.double_deal_category)
  }

  hasDoubleDealCategory(cartItems?) {
    return this.hasProductCategory(this.buytake1Details.double_deal_category, cartItems)
  }

  isTake1ProductCategories(item) {
    let productCategories = this.buytake1Details.product_categories
    // console.log('[isTake1ProductCategories] variants =>', productCategories);
    for (let i = 0; productCategories.length > i; i++) {
     if (this.isProductCategory(item, productCategories[i])) return true
    }
    return false;
  }

  hasTake1ProductCategories(cartItems?) {
    let productCategories = this.buytake1Details.product_categories
    for (let i = 0; productCategories.length > i; i++) {
     if (this.hasProductCategory(productCategories[i], cartItems)) return true
    }
    return false;
  }

}

export interface CartTotal {
  amount: string;
  currency: string;
  formatted: string;
}

export interface CartTotalObject {
  discount_subtotal_price: CartTotal;
  discount_delivery_price: CartTotal;
  delivery_price: CartTotal;
  payable_price: CartTotal;
  sub_total_price: CartTotal;
  products: Array<ProductCart>;
  total_preparation_time: string;
}

export interface ProductCart {
  id: string;
  total_price: CartTotal;
  price: CartTotal;
  discount_price: CartTotal;
  discount_total_price: CartTotal;
  title: string;
  quantity: number;
  preparation_time: string;
  voucher: VoucherProductCart;
}

export interface VoucherProductCart {
  code: string;
  description: string;
  discount_type: string;
  discount_value: string;
  title: string;
}
