import { BranchLocationService } from "./../../services/branch-location/branch-location.service";
import { PromoCodeModalComponent } from "./../promo-code-modal/promo-code-modal.component";
import { AppService } from "./../../services/app/app.service";
import { ConfirmDialogComponent } from "./../confirm-dialog/confirm-dialog/confirm-dialog.component";
import { InformationDialogComponent } from "./../information-dialog/information-dialog.component";
import { SelectBranchComponent } from "./../select-branch/select-branch.component";
import { LoaderService } from "./../../services/loader.service";
import { WindowResizeService } from "src/app/services/window-resize.service";
import { Title } from "@angular/platform-browser";
import { ToastService } from "./../../services/toast/toast.service";
import { ApiService } from "./../../services/api/api.service";
import { StoreService } from "./../../services/store/store.service";
import { CartService } from "./../../services/cart/cart.service";
import { isPlatformBrowser, Location } from "@angular/common";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import {
  Component,
  OnInit,
  AfterViewInit,
  Inject,
  PLATFORM_ID,
  HostListener,
  ModuleWithComponentFactories,
} from "@angular/core";
import Swiper from "swiper";
import { LocationWidgetModalComponent } from "../location-widget-modal/location-widget-modal.component";
import { UnslugifyPipe } from "../../pipes/unslugify/unslugify.pipe";
import { GlobalService } from "src/app/services/global.service";
import { UserService } from "src/app/services/user/user.service";
import { FavoriteItemComponent } from "../favorite-item/favorite-item.component";
import { Router } from "@angular/router";
@Component({
  selector: "app-menu-item",
  templateUrl: "./menu-item.component.html",
  styleUrls: ["./menu-item.component.scss"],
  providers: [UnslugifyPipe],
})
export class MenuItemComponent implements OnInit {
  pageUrlKey = "";
  useVoucherCode = true; // auto use in true default value
  isFromSearch = false;
  searchProductByVoucherFn: any;

  state: any
  isAPCSet = true;

  modalCtrl: any;
  childModalState = false; // means another modal is not on current view
  productDetails: any;
  pageNumber: any;
  catSegment = "";

  variantLimitArr = [];
  disabledVariants = [];

  isCartEdit = false;
  productIndexFromCart = null;

  productSlider = {
    slidesPerView: 1,
    observeParents: true,
    observer: true,
    watchOverflow: true,
    navigation: {
      nextEl: ".slider-next",
      prevEl: ".slider-prev",
    },
  };

  take1Items;
  take1SelectionsCollapse;
  take1ItemSelected;
  isTake1Loading = false;

  isAddToCartLoading = false;

  defaultVariantType = '';

  user: any;
  favSetProd: any;
  storeProd: any = []
  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    this.closeModal();
  }

  egcEnabled;

  constructor(
    private modalService: NgbModal,
    public cartService: CartService,
    private location: Location,
    private storeService: StoreService,
    private apiService: ApiService,
    private toastService: ToastService,
    private title: Title,
    public windowResizeService: WindowResizeService,
    private loaderService: LoaderService,
    private unslugify: UnslugifyPipe,
    private appService: AppService,
    public branchLocationService: BranchLocationService,
    public userService: UserService,
    public router: Router,
    @Inject(PLATFORM_ID) public platformId
  ) {
    this.egcEnabled = GlobalService.FEATURES.egc;
  }

  async ngOnInit() {
    this.user = await this.storeService.getItem("user")
    console.log(this.state);    
    if (this.productDetails) {

      if(this.user) {      
        //this.productDetails.selectedVariants.splice(0,2)
        //this.productDetails.selectedVariants[1].quantity = product.selectedVariants[1].quantity        
      }

      console.log(this.disabledVariants)
      if (this.cartService.isTake1ProductCategories(this.productDetails) || this.productDetails.apc_card_double_deal) {
        this.defaultVariantType = this.cartService.buytake1Details.price_variant_type;
      }

      this.productDetails.variants = this.cleanKeys(this.productDetails.variants)
      let variantTypes = Object.keys(this.productDetails.variants) || [];
      variantTypes.forEach(v => {
        this.productDetails.variants[v] = this.productDetails.variants[v].map(variant => {
          return {...variant, type: v}
        })
      })
      

      console.log('product details => ', this.productDetails);
      
      // console.log('clean keys =>', this.cleanKeys(this.productDetails.variants))

      if (this.productDetails.extra.maximum_variant_selection) {
        this.variantLimitArr = Object.keys(
          this.productDetails.extra.maximum_variant_selection
        );
        console.log("variant limit array => ", this.variantLimitArr);
      }


      let selectedVariants = {};

      if (this.productDetails.selectedVariants && this.productDetails.selectedVariants.length) {
        this.productDetails.selectedVariants = this.productDetails.selectedVariants.map(v => {
          // v.type = v.type.charAt(0).toUpperCase() + v.type.slice(1);
          if (this.variantLimitArr.includes(v.type.toLowerCase().replaceAll(' ', '-'))) {
            if (!selectedVariants[v.type]) {
              selectedVariants[v.type] = 1
            } else {
              selectedVariants[v.type]++
            }
          }
          return v;
        })

        let selectedVariantKeys = Object.keys(selectedVariants);
        selectedVariantKeys.forEach((k:any) => {
          console.log(this.productDetails.extra.maximum_variant_selection[k.toLowerCase().replaceAll(' ', '-')])
          if (selectedVariants[k] >= this.productDetails.extra.maximum_variant_selection[k.toLowerCase().replaceAll(' ', '-')].maximum_selection) {
            this.productDetails 
            && this.productDetails.variants[k]
            && this.productDetails.variants[k][0] 
            && this.productDetails.variants[k][0].variants.map(v => {
              if (!v.selected) {
                v.disabled = true;
              }
              return v;
            })
          }
        });
        console.log(selectedVariants)
      }

      const keys = Object.keys(this.productDetails);
      if (keys.includes("disabled_variants")) {
        this.disabledVariants = this.productDetails.disabled_variants;
      }

      // this.setVoucherOnProductCart(); // temp rm
      if (this.productDetails.apc_card_buy1take1_available || this.productDetails.apc_card_double_deal) {
        this.getTake1Items(true);
      }

      // if (this.cartService.isTake1ProductCategories(this.productDetails) && this.getVariantTypes(this.productDetails.variants, 'type').includes()) 

      // console.log('take1 => ', this.productDetails.take1);
      let variants = this.productDetails.variants ? Object.keys(this.productDetails.variants) : []
      if (variants.length > 0) {
        // this.defaultVariantType = variants[0];
        for (let i = 0; variants.length > i; i++) {
        let defaultVariantSizeCrust = this.getDefaultVariant(variants[i]);
        if (defaultVariantSizeCrust) {
          this.productDetails.selectedVariants.push({...defaultVariantSizeCrust, selected: true, quantity: 1})
          console.log(this.productDetails);
          this.productDetails.variants[variants[i]] = this.productDetails.variants[variants[i]].map(v => {
            if (v.id === defaultVariantSizeCrust.id) {
              return {...v, selected: true, quantity: 1}
            }
            return v;
          })
        }
      }
      }
      // let defaultVariantSizeCrust = this.getDefaultVariant('Size & Crust');
      // if (defaultVariantSizeCrust) {
      //   this.productDetails.selectedVariants.push({...defaultVariantSizeCrust, selected: true, quantity: 1})
      //   console.log(this.productDetails);
      //   this.productDetails.variants['Size & Crust'] = this.productDetails.variants['Size & Crust'].map(v => {
      //     if (v.id === defaultVariantSizeCrust.id) {
      //       return{...v, selected: true, quantity: 1}
      //     }
      //     return v;
      //   })
      // }
    }
  }

  closeModal() {
    // this.modalService.dismissAll();
    this.modalCtrl.close();
    let catSegment = this.catSegment || this.productDetails.productCategories.length ? this.productDetails.productCategories[0].segment : ''
    if (this.modalCtrl && !this.childModalState && !this.isCartEdit) {
      this.location.replaceState(
        `${this.pageUrlKey || "menu"}/${this.pageNumber}/${catSegment}`
      );
      let siteTitle = this.pageUrlKey === "use-my-coupon" ? "Promo" : "Menu";
      if (this.appService.siteName) {
        siteTitle = `${siteTitle} - ${this.appService.siteName}`;
      }
      this.title.setTitle(siteTitle);
    }
  }

  async showBranchList() {
    const branch = await this.storeService.getItem("branch");
    const branchLocation = await this.storeService.getItem("branchLocation");
    const deliveryType = await this.storeService.getItem("deliveryType");
    // if (!branchLocation || !branch) {
    //   return;
    // }
    // if ((branchLocation['city'] && deliveryType === 'delivery') || (branchLocation['selected_branch'] && deliveryType === 'pick-up')) {
    //   return;
    // }

    const user = await this.storeService.getItem("user");
    const modalComponent = this.modalService.open(
      LocationWidgetModalComponent,
      { windowClass: "location-widget-modal", size: "lg", centered: true }
    );
    modalComponent.componentInstance.user = user;
    modalComponent.componentInstance.isOnMenu = true;
    modalComponent.componentInstance.modalCtrl = modalComponent;
    this.childModalState = true;
    modalComponent.result.then((value) => {
      if (value) {
        console.log(value);
        this.childModalState = value.modalState;
      }
    });
  }

  async setVoucherOnProductCart() {
    const product = JSON.parse(JSON.stringify(this.productDetails)); // deep copy;
    const productOnCart: any = await this.cartService.voucherCodeInCart(
      product
    );

    if (!Array.isArray(productOnCart)) {
      return;
    }
    if (!productOnCart.length) {
      return;
    }
    const cartItem = productOnCart[0];
    if (!cartItem.voucher_code) {
      return;
    }

    this.productDetails.voucher_code = cartItem.voucher_code;
    this.useVoucherCode = true;
  }

  async showVoucherModal(cartItemAddedSuccessText) {
    const modalComponent = this.modalService.open(PromoCodeModalComponent, {
      windowClass: "location-widget-modal",
      size: "lg",
      centered: true,
    });
    modalComponent.componentInstance.modalCtrl = modalComponent;
    modalComponent.componentInstance.cartItemAddedSuccessText = cartItemAddedSuccessText;
    modalComponent.componentInstance.searchPromoCodeFn = this.searchProductByVoucherFn.bind(
      this
    );
  }

  async addToCart(productItem) {
    if (this.isAddToCartLoading) return;
    this.isAddToCartLoading = true;
    const token = await this.storeService.getItem("token");

    if (this.cartService.isApcCategory(productItem) && this.cartService.hasProducts()) {
      const modalComponent = this.modalService.open(ConfirmDialogComponent, {
        ariaLabelledBy: 'modal-basic-title',
        windowClass: 'confirm-dialog-modal',
        centered: true
      });
      modalComponent.componentInstance.title = '';
      modalComponent.componentInstance.buttonText.accept = 'Yes';
      modalComponent.componentInstance.buttonText.cancel = 'No';
      modalComponent.componentInstance.confirmationMessage = 'Adding APC Card would reset your cart and activate buy 1 take 1, do you want to proceed?';
      const res = await modalComponent.result;
      console.log(res);
      if (!res) {
        this.isAddToCartLoading = false;
        return;
      }

      if (token) {
        this.loaderService.showAsyncLoader();
        await this.cartService.removeAllCartItems();
        this.loaderService.hideAsyncLoader();
      }
      this.cartService.emptyCart();
    }

    let product = JSON.parse(JSON.stringify(productItem)); // deep copy;
    console.log('set take 2...', product, this.cartService)


    if (this.take1ItemSelected) {
      console.log('set take 1...')
      product.take1 = this.take1ItemSelected
      console.log('[add to cart] take 1 item => ', product.take1)
    }

    if (!this.useVoucherCode) {
      product.voucher_code = "";
    }

    const branch = await this.storeService.getItem("branch");
    const branchLocation: any = await this.storeService.getItem(
      "branchLocation"
    );
    const deliveryType: any = await this.storeService.getItem("deliveryType");
    if (!branchLocation || !branch) {
      this.showBranchList();
      this.isAddToCartLoading = false;
      return;
    }
    if (
      (!branchLocation["city"] && deliveryType === "delivery") ||
      (!branchLocation["selected_branch"] && deliveryType === "pick-up")
    ) {
      this.showBranchList();
      this.isAddToCartLoading = false;
      return;
    }

    // const productOnCart: any = await this.cartService.itemInCart(product);
    const productOnCart: any = await this.cartService.isInCart(product, "item");
    const productOnCartQuantity = productOnCart ? productOnCart.quantity : 0;
    console.log(productOnCartQuantity);
    if (product.quantity + productOnCartQuantity > 50) {
      this.toastService.showDangerToast(
        `${product.title} has a maximum quantity of 50`
      );
      this.isAddToCartLoading = false;
      // product.quantity = (50 - productOnCartQuantity);
      return;
    }

    if (
      (product.quantity + productOnCartQuantity < product.minimum_quantity &&
        !(await this.cartService.isInCart(product))) ||
      product.quantity === 0 ||
      !product.quantity
    ) {
      this.toastService.showDangerToast(
        `${product.title} has a minimum quantity of ${product.minimum_quantity}`
      );
      this.isAddToCartLoading = false;
      return;
    }

    console.log('token => ', token)
    const brgy =
      deliveryType === "delivery"
        ? await this.storeService.getItem("brgy_segment")
        : null;

    if (!Array.isArray(product.variants)) {
      // {} formatted variants, [] empty variants

      if (product.variants.length && product.selectedVariants.length <= 0) {
        this.showDangerToast(`Please choose a variant first.`);
        this.isAddToCartLoading = false;
        return;
      }

      const missingSelectedVariants = this.isAllVariantsSelected(
        product.selectedVariants
      );
      if (missingSelectedVariants.length) {
        missingSelectedVariants.filter((type) => {
          this.showDangerToast(
            `${this.unslugify.transform(type)} is required.`
          );
        });
        this.isAddToCartLoading = false;
        return;
      }


      let error = false;
      product.selectedVariants.forEach(v => {
        if (v.quantity < 1) {
          this.showDangerToast(`${v.title} has a minimum quantity of 1`);
          error = true;
        } else if (v.quantity > 50) {
          this.showDangerToast(`${v.title} has a maximum quantity of 50`);
          error = true;
        }
      });
      if (error) {
        this.isAddToCartLoading = false;
        return
      };
    }

    // const itemInCart: any = await this.cartService.itemInCart(product);
    const itemInCart: any = await this.cartService.isInCart(product, "item");
    let itemQuantityInCart = 0;
    if (itemInCart) {
      itemQuantityInCart = itemInCart.quantity;
    }

    console.log('product => ', product)

    if (!token) {
      try {
        const apcCardCode = this.userService.apcCardNumber;
        const cartClone = await this.cartService.preCartValidationTransform(
          product
        );
        console.log("cart clone", cartClone);
        await this.cartService.guestCartValidationChecker(brgy, cartClone, apcCardCode);
      } catch (err) {
        console.log(err);
        const hasMessageKey = Object.keys(err.error).includes("message");
        if (hasMessageKey) {
          if (err.error.message.includes("Header X-Branch value")) {
            await this.resetBranchLocation();
            this.isAddToCartLoading = false;
            return;
          }
        }

        // this.cartService.customErrMsgCheckTotalGuest(err);
        if (err.status === 401) {
          this.isAddToCartLoading = false;
          return;
        }
        this.apiService.showErrors(err);
        this.isAddToCartLoading = false;
        return;
      }
    }

    // if (!token) {
    try {
      const hasStock: any = await this.cartService.checkProductStock({
        ...product,
        quantity: itemQuantityInCart + product.quantity,
      });
      if (hasStock.message === "has-stock") {
        if (!token) {
          // api validation checker (guest)
          await this.cartService.setCartData(product);

          const successMessage = `(${product.quantity}) ${product.title} successfully added to cart.`;
          this.toastService.showSuccessToast(successMessage);

          if (product.voucher_code) {
            this.modalCtrl.dismiss();
            this.showVoucherModal(successMessage);
          }
          
          this.closeModal();
          this.cartService.setTake1Items((this.cartService.hasApcCategory() || this.userService.apcCardNumber));
          console.log('cart storage => ', this.cartService.cartStorage)

          if (this.cartService.isApcCategory(productItem)) {
            window.location.reload();
          }

          this.isAddToCartLoading = false;
          return;
        }
      }
      if (hasStock.message === "no-stock") {
        this.toastService.showDangerToast(
          `Sorry, ${product.title} is out of stock.`
        );
        this.isAddToCartLoading = false;
        return;
      }
    } catch (err) {
      console.log("err", err);
      this.loaderService.hideAsyncLoader();

      if (err.error.message === "stock not found" && err.status === 404) {
        this.toastService.showDangerToast(
          `Sorry, ${product.title} is out of stock.`
        );
      }

      const hasMessageKey = Object.keys(err.error).includes("message");
      if (hasMessageKey) {
        if (err.error.message.includes("Header X-Branch value")) {
          await this.resetBranchLocation();
          this.isAddToCartLoading = false;
          return;
        }
      }

      this.isAddToCartLoading = false;
      return;
    }
    // }
    this.loaderService.showAsyncLoader();

    let headers = {};

    headers = {
      "x-branch": branch || "all",
    };

    headers = { ...headers, Authorization: `Bearer ${token}` };

    try {
      const additionalProductPayload = product.selectedAdditionalProducts.map(
        (addon) => addon.id
      );
      const variantsPayload = product.selectedVariants.map((variant) => {
        return { id: variant.segment, quantity: variant.quantity };
      });
      console.log(variantsPayload);
      const voucherCodes: any =
        (await this.storeService.getItem("voucher_codes")) || [];
      
      
      const apcCardCode = await this.storeService.getItem("apc");

      const reqBody: any = {
        product: product.id,
        quantity: product.quantity,
        dietary_requirements: product.extra.dietary_requirements,
        sub_category: product.selected_sub_category,
        voucher_codes: voucherCodes,
        barangay: brgy,
        // apc_card_code: apcCardCode || null,
        // take1: product.take1.id || null
      };

      console.log('add to cart: ', reqBody);
      

      if (((apcCardCode || this.cartService.hasApcCategory()) || (!(apcCardCode || this.cartService.hasApcCategory()) && this.cartService.isDoubleDealCategory(product))) && product && product.take1 && product.take1.id ) {
        reqBody.apc_card_code = apcCardCode || null;
        reqBody.take1 = product.take1.id;

        const selectedVariants = product.take1.selectedVariants
        console.log('selected variants =>', selectedVariants)

        if (selectedVariants && selectedVariants.length) {
          reqBody.take1_variants = selectedVariants.map(v => {
            if (!v.id || !product.id || !product.take1.id) return
            let tempId = v.id.replace(product.id,'').replace(product.take1.id,'');
            console.log('temp id =>', tempId);
            let id = tempId.split('-').filter(s => !!s).join('-');
            return {id, quantity: 1}
          })
        }

      }

      if (variantsPayload.length) {
        reqBody.variants = variantsPayload;
      }
      if (additionalProductPayload.length) {
        reqBody.additional_products = additionalProductPayload;
      }
      if (product.voucher_code) {
        reqBody.voucher_code = product.voucher_code;
      }

      console.log(reqBody, this.cartService.cartStorage);
      


      let res: any = await this.apiService.postRequest({
        url: "/auth/cart",
        body: reqBody,
        headers,
      });

      if (this.egcEnabled) {
        const egcRes = await this.apiService.request({
          method: "GET",
          url: '/auth/e-gift-certificate-cart',
          headers,
        });
  
        res.data.push(...egcRes.data)
      }      
        console.log('res => ', res, productItem, product); 

        if(!this.cartService.cartStorage.length) {
          await this.storeService.removeItem("prodStore")
        }
        
        this.cartService.setCartStorage(res, productItem);
        console.log('cart store =', this.cartService.cartStorage, productItem);
        

        let prodStore = await this.storeService.getItem('prodStore')
        this.storeProd = prodStore ? prodStore : []

        let clone = this.cartService.cartStorage.find(variant => variant.product.id === product.id)
        clone.deep_copy = product

        this.storeProd.push(clone)

        await this.storeService.setItem('prodStore', this.storeProd)                                
                
        console.log(this.storeProd);

        
        //this.cartService.setCartStorage(clone, productItem);
      // this.showSuccessToast(product.title);
      const successMessage = `(${product.quantity}) ${product.title} successfully added to cart.`;
      this.toastService.showSuccessToast(successMessage);
      this.loaderService.hideAsyncLoader();

      if (product.voucher_code) {
        this.modalCtrl.dismiss();
        this.showVoucherModal(successMessage);
      }
      this.closeModal();
      await this.cartService.setTake1Items((this.cartService.hasApcCategory() || this.userService.apcCardNumber));

      if (this.cartService.isApcCategory(productItem)) {
        window.location.reload();
      }
      this.isAddToCartLoading = false;
      
    } catch (err) {
      this.loaderService.hideAsyncLoader();
      this.apiService.showErrors(err);
      this.isAddToCartLoading = false;
      // console.log(err);
      // const hasMessageKey = Object.keys(err.error).includes('message');
      // if (hasMessageKey) {
      //   this.toastService.showDangerToast(err.error.message);
      //   return;
      // }
      // const errorFields = Object.keys(err.error.errors);
      // errorFields.filter(key => {
      //   err.error.errors[key].filter(errMessage => {
      //     this.showDangerToast(errMessage);
      //   });
      // });
      // console.log(err);
    }
  }

  async addToFavorite(productDetails) {
    console.log(this.state, productDetails, this.isAPCSet);
    if (isPlatformBrowser(this.platformId)) {
      try {
        let addItemState = true
        this.favSetProd = await this.storeService.getItem('favSetStore')          
        console.log(this.favSetProd, this.isAPCSet);           
        
        if(this.state.isAPCSet){
          productDetails.originalPrice = {
            price: productDetails.price,
            quantity: productDetails.quantity
          }          
          if (productDetails.selectedVariants != 0) {
            this.take1ItemSelected.selectedVariants[1].quantity = 1
            productDetails.apc_card = {
              buy1take1: true,
              product_take1: {
                is_meal: false,
                preparation_time: "01:00:00",
                route_key: this.take1ItemSelected.segment,
                title: this.take1ItemSelected.title,
                variants: this.take1ItemSelected.selectedVariants
              }
            }                  
          }  
          productDetails.apc_card_product = true           
          this.favSetProd.push(productDetails)
          productDetails.price = this.getTotalPrice(productDetails) 
        }

        if(!this.state.isAPCSet) {  
          productDetails.originalPrice = {
            price: productDetails.price,
            quantity: productDetails.quantity
          } 
          this.favSetProd.push(productDetails) 
          this.favSetProd.map((item, index) => {
            console.log(item, index, this.favSetProd);
            //item.apc_card = this.favSetProd[0].apc_card
            if(!item.apc_card || !item.apc_card.product_take1) { 
              this.isAPCSet = false;
              if (item.apc_card_double_deal){
                this.take1ItemSelected.selectedVariants[1].quantity = 1
                productDetails.apc_card = {
                  buy1take1: true,
                  product_take1: {
                    is_meal: false,
                    preparation_time: "01:00:00",
                    route_key: this.take1ItemSelected.segment,
                    title: this.take1ItemSelected.title,
                    variants: this.take1ItemSelected.selectedVariants
                  }
                } 
              }                                             
              item.price = this.getTotalPrice(item)                          
            }
          })        
        }
        
      const modalComponent = this.modalService.open(FavoriteItemComponent, {
        ariaLabelledBy: 'modal-basic-title',
        windowClass: 'modal-cart',
        backdropClass: 'menuitem-backdrop',
        backdrop: 'static',
        centered: true,
      });
      modalComponent.componentInstance.index = this.state;
      modalComponent.componentInstance.menuSet = this.favSetProd;
      modalComponent.componentInstance.menuProduct = productDetails;
      modalComponent.componentInstance.addItemState = addItemState;
      modalComponent.result.then(function (formData) {
        //use formData
        // this.getFavoriteSet();
        console.log("deleteSet 1",formData, this.state);
        
      });
        
      } catch (err) {
        console.log(err);
        
      }                        
    }
    
  }

  isAllVariantsSelected(selectedVariants) {
    // returns missing variant types[] ()
    const variantTypes = Object.keys(this.productDetails.variants);
    if (!selectedVariants.length) {
      return variantTypes;
    }
    const missingVariants = [];
    variantTypes.map((type: any) => {
      const filteredSelectedType = selectedVariants.filter(
        
        (selectedVariant) => {
          console.log(selectedVariant.type, type)
          return selectedVariant.type.toLowerCase().replaceAll(' ', '-') === type.toLowerCase().replaceAll(' ', '-')
        }
      );
      if (!filteredSelectedType.length) {
        missingVariants.push(type);
      }
    });
    return missingVariants;
  }

  showSuccessToast(productName) {
    this.toastService.show(`${productName} successfully added to cart.`, {
      classname: "bg-success text-light",
    });
  }

  showDangerToast(errorMessage) {
    this.toastService.show(errorMessage, { classname: "bg-danger text-light" });
  }

  setSubCategory(subCat) {
    this.productDetails.selected_sub_category = subCat;
  }

  // add or remove add-on (selected data are stored in `selectedAdditionalProducts`)
  setAdditionalProduct(addon) {
    let addonIndex = null;
    const hasAddOn = this.productDetails.selectedAdditionalProducts.filter(
      (selectedAddon, i) => {
        if (
          selectedAddon.id === addon.id &&
          selectedAddon.type === addon.type
        ) {
          addonIndex = i;
          return selectedAddon;
        }
      }
    );
    if (!hasAddOn.length) {
      addon.selected = true;
      this.productDetails.selectedAdditionalProducts.push(addon);
      // console.log('add', this.productDetails.selectedAdditionalProducts);
      return;
    }
    addon.selected = false;
    this.productDetails.selectedAdditionalProducts.splice(addonIndex, 1);
    // console.log('remove', this.productDetails.selectedAdditionalProducts);
  }

  // add or remove variations (selected data are stored in `selectedVariations`)
  setVariant(variation, isRadio?) {
    console.log("variation => ", variation, isRadio, this.isCartEdit);

    // for radio button that allows one selection only
    if (isRadio) {
      let variationIdRadio = null;
      const hasSameType = this.productDetails.selectedVariants.filter(
        (selectedVariats, i) => {
          console.log('checking: ',selectedVariats, variation.variantType.id)
                
          if (selectedVariats.type === variation.variantType.id) {
          // if (selectedVariats.type.toLowerCase().replaceAll(' ', '-') === variation.type.toLowerCase().replaceAll(' ', '-')) {
            variationIdRadio = selectedVariats.id;
            console.log('selected variants => ', selectedVariats)
            return selectedVariats;
          }
        }
      );
      if (hasSameType.length) {
        let keyTypes = this.productDetails.variants;
        console.log('has same type => ', hasSameType)
        for (let i = 0; hasSameType.length > i; i++) {
          console.log(hasSameType[i].type.charAt(0).toUpperCase() + hasSameType[i].type.slice(1));
          for (let j = 0; this.productDetails.variants[hasSameType[i].type].length > j; j++) {
            let variants = this.productDetails.variants[hasSameType[i].type][j].variants;
            this.productDetails.variants[hasSameType[i].type][j].selected = false;
            console.log('variants => ', variants)
            if (variants && variants.length) {
              console.log('has variants...')
              variants.map(v => {
                console.log('segment...', hasSameType[i].segment.toLowerCase().replaceAll(' ', '-'), v.segment.toLowerCase().replaceAll(' ', '-'))
                if (hasSameType[i].segment.toLowerCase().replaceAll(' ', '-') === v.segment.toLowerCase().replaceAll(' ', '-')) {
                  console.log('same type')
                  // return {...v, selected: false};
                  v.selected = false;
                }
                return v;
              })
            }
            this.productDetails.variants[hasSameType[i].type][j].variants = variants;
          }
        }
        console.log('variation index radio => ', variationIdRadio)
        this.productDetails.selectedVariants = this.productDetails.selectedVariants.filter(v => variationIdRadio !== v.id);
        console.log(this.productDetails)
      }
      variation.selected = true;
      variation.quantity = 1;
      this.productDetails.selectedVariants.push(variation);
      // console.log(this.productDetails.selectedVariants);
      // console.log(variation);
      return;
    }

    let variationIndex = null;
    const hasVariation = this.productDetails.selectedVariants.filter(
      (selectedVariants, i) => {
        if (
          selectedVariants.segment === variation.segment &&
          selectedVariants.type === variation.type
        ) {
          variationIndex = i;
          return selectedVariants;
        }
      }
    );

    if (hasVariation.length) {
      variation.selected = false;
      this.productDetails.selectedVariants.splice(variationIndex, 1);
      // console.log('remove', this.productDetails.selectedVariants);
    }

    if (!hasVariation.length) {
      variation.selected = true;
      variation.quantity = 1;
      this.productDetails.selectedVariants.push(variation);
      // console.log('add', this.productDetails.selectedVariants);
    }

    // variant limit checker
    const typeHasLimit = this.variantLimitArr.filter((variationLimitType) => {
      if (variationLimitType.toLowerCase().replaceAll(' ', '-') === variation.type.toLowerCase().replaceAll(' ', '-')) {
        console.log("has limit");
        return true;
      }
      return false;
    });
    // console.log('type limit', typeHasLimit, this.variantLimitArr, variation.type);
    console.log('has limits => ', typeHasLimit);
    if (typeHasLimit.length) {
      const selectedItemsByTypes = this.itemsWithSameType(variation);
      const typeLimitList = this.productDetails.extra.maximum_variant_selection;

      console.log(selectedItemsByTypes);
      console.log(variation.type.toLowerCase().replaceAll(' ', '-'));
      console.log(
        parseInt(
          typeLimitList[variation.type.toLowerCase().replaceAll(' ', '-')].maximum_selection,
          10
        )
      );
      console.log("variants => ", this.productDetails.disabled_variants);
      // disable variants on limit
      if (
        selectedItemsByTypes.length >=
        parseInt(
          typeLimitList[variation.type.toLowerCase().replaceAll(' ', '-')].maximum_selection,
          10
        )
      ) {
        this.changeDisabledState("variants", variation.type, true);
        this.disabledVariants.push(variation.type);
        this.productDetails.disabled_variants.push(variation.type);
        console.log("disabled...");
      }
      console.log("variants => ", this.productDetails.disabled_variants);

      // enable disabled variants if checked < limit
      const disabledVariant = this.disabledVariants.filter(
        (disabledVariantType) => disabledVariantType === variation.type
      );
      // console.log('disabledVariant type', disabledVariant);
      if (
        selectedItemsByTypes.length <
          parseInt(
            typeLimitList[variation.type.toLowerCase().replaceAll(' ', '-')].maximum_selection,
            10
          ) 
        //   &&
        // disabledVariant.includes(variation.type)
      ) {
        this.changeDisabledState("variants", variation.type, false);
        const variantIndex = this.disabledVariants.indexOf(variation.type);
        const productVariantIndex = this.productDetails.disabled_variants.indexOf(
          variation.type
        );
        this.disabledVariants.splice(variantIndex, 1);
        this.productDetails.disabled_variants.splice(productVariantIndex, 1);
        console.log('enable variants');
      }
    }

    return variation;
  }

  changeDisabledState(type, subType, state) {
    this.productDetails[type][subType].map((productAddon) => {
      if (!productAddon.selected) {
        productAddon.disabled = state;
      }
      if (type === "variants") {
        if (productAddon.variants.length) {
          productAddon.variants.map((subVariant) => {
            if (!subVariant.selected) {
              subVariant.disabled = state;
              console.log("isDisabled", subVariant.disabled);
            }
          });
        }
      }
    });
  }

  itemsWithSameType(item) {
    return this.productDetails.selectedVariants.filter((selectedVariats) => {
      if (selectedVariats.type.toLowerCase().replaceAll(' ', '-') === item.type.toLowerCase().replaceAll(' ', '-')) {
        console.log("equal");
        return selectedVariats;
      }
    });
  }

  showInfoModal(title, message) {
    const modalCtrl = this.modalService.open(InformationDialogComponent, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "postcode-confirmation-modal",
      size: "lg",
      centered: true,
    });
    modalCtrl.componentInstance.message = message;
    modalCtrl.componentInstance.title = title;
  }

  async removeItem(product, index) {
    const modalComponent = this.modalService.open(ConfirmDialogComponent, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "confirm-dialog-modal",
      centered: true,
    });

    modalComponent.componentInstance.title = "Remove Item to Cart";
    modalComponent.componentInstance.confirmationMessage =
      "Are you sure you want to remove this item to cart?";
    modalComponent.result.then(async (res) => {
      if (res) {
        const token = await this.storeService.getItem("token");
        if (!token) {
          this.cartService.removeItemInCart(product, index);
        } else {
          const cartItem = this.cartService.cartStorage[
            this.productIndexFromCart
          ];
          this.loaderService.showAsyncLoader();
          await this.cartService.removeCartItem(cartItem);
          this.loaderService.hideAsyncLoader();
        }
        this.modalCtrl.close();
      }
    });
  }

  async updateItemInCart(product, index) {    
    
    const token = await this.storeService.getItem("token");

    // validations
    if (!this.useVoucherCode) {
      product.voucher_code = "";
    }
    
    if (this.take1ItemSelected) {
      console.log('set take 1...')
      product.take1 = this.take1ItemSelected
      console.log('[add to cart] take 1 item => ', product.take1)
    }

    const branch = await this.storeService.getItem("branch");
    const branchLocation: any = await this.storeService.getItem(
      "branchLocation"
    );
    const deliveryType: any = await this.storeService.getItem("deliveryType");
    if (!branchLocation || !branch) {
      this.showBranchList();
      return;
    }
    if (
      (!branchLocation["city"] && deliveryType === "delivery") ||
      (!branchLocation["selected_branch"] && deliveryType === "pick-up")
    ) {
      this.showBranchList();
      return;
    }

    if (product.quantity > 50) {
      this.toastService.showDangerToast(
        `${product.title} has a maximum quantity of 50`
      );
      product.quantity = 50;
      return;
    }

    if (
      product.quantity < product.minimum_quantity ||
      product.quantity === 0 ||
      !product.quantity
    ) {
      this.toastService.showDangerToast(
        `${product.title} has a minimum quantity of ${product.minimum_quantity}`
      );
      product.quantity = product.minimum_quantity;
      return;
    }

    if (!Array.isArray(product.variants)) {
      // {} formatted variants, [] empty variants

      if (product.variants.length && product.selectedVariants.length <= 0) {
        this.showDangerToast(`Please choose a variant first.`);
        return;
      }

      console.log('product => ', product.selectedVariants);

      const missingSelectedVariants = this.isAllVariantsSelected(
        product.selectedVariants
      );
      console.log(missingSelectedVariants);
      if (missingSelectedVariants.length) {
        missingSelectedVariants.filter((type) => {
          this.showDangerToast(
            `${this.unslugify.transform(type)} is required.`
          );
        });
        return;
      }

      let error = false;
      product.selectedVariants.forEach(v => {
        if (v.quantity < 1) {
          this.showDangerToast(`${v.title} has a minimum quantity of 1`);
          error = true;
        } else if (v.quantity > 50) {
          this.showDangerToast(`${v.title} has a maximum quantity of 50`);
          error = true;
        }
      });
      if (error) return;

    }

    this.loaderService.showAsyncLoader();
    if (!token) {
      this.cartService.updateGuestCart(product, index);
      this.toastService.showSuccessToast(`Cart successfully updated.`);
      this.loaderService.hideAsyncLoader();
      this.closeModal();
      return;
    }
    const additionalProductPayload = product.selectedAdditionalProducts.map(
      (addon) => addon.id
    );
    
    product.selectedVariants = this.removeDuplicates(product.selectedVariants, 'id');
    console.log('testing: ', product);

    const apcCardCode = await this.storeService.getItem("apc")
    
    const variantsPayload = product.selectedVariants.map(
      (variant) => {
        return { id: variant.segment, quantity: variant.quantity };
      }
    );    
    console.log('test: ', variantsPayload);
    

    const cartItem = this.cartService.cartStorage[this.productIndexFromCart];
    const payload = {
      id: cartItem.id,
      quantity: product.quantity,
      dietary_requirements: product.extra.dietary_requirements,
      sub_category: product.selected_sub_category,      
      variants: [...variantsPayload],   
      additional_products: additionalProductPayload,
    };
    
    let payload_1 = {}

    if (apcCardCode) {
      const variantsPayloadApc = product.take1.selectedVariants.map(
        (variant) => {
          return { id: variant.segment, quantity: 1}
        }
      )

      payload_1 = {
        id: cartItem.id,
        quantity: product.quantity,
        dietary_requirements: product.extra.dietary_requirements,
        sub_category: product.selected_sub_category,    
        variants: [...variantsPayload],
        take1: product.take1.id,
        take1_variants: [...variantsPayloadApc],
        additional_products: additionalProductPayload,
      }
    }  

    
      
    try {
      if(!product.take1) {
        await this.cartService.updateCartItem(payload);                
        this.toastService.showSuccessToast(`Cart successfully updated.`);
        this.closeModal();
      } else {
        await this.cartService.updateCartItem(payload_1);
        this.toastService.showSuccessToast(`Cart successfully updated.`);
        this.closeModal();        
      }

      console.log(this.cartService.cartStorage, product);
      let prodStore = await this.storeService.getItem('prodStore')
      this.storeProd = prodStore ? prodStore : []

      prodStore[index].deep_copy = product

      await this.storeService.setItem('prodStore', this.storeProd)  
      
      console.log(this.storeProd);

    } catch (err) {
      this.toastService.showDangerToast("Failed to update cart");
      console.log(err);
    } finally {
      this.loaderService.hideAsyncLoader();
    }
  }

  async resetBranchLocation() {
    await this.storeService.removeItem("branch");
    await this.storeService.removeItem("branchLocation");
    await this.storeService.removeItem("deliveryType");
    await this.cartService.emptyCart(true);
    this.showBranchList();
  }

  inputChanged(value) {
    console.log(this.productDetails, value)
    this.productDetails.selectedVariants = this.productDetails.selectedVariants.map(v => {
      if (v.segment === value.segment) {
        return value;
      }
      return v;
    });
  }

  async getTake1Items(isEnabled, isRefresh?) {

    if (!isEnabled) return

    try {

      this.isTake1Loading = true;

      let headers = {};

      const branch = await this.storeService.getItem("branch");
      headers = {
        "x-branch": branch || "all",
      };

      const selectedVariant = this.getSelectedVariant(this.defaultVariantType)
      console.log('selectedVariant =>', selectedVariant);

      let variantId = this.getSelectedVariant(this.cartService.buytake1Details.price_variant_type).id.replace(this.productDetails.id,'');
      console.log('temp id =>', variantId);
      variantId = variantId.split('-').filter(s => !!s).join('-');

      let res: any = await this.apiService.request({
        url: `/v2/products/take1?product=${this.productDetails.id}&variant=${variantId}&limit=0`,
        method: 'GET',
        headers,
        jsona: true
      });
      this.take1Items = res;

      if (this.take1Items && this.take1Items.length) {
        this.take1Items.map(t => {
          t.variants = t.variants.map(v => {
            return {...v, type: this.cleanString(v.type)};
          })
          return {...t, variants: t.variants}
        })
      }

      const apcCardCode = this.userService.apcCardNumber;

      // checks if using apc card
      if (this.take1Items && this.take1Items.length > 0 && ((apcCardCode || this.cartService.hasApcCategory()) || (!(apcCardCode || this.cartService.hasApcCategory()) && this.cartService.isDoubleDealCategory(this.productDetails))) && !isRefresh) {
        this.take1SelectionsCollapse = {};
        this.take1SelectionsCollapse.take1 = true;
        // this.take1ItemSelected = this.take1Items.find(t => t.id === this.productDetails.id) ?  : this.take1Items[0];
        for (let i = 0; this.take1Items.length > i; i++) {
          if (this.productDetails.take1 && this.take1Items[i].id === this.productDetails.take1.id) {
            this.take1ItemSelected = this.productDetails.take1;

            break;
          }
          // if (this.take1Items[i].id === this.productDetails.id) {
          //   this.take1ItemSelected = this.take1Items[i];
          //   break;
          // }
        }

        console.log('selected => ', this.take1ItemSelected)

        this.take1ItemSelected = this.take1ItemSelected || this.take1Items[0];
        this.take1ItemSelected.selectedVariants = this.take1ItemSelected.selectedVariants || []
        this.take1ItemSelected.selectedVariants.push(this.getSelectedVariant(this.defaultVariantType));
        let variantTypes = this.getVariantTypes(this.take1ItemSelected.variants, 'type', this.defaultVariantType)
        variantTypes.forEach((v: string) => {
          const variants = this.getVariants(this.take1ItemSelected.variants, v);
          this.take1SelectionsCollapse[v] = true;
          if (variants && variants.length && variants.length > 0) {
            let selectedVariants = this.take1ItemSelected.selectedVariants || []
            selectedVariants.push(variants[0])

            this.take1ItemSelected.selectedVariants = selectedVariants;
          }
        })
        console.log('take1ItemSelected =>', this.take1ItemSelected)
      }

      this.take1ItemSelected.selectedVariants.splice(2,2)

      console.log(this.take1ItemSelected);      
      console.log('take 1 items => ', res);
      this.isTake1Loading = false;
    } catch (err) {
      console.log(err);
      this.isTake1Loading = false;
    }
  }

  toggleTake1Selections(field, state?) {
    console.log('field => ', field);
    this.take1SelectionsCollapse[field] = state || !this.take1SelectionsCollapse[field];
    console.log(this.productDetails)
  }

  selectTake1Item(value) {
    // this.productDetails.take1 = value;
    this.take1ItemSelected = value;
    console.log('take 1 selected', this.take1ItemSelected);
    
    this.take1ItemSelected.selectedVariants = []
    this.take1ItemSelected.selectedVariants.push(this.getSelectedVariant(this.defaultVariantType));
    let variantTypes = this.getVariantTypes(this.take1ItemSelected.variants, 'type', this.defaultVariantType)
    variantTypes.forEach(v => {
      const variants = this.getVariants(this.take1ItemSelected.variants, v);
      if (variants && variants.length && variants.length > 0) {
        let selectedVariants = this.take1ItemSelected.selectedVariants || []
        selectedVariants.push(variants[0])

        this.take1ItemSelected.selectedVariants = selectedVariants;
      }
    })
    this.toggleTake1Selections('take1', false);
    // console.log('product details => ', this.productDetails)
  }

  // size & crust
  getDefaultVariant(type) {
    let variants = this.productDetails.variants[type];
    console.log(variants);

    if (!variants || variants.length <= 0) {
      return null;
    }

    for (let i = 0; variants.length > i; i++) {
      if (variants[i].selected === true) {
        return null;
      }
    }

    function compare(a, b) {
      if (parseFloat(a.price.amount) < parseFloat(b.price.amount)) {
        return -1;
      }
      if (parseFloat(a.price.amount) > parseFloat(b.price.amount)) {
        return 1;
      }
      return 0;
    }

    return variants.sort(compare)[0] || null;
  }

  getDefaultVariantTake1(product, type) {
    if (!product || !product.variants || !product.variants.length) return null

    let variants = product.variants.filter(v => v.type === type);
    //console.log(variants);

    if (!variants || variants.length <= 0) {
      return null;
    }

    for (let i = 0; variants.length > i; i++) {
      if (variants[i].selected === true) {
        return null;
      }
    }

    function compare(a, b) {
      if (parseFloat(a.price.amount) < parseFloat(b.price.amount)) {
        return -1;
      }
      if (parseFloat(a.price.amount) > parseFloat(b.price.amount)) {
        return 1;
      }
      return 0;
    }

    return variants.sort(compare)[0] || null;
  }

  getSelectedVariant(type) {    
    
    if (!this.productDetails.variants[type] || this.productDetails.variants[type].length <= 0) {
      return null;
    }
    let selectedVariants = this.productDetails.variants[type].filter(v => v.selected);
    return selectedVariants[0] || null;
  }

  // getVariantPrice(type, )
  removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject  = {};

    for(var i in originalArray) {
       lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for(i in lookupObject) {
        newArray.push(lookupObject[i]);
    }
     return newArray;
  }

  getVariantTypes(arr, field, exclude?) {
    if (!arr || !arr.length || arr.length <= 0 || !field) return []
    
    let variantTypes = Array.from(new Set(arr.map(a => a[field]) || []))
    
    if (!exclude) return variantTypes

    return variantTypes.filter((v:string) => v.toLowerCase() !== exclude.toLowerCase());
  }

  getVariants(arr, type) {
    if (!arr || !arr.length || arr.length <= 0 || !type) return []
    return arr.filter(v => v.type == type)
  }

  selectVariant(item, selectedVariant) {
    let selectedVariants = item.selectedVariants || [];
    console.log('selectedVariants =>', selectedVariants)
    if (selectedVariants.find(s => s.type === selectedVariant.type)) {
      selectedVariants = selectedVariants.map(s => s.type === selectedVariant.type ? selectedVariant : s)
    } else {
      selectedVariants.push(selectedVariant)
    }
    console.log('selected variants =>', selectedVariants)
    this.take1ItemSelected.selectedVariants = selectedVariants

    this.toggleTake1Selections(selectedVariant.type, false);
  }

  isOnArray(arr, field, value) {
    //console.log(arr)
    if (!arr || !arr.length || arr.length <= 0 || !field || !value) return null;

    arr = arr.filter(a => !!a)

    const obj = arr.find(a => a[field] === value);
    // console.log('obj =>', obj, field, value)
    return obj;
  }

  getTotalPrice(product) { 
    console.log(product);     
    let variants = product.selectedVariants    
    if(!variants.length){

      if (product.originalPrice) {
        product.originalPrice.quantity = product.quantity        
        let amount = (product.originalPrice.price.amount * product.originalPrice.quantity)
        let format = (Math.round(amount)/100).toFixed(2)
        console.log(amount,format);
        return {amount: `${amount}`, currency: "PHP", formatted: `₱${format.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}  
      }
      
      //return product.price
    }  
    
    if(variants) {
      let amount = (variants[1].price.amount * variants[1].quantity)
      let format = (Math.round(amount)/100).toFixed(2) 
      return {amount: `${amount}`, currency: "PHP", formatted: `₱${format.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
    } 
        
  }

  cleanKeys(object) {

    if (!object) return object;

    // function changeKeys(item, index) {
      return Object.keys(object).reduce((result, key) => {
        const cleanKey = key.replace(/[^A-Z0-9]+/ig, "-").toLowerCase()
        result[cleanKey] = object[key];
        return result;
      }, {});
    
    // }

    // const result = objects.map(changeKeys);
    // console.log('[cleanKeys] result =>', result);
    // return result;
  }

  cleanString(str) {
    if (!str ||  typeof str != 'string') return ''
    return str.replace(/[^A-Z0-9]+/ig, "-").toLowerCase()
  }

}
