import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { AppService } from './../../services/app/app.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuItemComponent } from './../menu-item/menu-item.component';
import { RouteHelperService } from './../../services/route-helper.service';
import { Router } from '@angular/router';
import { ToastService } from './../../services/toast/toast.service';
import { StoreService } from './../../services/store/store.service';
import { CartService } from './../../services/cart/cart.service';
import { Component, OnInit, Input, Output, EventEmitter, Inject, PLATFORM_ID } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog/confirm-dialog.component';
import { LoaderService } from 'src/app/services/loader.service';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-cart-content',
  templateUrl: './cart-content.component.html',
  styleUrls: ['./cart-content.component.scss']
})
export class CartContentComponent implements OnInit {
  activeRoute = '';

  state = false;

  baranggay = '';

  deliveryType = 'delivery';

  minimumOrder = 0;

  modalCtrl: any;

  egcEnabled;

  @Input()
  set cartState(value) {
    this.state = value;
  }

  @Input() location;
  productIndexFromCart = null;

  @Output() close = new EventEmitter<any>();

  @Output() emptyCart = new EventEmitter<any>();
  constructor(
      public cartService: CartService,
      private storeService: StoreService,
      private toastService: ToastService,
      private router: Router,
      private routeService: RouteHelperService,
      private modalService: NgbModal,
      public appService: AppService,
      private apiService: ApiService,
      private loaderService: LoaderService,
      public userService: UserService,
      @Inject(PLATFORM_ID) private platformId
    ) {
      this.egcEnabled = GlobalService.FEATURES.egc;
    }

  ngOnInit() {
    this.routeService.routeSubject.subscribe((route) => {
      this.activeRoute = route.snapshot._routerState.url;
    });

    if (isPlatformBrowser(this.platformId)) {
      this.setBaranggay();

      this.cartService.onDeliveryTypeChange().subscribe(deliveryType => {
        if (deliveryType) {
          this.deliveryType = deliveryType;

          let minimumOrder = this.appService.siteSettings.minimum_order_amount_delivery;
          if (this.deliveryType === 'pick-up') {
            minimumOrder = this.appService.siteSettings.minimum_order_amount_pick_up;
          }

          this.cartService.changeMinimumOrder(minimumOrder)
        }
      });
      this.cartService.onMinimumOrderChange().subscribe(minimumOrder => {
        this.minimumOrder = minimumOrder;
      })
    }         
    console.log("Checkout ", this.cartService);        

  }

  async setBaranggay() {
    const branchLocation: any = await this.storeService.getItem('branchLocation');
    if (branchLocation) {
      if (!branchLocation.branch) {
        return;
      }
      this.baranggay = branchLocation.branch.title;
    }
    this.cartService.getCartItems()
  }

  // close() {
  //   this.state = false;
  //   this.closeCart.emit(this.state);
  // }

  async gotoCheckout() {
    const user: any = await this.storeService.getItem('user');
    if (user) {
      if (!user.has_verified_email) {
        this.toastService.showDangerToast('Please verify your email first to proceed.');
        return;
      }
    }

    if (this.cartService.cartStorage['length']) {
      // this.close();
      this.close.emit();
      this.router.navigate(['/checkout']);
    } else {
      this.toastService.showDangerToast('Your cart is empty, please add products to checkout');
    }
    this.modalService.dismissAll();
  }

  async editProduct(product, index) {
    const token = await this.storeService.getItem('token');
    console.log("edit product: ",product);      

    const modalComponent = this.modalService.open(MenuItemComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'modal-cart',
      backdropClass: 'menuitem-backdrop'
    });
        
    if (token) {      
      
      //product = product.product
      let prodStore = await this.storeService.getItem("prodStore") 
      let product_user = prodStore[index]         
      const productDetails = product_user.deep_copy; // deep copy    ;
      console.log(productDetails, prodStore);
      
      modalComponent.componentInstance.productDetails = productDetails;
    }
    
    if (!token) {
      const productDetails = JSON.parse(JSON.stringify(product)); // deep copy    ;
      console.log('product details deep copy => ', productDetails);
    
      modalComponent.componentInstance.productDetails = productDetails;
    }

    modalComponent.componentInstance.modalCtrl = modalComponent;
    modalComponent.componentInstance.isCartEdit = true;
    modalComponent.componentInstance.productIndexFromCart = index;
    // dismiss cart, add condition when needed
    // this.close();
  }

  async removeEgc(egc, index) {
    const modalComponent = this.modalService.open(ConfirmDialogComponent, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "confirm-dialog-modal",
      centered: true,
    });

    modalComponent.componentInstance.title = "Remove Item to Cart";
    modalComponent.componentInstance.confirmationMessage =
      "Are you sure you want to remove this EGC to cart?";
    modalComponent.result.then(async (res) => {
      if (res) {

        const token = await this.storeService.getItem("token");
        if (!token) {
          this.cartService.removeItemInCart(egc, index);
          return;
        }
        const headers = { Authorization: `Bearer ${token}` };
        console.log('egc => ', egc)
        const removeEgcResponse = await this.apiService.request({
          method: 'DELETE',
          url: `/auth/e-gift-certificate-cart/${egc.id}`,
          headers,
        });

        await this.cartService.getCartItems();

        // const token = await this.storeService.getItem("token");
        // if (!token) {
        //   this.cartService.removeItemInCart(egc, index);
        // } else {
        //   // const cartItem = this.cartService.cartStorage[
        //   //   this.productIndexFromCart
        //   // ];
        //   this.loaderService.showAsyncLoader();
        //   await this.cartService.removeCartItem(egc);
        //   this.loaderService.hideAsyncLoader();
        // }
        // // this.modalCtrl.close();
      }
    });
  }

  clearCart() {
    this.emptyCart.emit();   
    
  }

  getEgcTotalAmount(egcProduct) {
    // console.log('egc product => ', egcProduct);
    if (!egcProduct || !egcProduct.quantity || !egcProduct.price || !egcProduct.price.amount) {
      return '';
    }
    // console.log('amount => ', (parseInt(egcProduct.price.amount) / 100))
    return `₱${((parseFloat(egcProduct.price.amount) / 100) * egcProduct.quantity).toFixed(2)}`
  }

  edit(product, index) {
    product.type === 'e-gift-certificate-cart'? 
    this.removeEgc(product, index) 
    : this.editProduct(product, index)
  }


  async removeItem(product, index) {
    console.log("trial: ",product);    
    const modalComponent = this.modalService.open(ConfirmDialogComponent, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "confirm-dialog-modal",
      centered: true,
    });

    modalComponent.componentInstance.title = "Remove Item to Cart";
    modalComponent.componentInstance.confirmationMessage =
      "Are you sure you want to remove this item to cart?";
    modalComponent.result.then(async (res) => {
      if (res) {
        const token = await this.storeService.getItem("token");
        if (!token) {
          this.loaderService.showAsyncLoader();
          await this.cartService.removeItemInCart(product, index);
          this.loaderService.hideAsyncLoader(); 
        } else {
          const cartItem = this.cartService.cartStorage[
            index
          ];
          console.log('cart item => ', cartItem)
          this.loaderService.showAsyncLoader();
          await this.cartService.removeCartItem(cartItem);
          let removeProdStore: any = await this.storeService.getItem('prodStore')          
          removeProdStore.splice(index,1)
          await this.storeService.setItem('prodStore', removeProdStore)  

          console.log(removeProdStore);
          
          this.loaderService.hideAsyncLoader();          
        }
        await this.cartService.setTake1Items((this.cartService.hasApcCategory() || await this.storeService.getItem('apc')))
        // modalComponent.close()        
        
      }
    });
  }
  

  show(item) {
    console.log('item => ', item);
    return item;
  }  

  getSelectedVariant(item, type) {
    let selectedVariants = item.selectedVariants.filter(v => v.type === type);
    return selectedVariants[0] || null;
  }

  isBuy1Take1 = () => this.cartService.hasApcCategory() || this.userService.apcCardNumber;
  

}
