import { ApiService } from './../api/api.service';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  siteName = '';
  siteSettings: any;
  siteUrl;
  constructor(
    public http: HttpClient,
    private meta: Meta,
    private title: Title,
    private apiService: ApiService,
    @Inject(PLATFORM_ID) private platformId, @Inject(DOCUMENT) private document: Document
  ) {
    this.siteUrl = this.document.location.origin;
  }

  clearHead() {
    // remove all META-Informaiton
    this.removeDescription();
    this.removeKeywords();
  }

  updateOgTags(metaConfig, isPageMeta?) {
    if (!metaConfig) { return; }
    if (metaConfig.title) {
      let title = metaConfig.title;
      if (isPageMeta && this.siteName) {
        title = `${title} - ${this.siteName}`;
      }
      this.meta.updateTag({ property: 'og:title', content: title });
      this.meta.updateTag({ name: 'twitter:title', content: title });
      this.title.setTitle(title);
    }
    if (metaConfig.description) {
      this.meta.updateTag({ name: 'description', content: metaConfig.description });
      this.meta.updateTag({ property: 'og:description', content: metaConfig.description });
      this.meta.updateTag({ name: 'twitter:description', content: metaConfig.description });
    }
    if (metaConfig.image) {
      this.meta.updateTag({ property: 'og:image', content: metaConfig.image });
      this.meta.updateTag({ name: 'twitter:image', content: metaConfig.image });
    }
    if (metaConfig.keywords) {
       this.meta.updateTag({ name: 'keywords', content: metaConfig.keywords });
    }
    if (metaConfig.author) {
      this.meta.updateTag({ name: 'author', content: metaConfig.author });
    }
  }

  updateDescription(description) {
    this.meta.updateTag({ property: 'og:description', content: description });
    this.meta.updateTag({ name: 'twitter:description', content: description });
    this.meta.updateTag({ name: 'description', content: description });
  }

  updateImage(img) {
    this.meta.updateTag({ property: 'og:image', content: img });
    this.meta.updateTag({ name: 'twitter:image', content: img });
  }

  removeDescription() {
    // Try to remove META-Tags
    try {
      document.querySelector('meta[name="description"]').remove();
    } catch (e) {

    }
  }

  removeKeywords() {
    // Try to remove META-Tags
    try {
      document.querySelector('meta[name="keywords"]').remove();
    } catch (e) {

    }
  }

  addDescription(content) {
    // Add the new META-Tags
    const description = document.createElement('meta');
    description.name = 'description';
    description.content = content;
    document.getElementsByTagName('head')[0].appendChild(description);
  }

  addKeywords(content) {
    const keywords = document.createElement('meta');
    keywords.name = 'keywords';
    keywords.content = content;
    document.getElementsByTagName('head')[0].appendChild(keywords);
  }

  addFavicon(faviconUrl: string) {
    document.querySelector('link[rel*="icon"]').remove();
    const favicon = document.createElement('link');
    const fileExtension = faviconUrl.split('.').pop();
    favicon.type = `image/${fileExtension || 'jpg'}`;
    favicon.rel = 'icon';
    favicon.href = faviconUrl;
    document.getElementsByTagName('head')[0].appendChild(favicon);
  }

  async getAppSettings() {
    try {
      const res = await this.apiService.request({
        url: '/value-store/settings',
        method: 'GET',
      });
      this.siteSettings = res;
      console.log('site settings =>', this.siteSettings);
      const metaConfig = {
        title: this.siteSettings.meta_tag_title,
        description: this.siteSettings.meta_tag_description,
        author: this.siteSettings.meta_tag_author,
        image: this.siteSettings.meta_tag_logo
      };
      this.siteName = this.siteSettings.site_title || '';
      this.updateOgTags(metaConfig);
      if (isPlatformBrowser(this.platformId)) {
        this.addFavicon(this.siteSettings.site_favicon);
      }
    } catch (err) {
      console.log(err);
    }
  }
}
