<div class="modal-header">
    <div>
        <p>{{product?.title || product?.product?.title}}</p>
    </div>
    <a class="close" aria-label="Close" (click)="closeModal(false)">
        <span class="acc-close custom-icon-delete"></span>
    </a>
</div>
<div class="modal-body">
    <div class="if__comment">
        <div class="form-group">
          <textarea 
            [(ngModel)]="product"
            class="form-control" rows="3" style="resize: none;"
            placeholder="Do you have specific needs? Enter them here..."></textarea>
        </div>
    </div>
    <div>
        <button class="btn btn-primary" (click)="closeModal(true)">Save</button>
    </div>
</div>