import { ApcCardInputModalComponent } from "../components/apc-card-input-modal/apc-card-input-modal.component";
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "./user/user.service";
export class ModalService {
    constructor(modalService, userService) {
        this.modalService = modalService;
        this.userService = userService;
    }
    openApcCardInputModal(withQuestions = false) {
        if (this.userService.apcCardNumber) {
            window.location.reload();
            return;
        }
        const modalRef = this.modalService.open(ApcCardInputModalComponent, { centered: true });
        modalRef.componentInstance.withQuestions = withQuestions;
        modalRef.componentInstance.isShowCardInput = !withQuestions;
        modalRef.result.then((value) => console.log('value => ', value));
    }
}
ModalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ModalService_Factory() { return new ModalService(i0.ɵɵinject(i1.NgbModal), i0.ɵɵinject(i2.UserService)); }, token: ModalService, providedIn: "root" });
