import * as tslib_1 from "tslib";
import { isPlatformBrowser } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/platform-browser";
import * as i3 from "../api/api.service";
import * as i4 from "@angular/common";
export class AppService {
    constructor(http, meta, title, apiService, platformId, document) {
        this.http = http;
        this.meta = meta;
        this.title = title;
        this.apiService = apiService;
        this.platformId = platformId;
        this.document = document;
        this.siteName = '';
        this.siteUrl = this.document.location.origin;
    }
    clearHead() {
        // remove all META-Informaiton
        this.removeDescription();
        this.removeKeywords();
    }
    updateOgTags(metaConfig, isPageMeta) {
        if (!metaConfig) {
            return;
        }
        if (metaConfig.title) {
            let title = metaConfig.title;
            if (isPageMeta && this.siteName) {
                title = `${title} - ${this.siteName}`;
            }
            this.meta.updateTag({ property: 'og:title', content: title });
            this.meta.updateTag({ name: 'twitter:title', content: title });
            this.title.setTitle(title);
        }
        if (metaConfig.description) {
            this.meta.updateTag({ name: 'description', content: metaConfig.description });
            this.meta.updateTag({ property: 'og:description', content: metaConfig.description });
            this.meta.updateTag({ name: 'twitter:description', content: metaConfig.description });
        }
        if (metaConfig.image) {
            this.meta.updateTag({ property: 'og:image', content: metaConfig.image });
            this.meta.updateTag({ name: 'twitter:image', content: metaConfig.image });
        }
        if (metaConfig.keywords) {
            this.meta.updateTag({ name: 'keywords', content: metaConfig.keywords });
        }
        if (metaConfig.author) {
            this.meta.updateTag({ name: 'author', content: metaConfig.author });
        }
    }
    updateDescription(description) {
        this.meta.updateTag({ property: 'og:description', content: description });
        this.meta.updateTag({ name: 'twitter:description', content: description });
        this.meta.updateTag({ name: 'description', content: description });
    }
    updateImage(img) {
        this.meta.updateTag({ property: 'og:image', content: img });
        this.meta.updateTag({ name: 'twitter:image', content: img });
    }
    removeDescription() {
        // Try to remove META-Tags
        try {
            document.querySelector('meta[name="description"]').remove();
        }
        catch (e) {
        }
    }
    removeKeywords() {
        // Try to remove META-Tags
        try {
            document.querySelector('meta[name="keywords"]').remove();
        }
        catch (e) {
        }
    }
    addDescription(content) {
        // Add the new META-Tags
        const description = document.createElement('meta');
        description.name = 'description';
        description.content = content;
        document.getElementsByTagName('head')[0].appendChild(description);
    }
    addKeywords(content) {
        const keywords = document.createElement('meta');
        keywords.name = 'keywords';
        keywords.content = content;
        document.getElementsByTagName('head')[0].appendChild(keywords);
    }
    addFavicon(faviconUrl) {
        document.querySelector('link[rel*="icon"]').remove();
        const favicon = document.createElement('link');
        const fileExtension = faviconUrl.split('.').pop();
        favicon.type = `image/${fileExtension || 'jpg'}`;
        favicon.rel = 'icon';
        favicon.href = faviconUrl;
        document.getElementsByTagName('head')[0].appendChild(favicon);
    }
    getAppSettings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.apiService.request({
                    url: '/value-store/settings',
                    method: 'GET',
                });
                this.siteSettings = res;
                console.log('site settings =>', this.siteSettings);
                const metaConfig = {
                    title: this.siteSettings.meta_tag_title,
                    description: this.siteSettings.meta_tag_description,
                    author: this.siteSettings.meta_tag_author,
                    image: this.siteSettings.meta_tag_logo
                };
                this.siteName = this.siteSettings.site_title || '';
                this.updateOgTags(metaConfig);
                if (isPlatformBrowser(this.platformId)) {
                    this.addFavicon(this.siteSettings.site_favicon);
                }
            }
            catch (err) {
                console.log(err);
            }
        });
    }
}
AppService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppService_Factory() { return new AppService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Meta), i0.ɵɵinject(i2.Title), i0.ɵɵinject(i3.ApiService), i0.ɵɵinject(i0.PLATFORM_ID), i0.ɵɵinject(i4.DOCUMENT)); }, token: AppService, providedIn: "root" });
