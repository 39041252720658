import { CartService } from 'src/app/services/cart/cart.service';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from './../../services/toast/toast.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { StoreService } from 'src/app/services/store/store.service';

@Component({
  selector: 'app-promo-code-modal',
  templateUrl: './promo-code-modal.component.html',
  styleUrls: ['./promo-code-modal.component.scss']
})
export class PromoCodeModalComponent implements OnInit {

  cartItemAddedSuccessText = '';
  promoCode = '';
  isPromoCodeValid = true;
  promoCodeErrMessage = '';

  modalCtrl: any;
  searchPromoCodeFn: any;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeModal();
  }
  constructor(
    private storeService: StoreService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private loaderService: LoaderService,
    private cartService: CartService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  closeModal() {
    this.modalCtrl.close();
  }

  async searchProductByPromo(voucherCode) {
    try {
      const isModal = true;
      this.loaderService.showAsyncLoader();

      const token = await this.storeService.getItem('token');
      if (!token) {
        const isDuplicateVoucherCode = await this.cartService.checkVoucherValidator(voucherCode);
        if (isDuplicateVoucherCode) {
          this.isPromoCodeValid = false;
          this.promoCodeErrMessage = 'This promo code is currently applied';
          return;
        }
      }

      const noProductFound = await this.searchPromoCodeFn(voucherCode, isModal);
      if (noProductFound) {
        this.isPromoCodeValid = false;
        this.promoCodeErrMessage = 'Incorrect Promo Code.';
      }
      if (!noProductFound) {
        this.modalCtrl.dismiss();
      }
    } catch (err) {
      if (err.status === 404 || err.status === 422) {
        this.isPromoCodeValid = false;
      }

      const hasMessageKey = Object.keys(err.error).includes('message');
      if (hasMessageKey) {
        this.promoCodeErrMessage = err.error.message;
        return;
      }
      const errorFields = Object.keys(err.error);
      errorFields.filter(key => {
        if (key.includes('code')) {
          this.promoCodeErrMessage = err.error[key][0];
        }
      });
    } finally {
      this.loaderService.hideAsyncLoader();
    }
  }

  async gotoCheckout() {
    const user: any = await this.storeService.getItem('user');
    if (user) {
      if (!user.has_verified_email) {
        this.toastService.showDangerToast('Please verify your email first to proceed.');
        return;
      }
    }
    this.router.navigate(['/checkout']);
    this.modalService.dismissAll();
  }

  gotoMenu() {
    this.router.navigate(['/menu/1']);
    this.modalCtrl.dismiss();
  }

}
