/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./information-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./information-dialog.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
var styles_InformationDialogComponent = [i0.styles];
var RenderType_InformationDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InformationDialogComponent, data: {} });
export { RenderType_InformationDialogComponent as RenderType_InformationDialogComponent };
export function View_InformationDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "information-dialog-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["md-dialog-title", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["md-dialog-content", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "action-buttons"], ["md-dialog-actions", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "btn btn-dark"], ["md-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalRef.close(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Ok"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.message; _ck(_v, 4, 0, currVal_1); }); }
export function View_InformationDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-information-dialog", [], null, [["document", "keydown.escape"]], function (_v, en, $event) { var ad = true; if (("document:keydown.escape" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onKeydownHandler($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_InformationDialogComponent_0, RenderType_InformationDialogComponent)), i1.ɵdid(1, 114688, null, 0, i2.InformationDialogComponent, [i3.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InformationDialogComponentNgFactory = i1.ɵccf("app-information-dialog", i2.InformationDialogComponent, View_InformationDialogComponent_Host_0, {}, {}, []);
export { InformationDialogComponentNgFactory as InformationDialogComponentNgFactory };
