import * as tslib_1 from "tslib";
import { isPlatformBrowser } from '@angular/common';
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
export class FooterComponent {
    constructor(apiService, branchLocationService, router, toastService, platformId) {
        this.apiService = apiService;
        this.branchLocationService = branchLocationService;
        this.router = router;
        this.toastService = toastService;
        this.platformId = platformId;
        this.location = {
            title: '',
            segment: ''
        };
        this.currentRoute = '/';
        router.events.subscribe((val) => {
            // see also
            // console.log(val instanceof NavigationEnd);
            this.currentRoute = router.url;
            // console.log('current route => ', this.currentRoute);
            // console.log('router => ', router.url)
        });
        this.year = new Date().getFullYear();
        this.email = new FormControl('', [Validators.required, Validators.email]);
    }
    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.setDefaultLocation();
        }
        this.getSocialLinks();
    }
    getProvinces() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const branchProvinces = yield this.apiService.request({
                    method: 'GET',
                    url: '/province-branches-for-location-page',
                    jsona: true
                });
                const filteredBranchProvinces = branchProvinces.filter(branchProvince => branchProvince.branches.length);
                console.log(filteredBranchProvinces);
                return filteredBranchProvinces;
                // this.appendToMenu('Stores', filteredBranchProvinces);
            }
            catch (err) {
                console.log(err);
            }
        });
    }
    // appendToMenu(title, arr) {
    //   this.menus.map(menu => {
    //     if (menu.title === title) {
    //       menu['children'] = [...arr]
    //     }
    //   });
    // }
    setDefaultLocation() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let provinces = yield this.getProvinces();
            console.log('provinces =>', provinces);
            // if (!provinces) {
            //   await this.branchLocationService.getProvinces();
            //   provinces = this.branchLocationService.provinces;
            // }
            if (provinces && provinces.length && provinces.length > 0) {
                this.location.title = provinces[0].title;
                this.location.segment = provinces[0].segment;
            }
        });
    }
    subscribe() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.email.invalid) {
                    this.toastService.showDangerToast('Invalid email');
                    return;
                }
                const { message } = yield this.apiService.postRequest({ url: '/newsletter/subscribe', body: { email: this.email.value } });
                console.log('message =>', message);
                if (message) {
                    this.toastService.showSuccessToast(message);
                    return;
                }
                this.toastService.showSuccessToast('You are now subscribed to our news letter!');
            }
            catch (err) {
                this.apiService.showErrors(err);
            }
        });
    }
    getSocialLinks() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const socialLinks = yield this.apiService.request({
                method: 'GET',
                url: '/value-store/social',
            });
            console.log('social links => ', socialLinks);
            this.socialLinks = socialLinks;
        });
    }
}
