// Packages
import { NgForageConfig, Driver } from 'ngforage';
import { AuthServiceConfig, FacebookLoginProvider } from 'angularx-social-login';
import { NgcCookieConsentConfig } from 'ngx-cookieconsent';
const ɵ0 = FacebookLoginProvider.PROVIDER_ID;
const config = new AuthServiceConfig([
    {
        id: ɵ0,
        provider: new FacebookLoginProvider('291429762047995')
    }
]);
const cookieConfig = {
    cookie: {
        domain: 'angelspizza.com.ph'
    },
    palette: {
        popup: { "background": '#fff' }, "button": { "background": '#f2cc1d' }
    },
    type: 'info',
    theme: "classic",
    position: 'bottom-left',
    elements: {
        messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}} 
      <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank">{{cookiePolicyLink}}</a>, 
      <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank">{{privacyPolicyLink}}</a>
    </span>
    `,
    },
    content: {
        message: "We use cookies on this site to enchance your user experience. By clicking any link on this page you are giving your consent for us to set cookies",
        dismiss: "Accept & Close",
        // cookiePolicyLink: 'Cookie Policy',
        // cookiePolicyHref: 'https://cookie.com',
        privacyPolicyLink: 'Learn more',
        // privacyPolicyHref: `${window.location.protocol}//${window.location.hostname}:${window.location.port}/privacy-policy`,
        privacyPolicyHref: `https://angelspizza.com.ph/privacy-policy`,
    }
};
export function socialConfig() {
    return config;
}
export class AppModule {
    constructor(ngfConfig) {
        ngfConfig.configure({
            name: 'angels-pizza-store',
            driver: [
                Driver.INDEXED_DB,
                Driver.LOCAL_STORAGE
            ]
        });
    }
}
export { ɵ0 };
