import { AppService } from "./../../services/app/app.service";
import { isPlatformBrowser } from "@angular/common";
import { LoaderService } from "./../../services/loader.service";
import { NavigationEnd, Router } from "@angular/router";
import { ApiService } from "./../../services/api/api.service";
import { CartService } from "./../../services/cart/cart.service";
import {
  Component,
  OnInit,
  PLATFORM_ID,
  Inject,
  Output,
  EventEmitter,
  Input,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  
})
export class HeaderComponent implements OnInit {
  totalValue = 0;
  userData = {
    token: null,
    user: null,
  };

  scrollTop = 0;

  currentRoute = '/';
  
  homeBanners: any;

  swiperConfig = {
    bannerSlider: {
      lazy: true,
      preloadImages: false,
      loadPrevNext: true,
      loop: true,
      slidesPerView: 1,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        hideOnClick: false
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
    },
  };

  showMobileMenu = false;
  
  closeResult = '';

  isExpanded = {};

  @Input() menus;

  @Input("userData")
  set userDetails(value: { token; user }) {
    this.userData = value;
  }
  @Output() toggleCart = new EventEmitter<any>();

  @Output() openMenu = new EventEmitter<any>();

  @Output() logout = new EventEmitter<any>();

  @Output() toggleLocation = new EventEmitter<any>();

  @ViewChild('signupmodal', {static: false}) private signupModal;
  @ViewChild('signinmodal', {static:false}) private signinModal;

  constructor(
    public cartService: CartService,
    public apiService: ApiService,
    public router: Router,
    public loaderService: LoaderService,
    private modalService: NgbModal,
    public appService: AppService,
    private elementRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId
  ) {
    router.events.subscribe((val) => {
      // see also
      // console.log(val instanceof NavigationEnd);
      this.currentRoute = router.url;
      // console.log('current route => ', this.currentRoute);
      // console.log('router => ', router.url)

      if (this.currentRoute == '/') {
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundImage = 'url("../../../assets/imgs/angels/background-home.webp")';
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundPosition = 'top center';
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundSize = 'cover';
      } else {
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundImage = "none";
      }
    });
  }

  ngOnInit() {
    // API Requests (BROWSER)
    if (isPlatformBrowser(this.platformId)) {
      this.cartService.getCartItems();
    }

    this.getHomeSliders();
  }

  async toggleCollapsibleCart() {
    this.toggleCart.emit(true);
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  toggleMenu() {
    this.openMenu.emit(true);
  }

  logoutUser() {
    this.logout.emit(true);
  }

  async getHomeSliders() {
    try {
      const res = await this.apiService.request({
        method: 'GET',
        url: '/sliders/homepage',
        jsona: true
      });
      this.homeBanners = res;
      console.log('home banners => ', this.homeBanners)
    } catch (err) {
      console.log(err);
    }
  }

  toggleMobileMenu(state) {
    this.showMobileMenu = state;
  }

  open(content, size?) {
    let options: any = {centered: true, ariaLabelledBy: 'modal-basic-title'}
    if (size) {
      options.size = size;
    }
    this.modalService.open(content, options).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  close(modal) {
    modal.close()
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openSignInModal() {
    this.modalService.dismissAll();
    this.open(this.signinModal);
  }

  openSignUpModal() {
    this.modalService.dismissAll();
    this.open(this.signupModal, 'lg');
  }
}
