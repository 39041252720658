/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./terms-conditions.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./terms-conditions.component";
import * as i3 from "../../services/loader.service";
import * as i4 from "../../services/api/api.service";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
var styles_TermsConditionsComponent = [i0.styles];
var RenderType_TermsConditionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TermsConditionsComponent, data: {} });
export { RenderType_TermsConditionsComponent as RenderType_TermsConditionsComponent };
export function View_TermsConditionsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "section", [["class", "block-terms page-content py-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "close-btn"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "custom-icon-delete ml-auto border-0"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "container"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "agree-btn"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["I Agree"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.apiService == null) ? null : ((_co.apiService.agreementsContent == null) ? null : _co.apiService.agreementsContent.terms_condition)); _ck(_v, 3, 0, currVal_0); }); }
export function View_TermsConditionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-terms-conditions", [], null, null, null, View_TermsConditionsComponent_0, RenderType_TermsConditionsComponent)), i1.ɵdid(1, 114688, null, 0, i2.TermsConditionsComponent, [i3.LoaderService, i4.ApiService, i5.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TermsConditionsComponentNgFactory = i1.ɵccf("app-terms-conditions", i2.TermsConditionsComponent, View_TermsConditionsComponent_Host_0, {}, {}, []);
export { TermsConditionsComponentNgFactory as TermsConditionsComponentNgFactory };
