/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./location-widget-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../location-widget/location-widget.component.ngfactory";
import * as i3 from "../location-widget/location-widget.component";
import * as i4 from "../../services/api/api.service";
import * as i5 from "../../services/store/store.service";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
import * as i7 from "@angular/forms";
import * as i8 from "../../services/loader.service";
import * as i9 from "../../services/cart/cart.service";
import * as i10 from "../../services/toast/toast.service";
import * as i11 from "../../services/user/user.service";
import * as i12 from "../../services/branch-location/branch-location.service";
import * as i13 from "@angular/router";
import * as i14 from "../../pipes/filter/filter.pipe";
import * as i15 from "../../services/modal.service";
import * as i16 from "./location-widget-modal.component";
var styles_LocationWidgetModalComponent = [i0.styles];
var RenderType_LocationWidgetModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LocationWidgetModalComponent, data: {} });
export { RenderType_LocationWidgetModalComponent as RenderType_LocationWidgetModalComponent };
export function View_LocationWidgetModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "modal__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "custom-icon-delete"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "label", [["class", "d-md-none"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Location"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "modal__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-location-widget", [], null, null, null, i2.View_LocationWidgetComponent_0, i2.RenderType_LocationWidgetComponent)), i1.ɵdid(6, 114688, null, 0, i3.LocationWidgetComponent, [i4.ApiService, i5.StoreService, i6.NgbModal, i7.FormBuilder, i8.LoaderService, i9.CartService, i10.ToastService, i11.UserService, i12.BranchLocationService, i13.Router, i14.FilterComponentPipe, i15.ModalService, i1.PLATFORM_ID], { user: [0, "user"], isOnMenu: [1, "isOnMenu"], isOnCheckout: [2, "isOnCheckout"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user; var currVal_1 = _co.isOnMenu; var currVal_2 = _co.isOnCheckout; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_LocationWidgetModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-location-widget-modal", [], null, [["document", "keydown.escape"]], function (_v, en, $event) { var ad = true; if (("document:keydown.escape" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onKeydownHandler($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_LocationWidgetModalComponent_0, RenderType_LocationWidgetModalComponent)), i1.ɵdid(1, 114688, null, 0, i16.LocationWidgetModalComponent, [i6.NgbActiveModal, i6.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LocationWidgetModalComponentNgFactory = i1.ɵccf("app-location-widget-modal", i16.LocationWidgetModalComponent, View_LocationWidgetModalComponent_Host_0, { user: "user", isOnMenu: "isOnMenu", isOnCheckout: "isOnCheckout" }, {}, []);
export { LocationWidgetModalComponentNgFactory as LocationWidgetModalComponentNgFactory };
