<div class="location-widget" [attr.disabled]="true">

    <h2 class="widget__title">Check to see if we deliver in your area</h2>
    <form [formGroup]="branchForm" *ngIf="branchForm">
        <div class="row justify-content-center align-items-center">

            <!-- delivery option -->
            <ng-container  *ngIf="deliveryType === 'delivery' && !user">
                <div class="col-6 col-md-auto">
                    <div ngbDropdown>
                        <button class="btn btn-outline-dark" id="dropdownProvince" ngbDropdownToggle
                            [disabled]="!branchForm?.get('province')?.value || disableDropdownButtons || isLoading">
                            <span class="btn-content">{{branchForm?.get('city')?.value?.title || 'Select Province'}}</span>
                            <span class="ml-2 custom-icon-arrow-down"></span>
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownProvince">
                            <ng-container *ngFor="let province of branchForm?.get('province')?.value">
                                <button ngbDropdownItem (click)="setBranch(province, 'city')">
                                    {{province?.title}}
                                </button>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div class="col-6 col-md-auto">
                    <div ngbDropdown>
                        <button class="btn btn-outline-dark" id="dropdownCity" ngbDropdownToggle
                            [disabled]="!branchForm?.get('city').value || disableDropdownButtons || isLoading">
                            <span class="btn-content">{{branchForm.get('brgy').value?.title || 'Select Branch'}}</span>
                            <span class="custom-icon-arrow-down"></span>

                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownCity">
                            <button ngbDropdownItem *ngFor="let city of branchForm?.get('city').value?.cities"
                                (click)="setBranch(city, 'brgy')"> {{city?.title}} </button>
                        </div>
                    </div>
                </div>

                <div class="col-6 col-md-auto">
                    <div ngbDropdown>
                        <button class="btn btn-outline-dark" id="dropdownBrgy" ngbDropdownToggle
                            [disabled]="!branchForm?.get('brgy').value || disableDropdownButtons || isLoading">
                            <span class="btn-content">{{branchForm?.get('branch').value?.title || 'Select Barangay'}}</span>
                            <span class="custom-icon-arrow-down"></span>
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBrgy">
                            <ng-container *ngFor="let brgy of branchForm?.get('brgy').value?.barangays">
                                <button ngbDropdownItem (click)="setBranch(brgy, 'branch')">
                                    {{brgy?.title}}
                                </button>
                            </ng-container>
                        </div>
                    </div>
                </div>                
            </ng-container>
            <ng-container  *ngIf="deliveryType === 'delivery' && user">
                

                <div class="location-image ">
                    <img class="img-fluid" src="assets/imgs/angels/Location-icon.png" alt="APC Photo 1">
                </div>
                <div class="location__description" *ngIf="branch.brgy">
                    {{ addressDetails?.profile?.address?.fill_type === 'manual' ? addressDetails?.profile?.address?.address_1 + ', ' 
                    + addressDetails?.profile?.barangay?.title + ', ' 
                    + addressDetails?.profile?.city?.title : addressDetails.profile?.address?.map_address}}                                                             
                </div>
                <div *ngIf="!branch.brgy">
                    <div class="location__description">
                        {{branch?.selected_branch?.title}}
                    </div>
                </div>
            </ng-container>
            <!-- end of delivery option -->

            <!-- pick-up option -->
            <!-- <div class="col-6 col-md-auto" *ngIf="deliveryType === 'pick-up' && user">                
                
                <div class="location-image ">
                    <img class="img-fluid" src="assets/imgs/angels/Location-icon.png" alt="APC Photo 1">
                    <div class="location__description">
                        {{ addressDetails?.province?.title || addresses?.delivery?.province?.title }} Branch
                    </div>
                </div>                
                
            </div> -->
            <div class="col-6 col-md-auto" *ngIf="deliveryType === 'pick-up'">                
                
                <div ngbDropdown>
                    <button class="btn btn-outline-dark" id="dropdownbranchPickup" ngbDropdownToggle
                        [disabled]="!branches || disableDropdownButtons || isLoading">
                        <span class="btn-content">{{branchForm?.get('selected_branch').value?.title || 'Select Branch'}}</span>
                        <span class="ml-2 custom-icon-arrow-down"></span>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownbranchPickup">
                        <ng-container *ngFor="let branch of branches | filterPipe: 'pick_up' : true">
                            
                            <button ngbDropdownItem (click)="setBranch(branch, 'selected_branch')">
                                {{branch?.title}}
                            </button>
                        </ng-container>
                    </div>
                </div>     
                
            </div>            
            <!-- end of pick-up option -->

            <!-- <div class="col-6 col-md-auto d-none d-md-block" *ngIf="!isOnMenu">
                <a [routerLink]="['/menu/1']" class="btn btn-secondary">Order Online Now</a>
            </div> -->

            <!-- delivery/pick-up -->
            <div class="col-6 col-md-auto location-options">
                <ng-container>
                    <div class="location-option">
                        <input (ngModelChange)="deliveryTypeChange($event)" value="delivery" [(ngModel)]="deliveryType" [ngModelOptions]="{standalone: true}" type="radio" checked="checked" name="delivery-type-widget" id="delivery-option-widget"  [disabled]="isLoading || cartService.allCartEgc">
                        <label class="radio-label" for="delivery-option-widget">
                            <p class="mb-0">Delivery</p>
                        </label>
                    </div>
                    <div class="location-option">
                        <input (ngModelChange)="deliveryTypeChange($event)" value="pick-up" [(ngModel)]="deliveryType" [ngModelOptions]="{standalone: true}" type="radio" name="delivery-type-widget" id="pickup-option-widget" [disabled]="isLoading || cartService.allCartEgc">
                        <label class="radio-label" for="pickup-option-widget">
                            <p class="mb-0">Pick-up</p>
                        </label>
                    </div>    
                </ng-container>
            </div>
            <!-- end of delivery/pickup -->

            <div *ngIf="user">
                <div class="col-md-auto" [ngClass]="{'d-none': (deliveryType === 'pick-up')}">                    

                    <button class="btn btn-secondary btn-option form-delivery-btn"
                        [disabled]="isLoading || cartService.allCartEgc"
                        (click)="changeLocation(deliveryType === 'delivery')">
                        Change My Location
                    </button>
                </div>
                <div class="col-md-auto" [ngClass]="{'d-none': (deliveryType === 'delivery')}">
                    <button class="btn btn-saved btn-option"
                    [ngClass]="{'btn-disabled': !branchForm?.valid && deliveryType === 'delivery', 'd-none': disableDropdownButtons}"
                    (click)="setSelectedBranch()" [disabled]="isLoading || cartService.allCartEgc">
                    <span class="custom-icon-check"></span>
                    Save {{deliveryType === 'delivery' ? 'My Location' : ''}}
                </button>
                <button class="btn btn-secondary btn-option form-delivery-btn"
                    [ngClass]="{'btn-disabled': !branchForm?.valid && deliveryType === 'delivery', 'd-none': !disableDropdownButtons}"
                    (click)="removeDisabledState(deliveryType === 'delivery' ? 'location' : 'branch')"  [disabled]="isLoading || cartService.allCartEgc">
                    Change {{deliveryType === 'delivery' ? 'My Location' : 'Branch'}}
                </button>
                </div>
            </div>
            <div *ngIf="!user">
                <button class="btn btn-saved btn-option"
                    [ngClass]="{'btn-disabled': !branchForm?.valid && deliveryType === 'delivery', 'd-none': disableDropdownButtons}"
                    (click)="setSelectedBranch()" [disabled]="isLoading || cartService.allCartEgc">
                    <span class="custom-icon-check"></span>
                    Save {{deliveryType === 'delivery' ? 'My Location' : ''}}
                </button>
                <button class="btn btn-secondary btn-option form-delivery-btn"
                    [ngClass]="{'btn-disabled': !branchForm?.valid && deliveryType === 'delivery', 'd-none': !disableDropdownButtons}"
                    (click)="removeDisabledState(deliveryType === 'delivery' ? 'location' : 'branch')"  [disabled]="isLoading || cartService.allCartEgc">
                    Change {{deliveryType === 'delivery' ? 'My Location' : 'Branch'}}
                </button>
            </div>
            
            
            <div class="spinner-border text-light" role="status" *ngIf="isLoading">
                <span class="sr-only">Loading...</span>
              </div>

        </div>

        <div class="text-center">
            <p>Can't find your location? You might want to try our Pick up option instead or call (02) 8922-2222.</p>
        </div>
        <!-- <div [class.text-dark]="isOnMenu || isOnCheckout" class="row justify-content-center text-white">
            For Pick-up or if you can’t find your location, please call or text: 09176250771 or 09150688453.
        </div> -->
    </form>
</div>