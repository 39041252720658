import { isPlatformBrowser } from '@angular/common';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { NgForage } from 'ngforage';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(
    private storage: NgForage,
    @Inject(PLATFORM_ID) private platformId
  ) { }

  async setItem(key, value) {
    if (isPlatformBrowser(this.platformId)) {
      return await this.storage.setItem(key, value);
    }
  }

  async getItem(key) {
    if (isPlatformBrowser(this.platformId)) {
      return await this.storage.getItem(key);
    }
  }

  async removeItem(key) {
    if (isPlatformBrowser(this.platformId)) {
      return await this.storage.removeItem(key);
    }
  }

}
