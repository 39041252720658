import { NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./app/app.service";
import * as i2 from "@angular/router";
export class RouteHelperService {
    constructor(appService, activatedRoute, router, platformId) {
        this.appService = appService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.platformId = platformId;
        this.routeSubject = new Subject();
        this.setupRouting();
    }
    setupRouting() {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd), map(() => this.activatedRoute), map(route => {
            while (route.firstChild) {
                route = route.firstChild;
            }
            return route;
        }), filter(route => route.outlet === 'primary')).subscribe((route) => {
            this.routeSubject.next(route);
            const seo = route.snapshot.data['seo'];
            // set your meta tags & title here
            if (seo) {
                const isPageMeta = true;
                this.appService.updateOgTags(seo, isPageMeta);
                this.scrollToTop();
            }
        });
    }
    scrollToTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }
}
RouteHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RouteHelperService_Factory() { return new RouteHelperService(i0.ɵɵinject(i1.AppService), i0.ɵɵinject(i2.ActivatedRoute), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i0.PLATFORM_ID)); }, token: RouteHelperService, providedIn: "root" });
