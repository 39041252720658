<!-- [class.w-promo-banner]="activeRoute === '/'" -->
<!-- <aside class="cart" [class.open]="state">  -->
  <!-- add class w-promo-banner on homepage  -->
  <!-- <div class="cart__head">
      <label>My Cart ({{cartService.cartStorage ? this.cartService.cartTotalQuantity() : 0}})</label>
      <span class="custom-icon-delete cart--close ml-auto" (click)="close()"></span>
  </div> -->
  <div class="cart__body">
      <div class="cart__item" *ngIf="location === 'header'">
          <div class="cart__info">
              <a [routerLink]="activeRoute.includes('menu') ? activeRoute: '/menu/1'" (click)="close.emit()">Continue
                  Shopping</a>
              <a href="javascript:void()" (click)="clearCart()" class="ml-auto pl-2">Empty Cart</a>
          </div>
      </div>
      <!-- <div class="cart__item" *ngIf="cartService.cartTotalObj && cartService?.getMinimumOrder()">
          <div class="cart__info cart__min">
              <label>Minimum Order</label>
              <span
                  class="ml-auto pl-2 s-fee">&#8369;{{minimumOrder | number : '1.2-2'}}</span>
          </div>
      </div> -->
      <div class="cart__item" *ngIf="cartService.cartTotalObj && minimumOrder" [ngClass]="{'d-none' : cartService.allCartEgc}">
          <div class="cart__info cart__min" >
              <label>Minimum Order</label>
              <span
                  class="ml-auto pl-2 s-fee">&#8369;{{minimumOrder | number : '1.2-2'}}</span>
          </div>
      </div>
      <div style="min-height: 200px; display: flex; justify-content: center; align-items: center;" *ngIf="!cartService?.cartStorage || cartService?.cartStorage?.length <= 0">
        <span
          *ngIf="cartService?.cartStorage?.length <= 0"
          class="color-lightestgrey text-center h2 font-normal-bold"
        >
          Order now!
        </span>
      </div>
      <!-- <div style="min-height: 200px;" *ngIf="cartService?.cartStorage && cartService?.cartStorage?.length > 0"> -->
        <!-- <div class="d-flex justify-content-center align-items-center h-100" style="height: 100%;"> -->
        <div class="cart__item product" *ngFor="let product of cartService.cartStorage; let i = index">
            <div class="cart__info row p-0 m-0">
                <div class="col-1 p-0 text-center" id="removepromo" *ngIf="product?.type !== 'e-gift-certificate-cart'" >
                  <a class='danger' (click)="removeItem(product, i)">
                    <!-- <i class='fal fa-times-circle'></i> -->
                    <span class="custom-icon-close"></span
                        >
                  </a>
                </div>
                <div class="col-1 p-0 text-center" id="removepromo" *ngIf="product?.type === 'e-gift-certificate-cart'" >
                    <a class='danger' (click)="removeEgc(product, i)">
                      <!-- <i class='fal fa-times-circle'></i> -->
                      <span class="custom-icon-close"></span
                          >
                    </a>
                  </div>
                <div class="product__attributes product_title col-5 p-0" (click)="edit(product, i)">
                    <!-- <span class="pr-2">X{{product?.quantity}}</span>
                    <img [src]="(product?.media || product?.product?.media) | image: 'src': 'mobile'" alt=""
                        class="prodcut__thumbnail pr-2"> -->
                    <span>{{ product?.title || product?.product?.title }}</span>
                    <!-- <span>{{ (product?.apc_card_buy1take1_available || product?.product?.apc_card_buy1take1_available) ? getSelectedVariant(product || product?.product, 'Size & Crust')?.title : product?.title || product?.product?.title }}</span> -->
                    <!-- <span>{{show(product || product?.product)}}</span> -->
                </div>
                <!-- <span>{{product.extra | json}}</span> -->
                <div class="col-3 p-0" *ngIf="!product?.selectedVariants?.length && !product?.extra?.variants?.length">
                  <span>{{product?.quantity}}</span>
                  <!-- <span>{{ (product?.apc_card_buy1take1_available || product?.product?.apc_card_buy1take1_available) ? getSelectedVariant(product || product?.product, 'Size & Crust')?.quantity : product?.quantity || product?.product?.quantity }}</span> -->
                  <!-- <span>{{show(product || product?.product)}}</span> -->
                </div>
                <div class="product_price col-3 pr-0" *ngIf="product?.type !== 'e-gift-certificate-cart' && !product?.selectedVariants?.length && !product?.extra?.variants?.length">
                    <!-- <span class="ml-auto pl-2 flex-shrink-0 unit_price">
                        {{
                            cartService.cartTotalObj?.products[i]?.voucher?.code
                                ? (cartService.cartTotalObj?.products[i]?.price?.amount | promoPriceDifference : cartService.cartTotalObj?.products[i]?.discount_price?.amount) 
                                : cartService.cartTotalObj?.products[i]?.price?.formatted
                        }}
                    </span> -->
                    <!-- <span>{{ (product?.apc_card_buy1take1_available || product?.product?.apc_card_buy1take1_available) ? getSelectedVariant(product || product?.product, 'Size & Crust')?.price?.formatted : cartService.cartTotalObj?.products[i]?.voucher?.code
                        ? (cartService.cartTotalObj?.products[i]?.price?.amount | promoPriceDifference : cartService.cartTotalObj?.products[i]?.discount_price?.amount) 
                        : cartService.cartTotalObj?.products[i]?.price?.formatted }}</span> -->
                        <span>{{ cartService.cartTotalObj?.products[i]?.voucher?.code
                            ? (cartService.cartTotalObj?.products[i]?.price?.amount | promoPriceDifference : cartService.cartTotalObj?.products[i]?.discount_price?.amount) 
                            : cartService.cartTotalObj?.products[i]?.price?.formatted }}</span>
                    <!-- fix alignment -->
                    <div class="promo-orig pl-2" *ngIf="cartService?.cartTotalObj?.products[i]?.voucher?.code">
                        <small class="text-muted">{{cartService?.cartTotalObj?.products[i]?.price?.formatted}}</small>  
                    </div>
                 </div>
                 <div class="product_price ml-auto" *ngIf="product?.type === 'e-gift-certificate-cart'">
                    <span class="ml-auto pl-2 flex-shrink-0 unit_price">
                        {{getEgcTotalAmount(product)}}
                    </span>
                 </div>
            </div>
            <div class="product__description"
                *ngIf="(product?.selectedAdditionalProducts?.length || product?.selectedVariants?.length) || 
                       (product?.product?.selectedAdditionalProducts?.length || product?.product?.selectedVariants?.length)">
  
                <div class="description__item"
                    *ngFor="let variants of (product?.selectedVariants || product?.product?.selectedVariants)">
                    <!-- <span class="pr-2">X{{product?.quantity}}</span> -->
                    <div class="row w-100 m-0 p-0">
                        <label class="col-6 p-0">{{variants?.title}}</label>
                        <label class="col-2 p-0 text-center">{{variants?.quantity}}</label>
                        <!-- ADD FLAVOR'S PRICE -->
                        <!-- <span class="ml-auto pl-2" *ngIf="variants?.price?.amount != '0'">{{variants?.type === 'Size & Crust' ? '': '+'}}
                            {{variants?.price?.formatted}}</span> -->
                        <!-- <span class="col-4 p-0 text-right" *ngIf="variants?.price?.amount != '0'">{{variants?.type === 'Size & Crust' || variants?.type === 'size-crust' ? '': '+'}}
                            {{variants?.price?.formatted}}</span> -->
                        <span class="col-4 p-0 text-right" *ngIf="variants?.price?.amount != '0'">{{variants?.price?.formatted}}</span>
                    </div>
                </div>
                <div class="description__item"
                    *ngFor="let addons of (product?.selectedAdditionalProducts || product?.product?.selectedAdditionalProducts || product?.product?.additionalProducts['add-on'])">
                    <span class="pr-2">X{{product?.quantity}}</span>
                    <label>{{addons?.title}}</label>
                    <span class="ml-auto pl-2">+ {{addons?.price?.formatted}}</span>
                </div>
            </div>
            <div class="product__note" *ngIf="product?.extra?.dietary_requirements">
                <span>{{product?.extra?.dietary_requirements}}</span>
            </div>
            <!-- <div>
                {{product?.extra?.apc_card?.product_take1?.title}}
            </div> -->
            <div class="product__description" *ngIf="product?.take1?.title">
                {{isBuy1Take1() && !cartService?.isDoubleDealCategory(product) ? 'Buy 1, Take 1' : 'Double Deal Pizza'}}: <br/>
                {{product?.take1?.title}}<span *ngFor="let variant of product?.take1?.selectedVariants">, {{variant?.title}}</span>
            </div>
            <div class="product__description" *ngIf="product?.extra?.apc_card?.product_take1?.title">
                {{isBuy1Take1() && !cartService?.isDoubleDealCategory(product) ? 'Buy 1, Take 1' : 'Double Deal Pizza'}}: <br/>
                {{product?.extra?.apc_card?.product_take1?.title}}<span *ngFor="let variant of product?.extra?.apc_card?.product_take1?.variants">, {{variant?.title}}</span>
            </div>
            <!-- <div class="product__subtotal" *ngIf="product?.type !== 'e-gift-certificate-cart'">
                <label>Subtotal</label>
                <span class="ml-auto pl-2 subtotal" *ngIf="cartService?.cartTotalObj?.products[i]">
                    {{cartService?.cartTotalObj?.products[i].total_price.formatted}}
                    {{
                        cartService.cartTotalObj?.products[i]?.voucher?.code
                          ? (cartService.cartTotalObj?.products[i]?.total_price?.amount | promoPriceDifference : cartService.cartTotalObj?.products[i].discount_total_price.amount) 
                          : cartService.cartTotalObj?.products[i]?.total_price.formatted
                    }}
                </span>
            </div> -->
            <!-- <div class="product__edit" *ngIf="product.type !== 'e-gift-certificate-cart'">
                <a href="javascript:void(0)" (click)="edit(product, i)">Edit</a>
            </div> -->
            <!-- <div class="product__edit" *ngIf="product.type === 'e-gift-certificate-cart'"> -->
            <!-- <div class="product__edit">
                <a href="javascript:void(0)" (click)="edit(product, i)">{{product.type === 'e-gift-certificate-cart' ? 'Remove' : 'Edit'}}</a>
            </div> -->
        </div>
      <!-- </div> -->
  </div>
  <div class="cart__tail" *ngIf="(cartService.cartTotalObj && cartService.cartStorage?.length)" [ngClass]="{'d-none' : cartService.allCartEgc}">
      <div class="min tail--item justify-content-center" *ngIf="((cartService.cartTotalObj.sub_total_price.amount | parseInt) * 0.01) < minimumOrder">
          <span class="pl-2 font-italic text-primary">&#8369;{{minimumOrder | number : '1.2-2'}} minimum price required</span>
      </div>
      <!-- <div class="tail--item">
          <label>Free Item/s</label>
          <div class="ml-auto pl-2 free-items">
              <span>
                  Free Item 1
              </span>
              <span>
                  Free Item 2 ...
              </span>
          </div>
      </div> -->
      <div class="cost tail--item">
          <label class="color-lightergrey">Cost</label>
          <span class="pl-2 ml-auto">
              <!-- {{cartService.cartTotalObj.sub_total_price.formatted}} -->
              {{
                  cartService.cartTotalObj?.discount_subtotal_price.amount == '0'
                    ? (cartService.cartTotalObj?.sub_total_price?.amount | promoPriceDifference : cartService.cartTotalObj?.discount_subtotal_price?.amount) 
                    : cartService.cartTotalObj?.sub_total_price?.formatted
              }}
          </span>
      </div>
      <div class="tail--item" *ngIf="deliveryType === 'delivery' && cartService.hasProducts()">
          <label class="color-lightergrey">Delivery Fee {{ baranggay ? '('+baranggay+')' : '' }}</label>
          <span class="ml-auto pl-2 s-fee">
              <!-- {{cartService.cartTotalObj.delivery_price.formatted}} -->
              {{
                  cartService.cartTotalObj?.discount_delivery_price.amount != '0'
                    ? (cartService.cartTotalObj?.delivery_price?.amount | promoPriceDifference : cartService.cartTotalObj?.discount_delivery_price?.amount) 
                    : cartService.cartTotalObj?.delivery_price?.formatted
              }}
          </span>
      </div>
      <div class="total tail--item">
        <label class="color-lightergrey">Discount</label>
        <span class="pl-2 ml-auto">{{cartService.cartTotalObj.discount_subtotal_price.formatted}}</span>
    </div>
      <div class="total tail--item">
          <label class="color-lightergrey">Total</label>
          <span class="pl-2 ml-auto">{{cartService.cartTotalObj.payable_price.formatted}}</span>
      </div>
      <div class="checkout tail--item">
          <button class="buttonstyle width-100" style="border: none"
              [disabled]="((cartService.cartTotalObj.sub_total_price.amount | parseInt) * 0.01) < minimumOrder"              
              (click)="gotoCheckout()">Proceed To Checkout</button>
      </div>      
  </div>    
  <div class="checkout tail--item">
    <button class="buttonstyle width-100" style="border: none; margin-top: 15px;"
        [ngClass]="{'d-none' : !cartService.allCartEgc || !cartService.cartStorage.length}"        
        (click)="gotoCheckout()">Proceed To Checkout</button>
</div>    
<!-- </aside> -->