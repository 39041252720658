import { GlobalService } from './../global.service';
import { FormdataService } from './../formdata.service';
import { ToastService } from './../toast/toast.service';
import { Router } from '@angular/router';
import { CartService } from './../cart/cart.service';
import { ApiService } from './../api/api.service';
import { StoreService } from './../store/store.service';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Pusher from 'pusher-js';
import { ApcCardInputModalComponent } from 'src/app/components/apc-card-input-modal/apc-card-input-modal.component';
import pusherJs from 'pusher-js';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userSubject = new Subject<any>();
  userStore: any;
  isStopRequest = false;
  apcCardNumber: any = "";
  showApcCardInput = false;
  user: boolean = false

  constructor(
    private storeService: StoreService,
    private apiService: ApiService,
    private cartService: CartService,
    private router: Router,
    private toastService: ToastService,
    private formdataService: FormdataService,
    private modalService: NgbModal,
  ) { }

  async getUser(isUpdate?, isFbLogin?) {
    const user = await this.storeService.getItem('user');
    const token = await this.storeService.getItem('token');
    if ((!isUpdate && user) || !token) {
      return;
    }
    try {
      const headers = { Authorization: `Bearer ${token}` };
      const res = await this.apiService.request({
        method: 'GET',
        url: '/auth/user/profile?include=media,addresses,orders',
        headers,
        jsona: true
      });
      if (!res.has_verified_email) {
        return res;
      }
      // await this.storeService.setItem('social_login', isFbLogin ? true : false);
      await this.storeService.setItem('user', res);
      this.user = true
      this.userSubject.next(res);
    } catch (err) {
      console.log(err);
    }
  }

  updateUserData(res) {
    this.userSubject.next(res);
  }

  async refreshToken() {
    try {
      const refreshToken = await this.storeService.getItem('refresh_token');
      const payload = {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        client_id: GlobalService.LOGIN_CLIENT_KEYS.ID_PASSWORD,
        client_secret: GlobalService.LOGIN_CLIENT_KEYS.SECRET,
      };
      const loginRes: any = await this.apiService.postRequest({
        url: '/oauth/token',
        body: this.formdataService.generate(payload)
      });     
      await this.storeService.setItem('token', loginRes.access_token);
      await this.storeService.setItem('refresh_token', loginRes.refresh_token);            
      return 'success';
    } catch (err) {     
      return 'revoked';
    }
  }

  async revokeAuth() {
    this.logout();    
    
    if (this.isStopRequest || this.router.url.includes('login')) {
      return;
    }
    this.isStopRequest = true;
    this.toastService.show('New session detected. You will be logged out.', { classname: 'bg-danger text-light' });
    await this.cartService.emptyCart();
    await this.router.navigate(['/']);
    this.updateUserData(null); // remove user info on status 401

    setTimeout(() => {
      this.isStopRequest = false;
    }, 1000);

    this.reloadApp();
  }

  async realTime() {    
    
  }

  async logout(logoutAction?) {
    this.userSubject.next(null);
    [
      'user',
      'token',
      'cart',
      'checkout_details',
      'refresh_token',
      'login_timestamp',
      'social_login',
      'apc',
      'voucher_codes'
      // 'branchLocation'
    ].filter(async key => {
      await this.storeService.removeItem(key);
    });

    await this.cartService.emptyCart();
    if (logoutAction) {
      this.reloadApp();
    }
  }

  reloadApp() {
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }

  async setApcCardNumber(value) {
    await this.storeService.setItem("apc", value);
    this.apcCardNumber = await this.storeService.getItem("apc");
    // return this.apcCardNumber;
  }

  async resetApcCardNumber() {
    await this.storeService.removeItem("apc");
    await this.getApcCardNumber();
    // this.apcCardNumber = await this.storeService.getItem("apc") || "";
    // return this.apcCardNumber;
  }

  async getApcCardNumber() {
    this.apcCardNumber = await this.storeService.getItem("apc") || "";
    // return this.apcCardNumber;
  }
}
