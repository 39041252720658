import * as tslib_1 from "tslib";
import { isPlatformBrowser } from '@angular/common';
import { MenuItemComponent } from './../menu-item/menu-item.component';
import { OnInit, EventEmitter } from '@angular/core';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog/confirm-dialog.component';
import { GlobalService } from 'src/app/services/global.service';
export class CartComponent {
    constructor(cartService, storeService, toastService, router, routeService, modalService, appService, apiService, platformId) {
        this.cartService = cartService;
        this.storeService = storeService;
        this.toastService = toastService;
        this.router = router;
        this.routeService = routeService;
        this.modalService = modalService;
        this.appService = appService;
        this.apiService = apiService;
        this.platformId = platformId;
        this.activeRoute = '';
        this.state = false;
        this.baranggay = '';
        this.deliveryType = 'delivery';
        this.minimumOrder = 0;
        this.closeCart = new EventEmitter();
        this.emptyCart = new EventEmitter();
        this.egcEnabled = GlobalService.FEATURES.egc;
    }
    set cartState(value) {
        this.state = value;
    }
    ngOnInit() {
        this.routeService.routeSubject.subscribe((route) => {
            this.activeRoute = route.snapshot._routerState.url;
        });
        if (isPlatformBrowser(this.platformId)) {
            this.setBaranggay();
            this.cartService.onDeliveryTypeChange().subscribe(deliveryType => {
                if (deliveryType) {
                    this.deliveryType = deliveryType;
                    let minimumOrder = this.appService.siteSettings.minimum_order_amount_delivery;
                    if (this.deliveryType === 'pick-up') {
                        minimumOrder = this.appService.siteSettings.minimum_order_amount_pick_up;
                    }
                    this.cartService.changeMinimumOrder(minimumOrder);
                }
            });
            this.cartService.onMinimumOrderChange().subscribe(minimumOrder => {
                this.minimumOrder = minimumOrder;
            });
        }
    }
    setBaranggay() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const branchLocation = yield this.storeService.getItem('branchLocation');
            if (branchLocation) {
                if (!branchLocation.branch) {
                    return;
                }
                this.baranggay = branchLocation.branch.title;
            }
        });
    }
    close() {
        this.state = false;
        this.closeCart.emit(this.state);
    }
    gotoCheckout() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const user = yield this.storeService.getItem('user');
            if (user) {
                if (!user.has_verified_email) {
                    this.toastService.showDangerToast('Please verify your email first to proceed.');
                    return;
                }
            }
            if (this.cartService.cartStorage['length']) {
                this.close();
                this.router.navigate(['/checkout']);
            }
            else {
                this.toastService.showDangerToast('Your cart is empty, please add products to checkout');
            }
            this.modalService.dismissAll();
        });
    }
    editProduct(product, index) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const token = yield this.storeService.getItem('token');
            if (token) {
                product = product.product;
            }
            const modalComponent = this.modalService.open(MenuItemComponent, {
                ariaLabelledBy: 'modal-basic-title',
                windowClass: 'modal-cart',
                backdropClass: 'menuitem-backdrop'
            });
            const productDetails = JSON.parse(JSON.stringify(product)); // deep copy
            console.log('product details => ', productDetails);
            modalComponent.componentInstance.productDetails = productDetails;
            modalComponent.componentInstance.modalCtrl = modalComponent;
            modalComponent.componentInstance.isCartEdit = true;
            modalComponent.componentInstance.productIndexFromCart = index;
            // dismiss cart, add condition when needed
            this.close();
        });
    }
    removeEgc(egc, index) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modalComponent = this.modalService.open(ConfirmDialogComponent, {
                ariaLabelledBy: "modal-basic-title",
                windowClass: "confirm-dialog-modal",
                centered: true,
            });
            modalComponent.componentInstance.title = "Remove Item to Cart";
            modalComponent.componentInstance.confirmationMessage =
                "Are you sure you want to remove this EGC to cart?";
            modalComponent.result.then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (res) {
                    const token = yield this.storeService.getItem("token");
                    if (!token) {
                        this.cartService.removeItemInCart(egc, index);
                        return;
                    }
                    const headers = { Authorization: `Bearer ${token}` };
                    console.log('egc => ', egc);
                    const removeEgcResponse = yield this.apiService.request({
                        method: 'DELETE',
                        url: `/auth/e-gift-certificate-cart/${egc.id}`,
                        headers,
                    });
                    yield this.cartService.getCartItems();
                    // const token = await this.storeService.getItem("token");
                    // if (!token) {
                    //   this.cartService.removeItemInCart(egc, index);
                    // } else {
                    //   // const cartItem = this.cartService.cartStorage[
                    //   //   this.productIndexFromCart
                    //   // ];
                    //   this.loaderService.showAsyncLoader();
                    //   await this.cartService.removeCartItem(egc);
                    //   this.loaderService.hideAsyncLoader();
                    // }
                    // // this.modalCtrl.close();
                }
            }));
        });
    }
    clearCart() {
        this.emptyCart.emit();
    }
    getEgcTotalAmount(egcProduct) {
        // console.log('egc product => ', egcProduct);
        if (!egcProduct || !egcProduct.quantity || !egcProduct.price || !egcProduct.price.amount) {
            return '';
        }
        // console.log('amount => ', (parseInt(egcProduct.price.amount) / 100))
        return `₱${((parseFloat(egcProduct.price.amount) / 100) * egcProduct.quantity).toFixed(2)}`;
    }
}
