import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Inject, HostListener } from '@angular/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  title = 'Confirmation';
  confirmationMessage = '';
  buttonText = {
    accept: '',
    cancel: ''
  };
  removeBtn = false
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.modalRef.dismiss();
  }
  constructor(public modalRef: NgbActiveModal) { }

  ngOnInit() {

  }
}
