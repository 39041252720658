<!-- <section class="block-login page-content">
  <div class="container block--padding">
    <div class="col-md-6 mx-auto">
      <div class="block__title">
        <h2 class="text-primary text-center section--title mb-4">Login</h2>
      </div> -->

<div class="popup-container w-auto border-radius-primary overflow-hidden">
  <div class="block-details popup-innercontainer text-center inputstyle">
    <div class="popup-close font-thin">
      <a (click)="closeModal.emit()">Close (x)</a>
    </div>
    <h6 class="py-3">Welcome back!</h6>
    <form class="w-100 px-2 px-md-5" (ngSubmit)="login()" [formGroup]="credentialsForm">
      <div class="form-group">
        <label
          [class.text-invalid]="
            !credentialsForm.get('username').valid &&
            credentialsForm.get('username').touched
          "
        >
          Email
          {{
            credentialsForm.get("username").hasError("required") &&
            credentialsForm.get("username").touched
              ? "is required."
              : credentialsForm.get("username").hasError("pattern") &&
                credentialsForm.get("username").touched
              ? "is invalid."
              : ""
          }}
        </label>
        <input
          maxlength="50"
          type="text"
          formControlName="username"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label
          [class.text-invalid]="
            !credentialsForm.get('password').valid &&
            credentialsForm.get('password').touched
          "
        >
          Password
          {{
            !credentialsForm.get("password").valid &&
            credentialsForm.get("password").touched
              ? "is required."
              : ""
          }}
        </label>
        <div class="input-password">
          <input
            maxlength="50"
            [type]="isShowPass ? 'text' : 'password'"
            formControlName="password"
            class="form-control"
          />
          <span
            [ngClass]="{
              'custom-icon-eye-regular': !isShowPass,
              'custom-icon-eye-slash': 'isShowPass'
            }"
            class="icon"
            (click)="isShowPass = !isShowPass"
          ></span>
        </div>
      </div>
      <div class="form-group">
        <button type="submit" class="btn buttonstyle-success w-100">LOGIN</button>
      </div>
      <div class="form-group">
        <a routerLink="/reset-password" (click)="closeModal.emit()">Forgot password?</a>
      </div>
    </form>
    <div>
      <p>Dont have an account? <a (click)="openSignUpModal.emit()">Register</a></p>
    </div>
    <!-- <div>
      <hr />
      <p>Login with another account</p>
    </div>
    <div class="form-group">
      <button
        type="button"
        (click)="loginWithFb()"
        class="btn btn-social btn-block btn--fb"
      >
        <span class="custom-icon-fb pr-4"></span>
        LOGIN USING FACEBOOK
      </button>
    </div> -->
  </div>

  <!-- </div>
  </div>

</section> -->
</div>
