import { AppService } from './../../services/app/app.service';
import { ConfirmDialogComponent } from './../../components/confirm-dialog/confirm-dialog/confirm-dialog.component';
import { BranchLocationService } from './../../services/branch-location/branch-location.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectBranchComponent } from './../../components/select-branch/select-branch.component';
import { isPlatformBrowser } from '@angular/common';
import { ScrollListenerService } from './../../services/scroll-listener/scroll-listener.service';
import { LoaderService } from './../../services/loader.service';
import { StoreService } from './../../services/store/store.service';
import { CartService } from 'src/app/services/cart/cart.service';
import { ToastService } from './../../services/toast/toast.service';
import { Title } from '@angular/platform-browser';
import { ApiService } from '../../services/api/api.service';
import { Component, OnInit, Inject, PLATFORM_ID, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { SubscriptionLike } from 'rxjs';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  user: any;
  baseUrl: string;
  products: Array<any>;
  categories: Array<any>;
  about: any;
  homeDetails: any;
  articles;

  swiperConfig = {
    bannerSlider: {
      lazy: true,
      preloadImages: false,
      loop: true,
      slidesPerView: 1,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        hideOnClick: false
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
    },
  };

  homeBanners: any;
  aboutHomeBanner: any;
  homePage: any;
  subscription: SubscriptionLike;
  branchForm: FormGroup;

  hasStoredLocation = false;
  disableDropdownButtons = false;
  savedSuccess = false;

  scrollConfig = {
    scrollHeight: 1,
    scrollTop: 0
  };

  aboutVideoUrl;

  // @ViewChild('aboutBlock', { static: false }) aboutBlock: ElementRef;

  constructor(
    private apiService: ApiService,
    private toastService: ToastService,
    private cartService: CartService,
    private storeService: StoreService,
    private loaderService: LoaderService,
    private title: Title,
    private scrollListenerService: ScrollListenerService,
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId,
    public appService: AppService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    public branchLocationService: BranchLocationService,
  ) { }

  ngOnInit() {
    let siteTitle = 'Home';
    if (this.appService.siteName) {
      siteTitle = `${siteTitle} - ${this.appService.siteName}`;
    }
    this.title.setTitle(siteTitle);
    this.homePageInit();
  }

  async homePageInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.user = await this.storeService.getItem('user');

      await this.getHomeSliders(),
        this.getAboutHomeBanner(),
        this.getHomePage(),
        this.getArticles();

      this.loaderService.hide();

      await Promise.all([
        this.cartService.getCartItems(),
        // this.getAbout()
      ]);
      

      window.scrollTo(0, 0);

      let loaded = false;
      this.subscription = this.scrollListenerService.scrollListenerSubject.subscribe(scrollTop => {
        this.scrollConfig.scrollTop = scrollTop;

        // get scroll max height
        // 82 as footer offset
        this.scrollConfig.scrollHeight = (document.documentElement.scrollHeight - window.innerHeight) - 82;

        if (scrollTop > 0 && !loaded) {
          // this.getProducts();
          this.getHomeDetails();
          loaded = true;
          // this.subscription.unsubscribe();
        }
      });
    }
  }

  async getHomeSliders() {
    try {
      const res = await this.apiService.request({
        method: 'GET',
        url: '/sliders/homepage',
        jsona: true
      });
      this.homeBanners = res;
      
    } catch (err) {
      console.log(err);
    }
  }

  async getAboutHomeBanner() {
    try {
      const res = await this.apiService.request({
        method: 'GET',
        url: '/sliders/about-homepage',
        jsona: true
      });
      this.aboutHomeBanner = res;
    } catch (err) {
      console.log(err);
    }
  }

  async getHomePage() {
    try {
      const res = await this.apiService.request({
        method: 'GET',
        url:'/settings/angels-pizza-homepage',
        // jsona: true
      })
      console.log('[getHomePage] res =>', res);
      this.homePage = res;
      if (this.homePage.embed_url) {
        this.homePage.embed_url = this.homePage.embed_url.substring(this.homePage.embed_url.lastIndexOf('/') + 1)
        this.homePage.embed_url = `<lite-youtube videoid="${this.homePage.embed_url}" style="max-width: 100%;"></lite-youtube>`
      }

      console.log('home page =>', this.homePage);
    } catch (err) {
      console.log(err);
    }
  }

  async getAbout() {
    try {
      const res = await this.apiService.request({
        method: 'GET',
        url: '/value-store/about'
      });
      this.about = res['company_profile_content'];
      this.aboutVideoUrl = res['history_video_embed'];
    } catch (err) {
      console.log(err);
    }
  }

  async showBranchList() {
    const branch = await this.storeService.getItem('branch');
    const branchLocation: any = await this.storeService.getItem('branchLocation');
    if (branch && branchLocation) {
      return;
    }

    const user = await this.storeService.getItem('user');
    const modalComponent = this.modalService.open(SelectBranchComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'postcode-confirmation-modal',
      size: 'lg'
      // centered: true
    });
    modalComponent.componentInstance.user = user;
  }

  removeDisabledState() {
    const isCartStorageArr = Array.isArray(this.cartService.cartStorage);
    if (!isCartStorageArr || !this.cartService.cartStorage['length']) {
      this.disableDropdownButtons = false;
      return;
    }
    const modalCtrl = this.modalService.open(ConfirmDialogComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'postcode-confirmation-modal',
      size: 'lg',
      centered: true
    });
    modalCtrl.componentInstance.confirmationMessage = `
      Product availability may vary if you change your current location.
      <br/><br/>
      Do you want to proceed?
    `;
    modalCtrl.componentInstance.title = 'Notice';
    modalCtrl.componentInstance.buttonText.accept = 'Change Barangay';
    modalCtrl.result.then(res => {
      if (!res) {
        return;
      }
      this.disableDropdownButtons = false;
    });
  }

  async setSelectedBranch() {
    if (!this.branchForm.valid) {
      return;
    }
    try {
      const selectedBranch = this.branchForm.get('selected_branch').value;
      const user = await this.storeService.getItem('user');
      await this.storeService.setItem('branchLocation', this.branchForm.value);
      await this.storeService.setItem('brgy_segment', this.branchForm.value.branch.segment);
      this.loaderService.showAsyncLoader();

      const storedBranchSegment = await this.storeService.getItem('branch');
      if (storedBranchSegment !== selectedBranch.segment) {
        if (Array.isArray(this.cartService.cartStorage)) {
          if (this.cartService.cartStorage.length) {
            if (user) {
              await this.cartService.removeAllCartItems();
            } else {
              await this.cartService.emptyCart();
            }
          }
        }

      }

      await this.storeService.setItem('branch', selectedBranch.segment);
      this.savedSuccess = true;
      setTimeout(() => {
        this.loaderService.hideAsyncLoader();
        window.location.reload();
      }, 500);
    } catch (err) {
      this.loaderService.hideAsyncLoader();
      this.toastService.showDangerToast('Failed to change branch, Please try again.');
      console.log(err);
    }
  }

  async getHomeDetails() {
    try {
      const res = await this.apiService.request({
        method: 'GET',
        url: '/value-store/home-detail'
      });
      this.homeDetails = res;
    } catch (err) {
      console.log(err);
    }
  }

  

  ngAfterViewInit() {
    // this.elementRef.nativeElement.ownerDocument.body.style.backgroundImage = 'url("../../../assets/imgs/angels/background-home.jpg")';
    // this.elementRef.nativeElement.ownerDocument.body.style.backgroundPosition = 'top center';
    // this.elementRef.nativeElement.ownerDocument.body.style.backgroundSize = 'cover';

    // this.formatAboutBlock();
  }

  navigate(link: string) {
    if (!link) {
      return;
    }
    window.location.href = link;
  }

  // formatAboutBlock() {
  //   // limit html tags to three
  //   let children = this.aboutBlock.nativeElement.children;
  //   for (const [i, child] of Object.entries(children)) {
  //     if (parseInt(i) > 3) {
  //       this.renderer.removeChild(this.aboutBlock.nativeElement, child);
  //     }
  //   }
  // }
  async getArticles(page?) {
    try {

      const res = await this.apiService.request({
        method: 'GET',
        url: `/articles?include=media&limit=2&page=${page || 1}&filter[homepage]=1`,
        jsona: true
      });

      console.log('res =>', res);

      if (res && res.length && res.length > 0) {
        this.articles = res;
      }

      console.log('articles =>', res);

      this.loaderService.hide();
      
      
    } catch (err) {
      this.loaderService.hide();
      console.log(err);
      this.apiService.showErrors(err);
    }
  }

}
