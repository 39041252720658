<!-- TODO @edsel add close on toast (DONE) -->
<ngb-toast *ngFor="let toast of toastService.toasts; let i = index"
    [class]="toast.classname"
    [autohide]="true"
    (hide)="close(i)"
    [delay]="toast.delay || 5000"
    (hide)="toastService.remove(toast)"
    header="Notification">
    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
    </ng-template>
    <ng-template #text>
        <div class="toast-message" [innerHTML]="toast.textOrTpl"></div>
    </ng-template>
</ngb-toast>