/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./item-comment.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "./item-comment.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
var styles_ItemCommentComponent = [i0.styles];
var RenderType_ItemCommentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ItemCommentComponent, data: {} });
export { RenderType_ItemCommentComponent as RenderType_ItemCommentComponent };
export function View_ItemCommentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["aria-label", "Close"], ["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [["class", "acc-close custom-icon-delete"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 11, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "if__comment"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 5, "textarea", [["class", "form-control"], ["placeholder", "Do you have specific needs? Enter them here..."], ["rows", "3"], ["style", "resize: none;"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 10)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 10)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.product = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(12, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(14, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.product; _ck(_v, 12, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.product == null) ? null : _co.product.title) || ((_co.product == null) ? null : ((_co.product.product == null) ? null : _co.product.product.title))); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 14).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 14).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 14).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 14).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 14).ngClassValid; var currVal_6 = i1.ɵnov(_v, 14).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 14).ngClassPending; _ck(_v, 9, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_ItemCommentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-item-comment", [], null, [["document", "keydown.escape"]], function (_v, en, $event) { var ad = true; if (("document:keydown.escape" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onKeydownHandler($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ItemCommentComponent_0, RenderType_ItemCommentComponent)), i1.ɵdid(1, 114688, null, 0, i3.ItemCommentComponent, [i4.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ItemCommentComponentNgFactory = i1.ɵccf("app-item-comment", i3.ItemCommentComponent, View_ItemCommentComponent_Host_0, {}, {}, []);
export { ItemCommentComponentNgFactory as ItemCommentComponentNgFactory };
