import { Pipe, PipeTransform } from "@angular/core";

// TODO: "for temporary use only"

@Pipe({
  name: "filterPipe",
})
export class FilterComponentPipe implements PipeTransform {

  transform(objArr, field, value: boolean): any[] {

    if(!objArr || objArr.length <= 0) return []

    console.log('objArr => ', objArr);

    if (field === 'delivery') {
      objArr = objArr
      .map(barangay => {
        
        if (!barangay || !barangay.branches || barangay.branches.length <= 0) return barangay;

        let branches = barangay.branches.filter(o => o.extra[field] && o.extra[field].status === value);

        console.log('barangay => ', barangay);
        console.log('branches =>', branches);

        return {
          ...barangay,
          branches
        }
      })
      .filter(barangay => barangay.branches && barangay.branches.length > 0);

      return objArr;
    }

    if (!objArr || !objArr.length) {
      return [];
    }
    return objArr.filter(o => o.extra[field] && o.extra[field].status === value);
  }
}
