import * as tslib_1 from "tslib";
import { GlobalService } from './../global.service';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../store/store.service";
import * as i2 from "../api/api.service";
import * as i3 from "../cart/cart.service";
import * as i4 from "@angular/router";
import * as i5 from "../toast/toast.service";
import * as i6 from "../formdata.service";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
export class UserService {
    constructor(storeService, apiService, cartService, router, toastService, formdataService, modalService) {
        this.storeService = storeService;
        this.apiService = apiService;
        this.cartService = cartService;
        this.router = router;
        this.toastService = toastService;
        this.formdataService = formdataService;
        this.modalService = modalService;
        this.userSubject = new Subject();
        this.isStopRequest = false;
        this.apcCardNumber = "";
        this.showApcCardInput = false;
        this.user = false;
    }
    getUser(isUpdate, isFbLogin) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const user = yield this.storeService.getItem('user');
            const token = yield this.storeService.getItem('token');
            if ((!isUpdate && user) || !token) {
                return;
            }
            try {
                const headers = { Authorization: `Bearer ${token}` };
                const res = yield this.apiService.request({
                    method: 'GET',
                    url: '/auth/user/profile?include=media,addresses,orders',
                    headers,
                    jsona: true
                });
                if (!res.has_verified_email) {
                    return res;
                }
                // await this.storeService.setItem('social_login', isFbLogin ? true : false);
                yield this.storeService.setItem('user', res);
                this.user = true;
                this.userSubject.next(res);
            }
            catch (err) {
                console.log(err);
            }
        });
    }
    updateUserData(res) {
        this.userSubject.next(res);
    }
    refreshToken() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const refreshToken = yield this.storeService.getItem('refresh_token');
                const payload = {
                    grant_type: 'refresh_token',
                    refresh_token: refreshToken,
                    client_id: GlobalService.LOGIN_CLIENT_KEYS.ID_PASSWORD,
                    client_secret: GlobalService.LOGIN_CLIENT_KEYS.SECRET,
                };
                const loginRes = yield this.apiService.postRequest({
                    url: '/oauth/token',
                    body: this.formdataService.generate(payload)
                });
                yield this.storeService.setItem('token', loginRes.access_token);
                yield this.storeService.setItem('refresh_token', loginRes.refresh_token);
                return 'success';
            }
            catch (err) {
                return 'revoked';
            }
        });
    }
    revokeAuth() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.logout();
            if (this.isStopRequest || this.router.url.includes('login')) {
                return;
            }
            this.isStopRequest = true;
            this.toastService.show('New session detected. You will be logged out.', { classname: 'bg-danger text-light' });
            yield this.cartService.emptyCart();
            yield this.router.navigate(['/']);
            this.updateUserData(null); // remove user info on status 401
            setTimeout(() => {
                this.isStopRequest = false;
            }, 1000);
            this.reloadApp();
        });
    }
    realTime() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
        });
    }
    logout(logoutAction) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.userSubject.next(null);
            [
                'user',
                'token',
                'cart',
                'checkout_details',
                'refresh_token',
                'login_timestamp',
                'social_login',
                'apc',
                'voucher_codes'
                // 'branchLocation'
            ].filter((key) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                yield this.storeService.removeItem(key);
            }));
            yield this.cartService.emptyCart();
            if (logoutAction) {
                this.reloadApp();
            }
        });
    }
    reloadApp() {
        setTimeout(() => {
            window.location.reload();
        }, 100);
    }
    setApcCardNumber(value) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.storeService.setItem("apc", value);
            this.apcCardNumber = yield this.storeService.getItem("apc");
            // return this.apcCardNumber;
        });
    }
    resetApcCardNumber() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.storeService.removeItem("apc");
            yield this.getApcCardNumber();
            // this.apcCardNumber = await this.storeService.getItem("apc") || "";
            // return this.apcCardNumber;
        });
    }
    getApcCardNumber() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.apcCardNumber = (yield this.storeService.getItem("apc")) || "";
            // return this.apcCardNumber;
        });
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.StoreService), i0.ɵɵinject(i2.ApiService), i0.ɵɵinject(i3.CartService), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.ToastService), i0.ɵɵinject(i6.FormdataService), i0.ɵɵinject(i7.NgbModal)); }, token: UserService, providedIn: "root" });
