import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-information-dialog',
  templateUrl: './information-dialog.component.html',
  styleUrls: ['./information-dialog.component.scss']
})
export class InformationDialogComponent implements OnInit {
  title = '';
  message = '';
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.modalRef.dismiss();
  }
  constructor(public modalRef: NgbActiveModal) { }

  ngOnInit() {
  }

}
