import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class LoaderService {
    constructor() {
        this.loaderSubject = new BehaviorSubject({ show: false });
        this.loaderState = this.loaderSubject.asObservable();
        this.asyncLoaderSubject = new BehaviorSubject({ show: false });
        this.asyncLoaderState = this.asyncLoaderSubject.asObservable();
        this.isLoading = new BehaviorSubject(false);
    }
    show() {
        this.loaderSubject.next({ show: true });
    }
    hide() {
        this.loaderSubject.next({ show: false });
    }
    showAsyncLoader() {
        this.asyncLoaderSubject.next({ show: true });
    }
    hideAsyncLoader() {
        this.asyncLoaderSubject.next({ show: false });
    }
}
LoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoaderService_Factory() { return new LoaderService(); }, token: LoaderService, providedIn: "root" });
