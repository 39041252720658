import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'promoPriceDifference'
})
export class PromoPriceDifferencePipe implements PipeTransform {

  transform(original, discount): any {
    const originalInt = parseInt(original, 10) * 0.01;
    const discountInt = parseInt(discount, 10) * 0.01;
    const difference = originalInt - discountInt;
    return `₱${difference.toFixed(2)}`;
  }

}
