/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cart.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../cart-content/cart-content.component.ngfactory";
import * as i3 from "../cart-content/cart-content.component";
import * as i4 from "../../services/cart/cart.service";
import * as i5 from "../../services/store/store.service";
import * as i6 from "../../services/toast/toast.service";
import * as i7 from "@angular/router";
import * as i8 from "../../services/route-helper.service";
import * as i9 from "@ng-bootstrap/ng-bootstrap";
import * as i10 from "../../services/app/app.service";
import * as i11 from "../../services/api/api.service";
import * as i12 from "../../services/loader.service";
import * as i13 from "../../services/user/user.service";
import * as i14 from "./cart.component";
var styles_CartComponent = [i0.styles];
var RenderType_CartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CartComponent, data: {} });
export { RenderType_CartComponent as RenderType_CartComponent };
export function View_CartComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "aside", [["class", "cart orderlist"]], [[2, "open", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "cart__head"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["My Cart (", ")"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "custom-icon-delete cart--close ml-auto"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-cart-content", [["class", "body"]], null, [[null, "emptyCart"], [null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("emptyCart" === en)) {
        var pd_0 = (_co.emptyCart.emit() !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_CartContentComponent_0, i2.RenderType_CartContentComponent)), i1.ɵdid(6, 114688, null, 0, i3.CartContentComponent, [i4.CartService, i5.StoreService, i6.ToastService, i7.Router, i8.RouteHelperService, i9.NgbModal, i10.AppService, i11.ApiService, i12.LoaderService, i13.UserService, i1.PLATFORM_ID], { location: [0, "location"] }, { close: "close", emptyCart: "emptyCart" })], function (_ck, _v) { var currVal_2 = "header"; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state; _ck(_v, 0, 0, currVal_0); var currVal_1 = (_co.cartService.cartStorage ? _co.cartService.cartTotalQuantity() : 0); _ck(_v, 3, 0, currVal_1); }); }
export function View_CartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cart", [], null, null, null, View_CartComponent_0, RenderType_CartComponent)), i1.ɵdid(1, 114688, null, 0, i14.CartComponent, [i4.CartService, i5.StoreService, i6.ToastService, i7.Router, i8.RouteHelperService, i9.NgbModal, i10.AppService, i11.ApiService, i1.PLATFORM_ID], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CartComponentNgFactory = i1.ɵccf("app-cart", i14.CartComponent, View_CartComponent_Host_0, { cartState: "cartState" }, { closeCart: "closeCart", emptyCart: "emptyCart" }, []);
export { CartComponentNgFactory as CartComponentNgFactory };
