import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
export class PromoCodeModalComponent {
    constructor(storeService, toastService, modalService, loaderService, cartService, router) {
        this.storeService = storeService;
        this.toastService = toastService;
        this.modalService = modalService;
        this.loaderService = loaderService;
        this.cartService = cartService;
        this.router = router;
        this.cartItemAddedSuccessText = '';
        this.promoCode = '';
        this.isPromoCodeValid = true;
        this.promoCodeErrMessage = '';
    }
    onKeydownHandler(event) {
        this.closeModal();
    }
    ngOnInit() {
    }
    closeModal() {
        this.modalCtrl.close();
    }
    searchProductByPromo(voucherCode) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const isModal = true;
                this.loaderService.showAsyncLoader();
                const token = yield this.storeService.getItem('token');
                if (!token) {
                    const isDuplicateVoucherCode = yield this.cartService.checkVoucherValidator(voucherCode);
                    if (isDuplicateVoucherCode) {
                        this.isPromoCodeValid = false;
                        this.promoCodeErrMessage = 'This promo code is currently applied';
                        return;
                    }
                }
                const noProductFound = yield this.searchPromoCodeFn(voucherCode, isModal);
                if (noProductFound) {
                    this.isPromoCodeValid = false;
                    this.promoCodeErrMessage = 'Incorrect Promo Code.';
                }
                if (!noProductFound) {
                    this.modalCtrl.dismiss();
                }
            }
            catch (err) {
                if (err.status === 404 || err.status === 422) {
                    this.isPromoCodeValid = false;
                }
                const hasMessageKey = Object.keys(err.error).includes('message');
                if (hasMessageKey) {
                    this.promoCodeErrMessage = err.error.message;
                    return;
                }
                const errorFields = Object.keys(err.error);
                errorFields.filter(key => {
                    if (key.includes('code')) {
                        this.promoCodeErrMessage = err.error[key][0];
                    }
                });
            }
            finally {
                this.loaderService.hideAsyncLoader();
            }
        });
    }
    gotoCheckout() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const user = yield this.storeService.getItem('user');
            if (user) {
                if (!user.has_verified_email) {
                    this.toastService.showDangerToast('Please verify your email first to proceed.');
                    return;
                }
            }
            this.router.navigate(['/checkout']);
            this.modalService.dismissAll();
        });
    }
    gotoMenu() {
        this.router.navigate(['/menu/1']);
        this.modalCtrl.dismiss();
    }
}
