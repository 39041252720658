import * as tslib_1 from "tslib";
import { isPlatformBrowser } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "ngforage";
export class StoreService {
    constructor(storage, platformId) {
        this.storage = storage;
        this.platformId = platformId;
    }
    setItem(key, value) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (isPlatformBrowser(this.platformId)) {
                return yield this.storage.setItem(key, value);
            }
        });
    }
    getItem(key) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (isPlatformBrowser(this.platformId)) {
                return yield this.storage.getItem(key);
            }
        });
    }
    removeItem(key) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (isPlatformBrowser(this.platformId)) {
                return yield this.storage.removeItem(key);
            }
        });
    }
}
StoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StoreService_Factory() { return new StoreService(i0.ɵɵinject(i1.NgForage), i0.ɵɵinject(i0.PLATFORM_ID)); }, token: StoreService, providedIn: "root" });
