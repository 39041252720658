import { Component, Input, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "src/app/services/api/api.service";
import { CartService } from "src/app/services/cart/cart.service";
import { LoaderService } from "src/app/services/loader.service";
import { StoreService } from "src/app/services/store/store.service";
import { UserService } from "src/app/services/user/user.service";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-apc-card-input-modal",
  templateUrl: "./apc-card-input-modal.component.html",
  styleUrls: ["./apc-card-input-modal.component.scss"],
})
export class ApcCardInputModalComponent implements OnInit {
  apcCard: FormControl;

  apcBuyUrl = "/menu/1";

  @Input() withQuestions;
  @Input() isShowCardInput;

  constructor(
    public userService: UserService,
    public ngbModal: NgbModal,
    private apiService: ApiService,
    private storeService: StoreService,
    private cartService: CartService,
    private loaderService: LoaderService,
    private modalService: NgbModal
  ) {
    this.apcCard = new FormControl("", [
      Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(3),
    ]);
  }

  async ngOnInit() {
    // const res: any = await this.apiService.request({
    //   method: "GET",
    //   url: "/value-store/apc_card",
    // });

    // console.log("apc_card => ", res);

    // const apcCardCategory: string = res.apc_card_category || undefined;
    // console.log("apc_card_category => ", apcCardCategory);

    const apcCardCategory = this.cartService.buytake1Details.apc_card_category;

    console.log('apcCardCategory =>', apcCardCategory);

    if (!apcCardCategory) return;

    const branch = await this.storeService.getItem("branch");
    let headers = {};
    headers = {
      "x-branch": branch || "all",
    };
    // let url = `/v2/products?orderBy=order_column&limit=1&page=1`;
    let url = `/v2/products?sort=order_column&limit=1&page=1`;
    if (apcCardCategory) {
      // url = `${url}&search=${apcCardCategory}&searchFields=productCategories.segment`;
      url = `${url}&filter[productCategories.segment]=${apcCardCategory}`;
    }
    const productsRes: any = await this.apiService.request({
      method: "GET",
      url,
      headers,
    });
    console.log("apc url => ", productsRes);

    if (!productsRes.data) return;
    this.apcBuyUrl =
      productsRes && productsRes.data && productsRes.data.length > 0
        ? `/menu/product/${productsRes.data[0].id}`
        : this.apcBuyUrl;
    // this.apcCardCategory = res.apc_card_category;
    console.log("apc url => ", this.apcBuyUrl);
  }

  async saveApcCardNumber() {
    try {
      console.log("saving...");
      if (!this.apcCard.valid) {
        return;
      }
      console.log("apc card ",this.cartService.hasProducts());
      

      if (this.cartService.hasProducts()) {
        const modalComponent = this.modalService.open(ConfirmDialogComponent, {
          ariaLabelledBy: 'modal-basic-title',
          windowClass: 'confirm-dialog-modal',
          centered: true
        });
        modalComponent.componentInstance.title = '';
        modalComponent.componentInstance.buttonText.accept = 'Yes';
        modalComponent.componentInstance.buttonText.cancel = 'No';
        modalComponent.componentInstance.confirmationMessage = 'Adding APC Card would reset your cart and activate buy 1 take 1, do you want to proceed?';
        const res = await modalComponent.result;
  
        if (!res) return;
      }

      await this.userService.setApcCardNumber(this.apcCard.value);
      this.apcCard.reset();
      console.log("saved");
      await this.emptyCart();
      // window.location.reload();
    } catch (err) {
      console.log(err);
    }
  }

  async answer(value) {
    switch (value) {
      case "yes":
        this.isShowCardInput = true;
        break;
      case "avail":
        window.location.href = this.apcBuyUrl;
        break;
      default:
        console.log('default')
        await this.emptyCart();
        break;
    }
  }

  back() {
    this.withQuestions = true;
    this.isShowCardInput = false;
  }

  async emptyCart() {
    if (!this.cartService.cartStorage || !this.cartService.cartStorage.length) {
      window.location.reload();
      return;
    }
    const token = (await this.storeService.getItem("token")) || null;

    if (token) {
      try {
        this.loaderService.showAsyncLoader();
        await this.cartService.removeAllCartItems();
        this.loaderService.hideAsyncLoader();
      } catch (err) {
        console.log(err);
        this.loaderService.hideAsyncLoader();
      }
    }
    await this.cartService.emptyCart();
    window.location.reload();
  }
}
