import { HomeComponent } from './pages/home/home.component';
import { Routes, ExtraOptions } from '@angular/router';
const routerOptions = {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'disabled',
    scrollOffset: [0, 64],
};
const ɵ0 = {
    seo: {
        title: `Home`,
        description: `&quot;Growing from strength to strength,
                      Buddy’s Restaurant will remain to be the pioneer on all Filipino fast food chain that satisfies our guests....&quot;`,
        image: null
    }
}, ɵ1 = () => import("./pages/menu/menu.module.ngfactory").then(m => m.MenuModuleNgFactory), ɵ2 = {
    seo: {
        title: `Menu`,
        description: 'Checkout the menu for our tasty creations.',
        image: null
    }
}, ɵ3 = () => import("./pages/menu/menu.module.ngfactory").then(m => m.MenuModuleNgFactory), ɵ4 = () => import("./pages/menu/menu.module.ngfactory").then(m => m.MenuModuleNgFactory), ɵ5 = {
    seo: {
        title: `Menu`,
        description: 'Checkout the menu for our tasty creations.',
        image: null
    }
}, ɵ6 = () => import("./pages/menu/menu.module.ngfactory").then(m => m.MenuModuleNgFactory), ɵ7 = {
    seo: {
        title: `Promo`,
        description: 'Checkout the menu for our tasty creations.',
        image: null
    }
}, ɵ8 = () => import("./pages/menu/menu.module.ngfactory").then(m => m.MenuModuleNgFactory), ɵ9 = () => import("./pages/menu/menu.module.ngfactory").then(m => m.MenuModuleNgFactory), ɵ10 = {
    seo: {
        title: `Use My Coupon`,
        description: 'Checkout the menu for our tasty creations.',
        image: null
    }
}, ɵ11 = () => import("./pages/menu/menu.module.ngfactory").then(m => m.MenuModuleNgFactory), ɵ12 = () => import("./pages/menu/menu.module.ngfactory").then(m => m.MenuModuleNgFactory), ɵ13 = {
    seo: {
        title: `E-Gift Certificate`,
        description: 'Checkout the menu for our tasty creations.',
        image: null
    }
}, ɵ14 = () => import("./pages/forgot-password/forgot-password.module.ngfactory").then(m => m.ForgotPasswordModuleNgFactory), ɵ15 = {
    seo: {
        title: `Reset Password`,
        description: 'Reset your account password',
        image: null
    }
}, ɵ16 = () => import("./pages/about-us/about-us.module.ngfactory").then(m => m.AboutUsModuleNgFactory), ɵ17 = {
    seo: {
        title: `About Us`,
        description: null,
        image: null
    }
}, ɵ18 = () => import("./pages/contact-us/contact-us.module.ngfactory").then(m => m.ContactUsModuleNgFactory), ɵ19 = {
    seo: {
        title: `Contact Us`,
        description: null,
        image: null
    }
}, ɵ20 = () => import("./pages/payments/payments.module.ngfactory").then(m => m.PaymentsModuleNgFactory), ɵ21 = {
    seo: {
        title: `Payments`,
        description: null,
        image: null
    }
}, ɵ22 = () => import("./pages/account-dashboard/account-dashboard.module.ngfactory").then(m => m.AccountDashboardModuleNgFactory), ɵ23 = {
    seo: {
        title: `Account`,
        description: null,
        image: null
    }
}, ɵ24 = () => import("./pages/account/account.module.ngfactory").then(m => m.AccountModuleNgFactory), ɵ25 = {
    seo: {
        title: `Account`,
        description: null,
        image: null
    }
}, ɵ26 = () => import("./pages/favorite/favorite.module.ngfactory").then(m => m.FavoriteModuleNgFactory), ɵ27 = {
    seo: {
        title: `Favorite`,
        description: null,
        image: null
    }
}, ɵ28 = () => import("./pages/saved-addresses/saved-addresses.module.ngfactory").then(m => m.SavedAddressesModuleNgFactory), ɵ29 = {
    seo: {
        title: `Saved Addresses`,
        description: null,
        image: null
    }
}, ɵ30 = () => import("./pages/under-maintenance/under-maintenance.module.ngfactory").then(m => m.UnderMaintenanceModuleNgFactory), ɵ31 = {
    seo: {
        title: `Under Maintenance`,
        description: null,
        image: null
    }
}, ɵ32 = () => import("./pages/verify-email/verify-email.module.ngfactory").then(m => m.VerifyEmailModuleNgFactory), ɵ33 = {
    seo: {
        title: `Verify`,
        description: null,
        image: null
    }
}, ɵ34 = () => import("./pages/checkout/checkout.module.ngfactory").then(m => m.CheckoutModuleNgFactory), ɵ35 = {
    seo: {
        title: `Checkout`,
        description: null,
        image: null
    }
}, ɵ36 = () => import("./pages/locations/locations.module.ngfactory").then(m => m.LocationsModuleNgFactory), ɵ37 = {
    seo: {
        title: `Locations`,
        description: null,
        image: null
    }
}, ɵ38 = () => import("./pages/review-order/review-order.module.ngfactory").then(m => m.ReviewOrderModuleNgFactory), ɵ39 = {
    seo: {
        title: `Review Order`,
        description: null,
        image: null
    }
}, ɵ40 = () => import("./pages/order-history/order-history.module.ngfactory").then(m => m.OrderHistoryModuleNgFactory), ɵ41 = {
    seo: {
        title: `Order History`,
        description: null,
        image: null
    }
}, ɵ42 = () => import("./pages/news/news.module.ngfactory").then(m => m.NewsModuleNgFactory), ɵ43 = {
    seo: {
        title: `News`,
        description: null,
        image: null
    }
}, ɵ44 = () => import("./pages/apc-card/apc-card.module.ngfactory").then(m => m.ApcCardModuleNgFactory), ɵ45 = {
    seo: {
        title: `APC Card`,
        description: null,
        image: null
    }
}, ɵ46 = () => import("./pages/privacy-policy/privacy-policy.module.ngfactory").then(m => m.PrivacyPolicyModuleNgFactory), ɵ47 = () => import("./pages/terms-condition/terms-conditions.module.ngfactory").then(m => m.TermsConditionsModuleNgFactory), ɵ48 = () => import("./pages/error-page/error-page.module.ngfactory").then(m => m.ErrorPageModuleNgFactory), ɵ49 = {
    seo: {
        title: `404`,
        description: null,
        image: null
    }
}, ɵ50 = {
    seo: {
        title: `404`,
        description: null,
        image: null
    }
};
const routes = [
    {
        path: '',
        component: HomeComponent,
        // loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
        data: ɵ0
    },
    {
        path: 'menu/:page',
        // component: MenuComponent,
        loadChildren: ɵ1,
        data: ɵ2
    },
    {
        path: 'menu/product/:item_segment',
        loadChildren: ɵ3,
    },
    {
        path: 'menu/:page/:cat_segment',
        loadChildren: ɵ4,
        data: ɵ5
    },
    {
        path: 'use-my-coupon/:page',
        // component: MenuComponent,
        loadChildren: ɵ6,
        data: ɵ7
    },
    {
        path: 'use-my-coupon/product/:item_segment',
        loadChildren: ɵ8,
    },
    {
        path: 'use-my-coupon/:page/:cat_segment',
        loadChildren: ɵ9,
        data: ɵ10
    },
    {
        path: 'use-my-coupon/product/select/:voucher_code',
        loadChildren: ɵ11,
    },
    {
        path: 'e-gift-certificate/:page',
        // component: MenuComponent,
        loadChildren: ɵ12,
        data: ɵ13
    },
    // {
    //   path: 'login',
    //   loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
    //   data: {
    //     seo: {
    //       title: `Login`,
    //       description: `Login`,
    //       image: null
    //     }
    //   }
    // },
    // {
    //   path: 'register',
    //   loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule),
    //   data: {
    //     seo: {
    //       title: `Register`,
    //       description: `Sign Up`,
    //       image: null
    //     }
    //   }
    // },
    {
        path: 'reset-password',
        loadChildren: ɵ14,
        data: ɵ15
    },
    {
        path: 'about-us',
        loadChildren: ɵ16,
        data: ɵ17
    },
    {
        path: 'contact-us',
        loadChildren: ɵ18,
        data: ɵ19
    },
    {
        path: 'payments/:type',
        loadChildren: ɵ20,
        data: ɵ21
    },
    {
        path: 'account-dashboard',
        loadChildren: ɵ22,
        data: ɵ23
    },
    {
        path: 'account',
        loadChildren: ɵ24,
        data: ɵ25
    },
    {
        path: 'favorite',
        loadChildren: ɵ26,
        data: ɵ27
    },
    {
        path: 'saved-addresses',
        loadChildren: ɵ28,
        data: ɵ29
    },
    {
        path: 'under-maintenance',
        loadChildren: ɵ30,
        data: ɵ31
    },
    {
        path: 'verify',
        loadChildren: ɵ32,
        data: ɵ33
    },
    {
        path: 'checkout',
        loadChildren: ɵ34,
        data: ɵ35
    },
    // { 
    //   path: 'locations/:location_item',
    //   loadChildren: () => import('./pages/locations/locations.module').then(m => m.LocationsModule),
    //   data: {
    //     seo: {
    //       title: `Locations`,
    //       description: null,
    //       image: null
    //     }
    //   }
    // },
    {
        path: 'locations',
        loadChildren: ɵ36,
        data: ɵ37
    },
    {
        path: 'review-order',
        loadChildren: ɵ38,
        data: ɵ39
    },
    {
        path: 'order-history',
        loadChildren: ɵ40,
        data: ɵ41
    },
    {
        path: 'news',
        loadChildren: ɵ42,
        data: ɵ43
    },
    {
        path: 'apc-card',
        loadChildren: ɵ44,
        data: ɵ45
    },
    // { path: 'flavors', loadChildren: () => import('./pages/flavors/flavors.module').then(m => m.FlavorsModule) },
    // {
    //   path: 'verify',
    //   loadChildren: () => import('./pages/verify-email/verify-email.module').then(m => m.VerifyEmailModule),
    // }
    // { path: 'privacy-policy', loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
    {
        path: 'privacy-policy', loadChildren: ɵ46
    },
    { path: 'terms-condition', loadChildren: ɵ47 },
    {
        path: '404', loadChildren: ɵ48,
        data: ɵ49
    },
    {
        path: '**', redirectTo: '/404',
        data: ɵ50
    },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28, ɵ29, ɵ30, ɵ31, ɵ32, ɵ33, ɵ34, ɵ35, ɵ36, ɵ37, ɵ38, ɵ39, ɵ40, ɵ41, ɵ42, ɵ43, ɵ44, ɵ45, ɵ46, ɵ47, ɵ48, ɵ49, ɵ50 };
