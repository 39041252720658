/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./social-share-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./social-share-modal.component";
var styles_SocialShareModalComponent = [i0.styles];
var RenderType_SocialShareModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SocialShareModalComponent, data: {} });
export { RenderType_SocialShareModalComponent as RenderType_SocialShareModalComponent };
export function View_SocialShareModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["social-share-modal works!"]))], null, null); }
export function View_SocialShareModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-social-share-modal", [], null, null, null, View_SocialShareModalComponent_0, RenderType_SocialShareModalComponent)), i1.ɵdid(1, 114688, null, 0, i2.SocialShareModalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SocialShareModalComponentNgFactory = i1.ɵccf("app-social-share-modal", i2.SocialShareModalComponent, View_SocialShareModalComponent_Host_0, {}, {}, []);
export { SocialShareModalComponentNgFactory as SocialShareModalComponentNgFactory };
