import { TemplateRef } from '@angular/core';
import * as i0 from "@angular/core";
export class ToastService {
    constructor() {
        this.toasts = [];
    }
    show(textOrTpl, options = {}) {
        this.toasts.push(Object.assign({ textOrTpl }, options));
    }
    remove(toast) {
        this.toasts = this.toasts.filter(t => t !== toast);
    }
    showSuccessToast(message) {
        this.show(message, { classname: 'bg-success text-light' });
    }
    showDangerToast(message, delay) {
        this.show(message, { classname: 'bg-danger text-light', delay });
    }
    showWarningToast(message, delay) {
        this.show(message, { classname: 'bg-warning text-light', delay });
    }
}
ToastService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ToastService_Factory() { return new ToastService(); }, token: ToastService, providedIn: "root" });
