import { DOCUMENT } from '@angular/common';
import { Inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {
  constructor(@Inject(DOCUMENT) private document: Document) {}
  transform(media, type, conversion?): any {
    if (Array.isArray(media)) {
      media = media[0];
    }
    if (!media) {
      return;
    }

    if (type === 'src') {
      if (media && media.generated_conversions) {
        return media.generated_conversions.hasOwnProperty(conversion)
          ? `${media.generated_conversions[conversion]}`
          : `${media.original_file}`;
      } else {
        return media;
        // return `${GlobalService.BASE_URL_IMG}${media ? '' : '/'}${media}`;
      }
    }
    let propertyKeys;
    if (media.custom_properties) {
      propertyKeys = Object.keys(media.custom_properties);
    }

    if (type === 'alt' && propertyKeys.includes('html_attributes')) {

      const htmlAttributes = media.custom_properties['html_attributes'];
      return (htmlAttributes ? htmlAttributes.alt : '');
    } else if (type === 'alt' && !propertyKeys.includes('html_attributes')) {
      return '';
    }

    if (type === 'title' && propertyKeys.includes('html_attributes')) {
      const htmlAttributes = media.custom_properties['html_attributes'];
      return (htmlAttributes ? htmlAttributes.title : '');
    } else if (type === 'title' && !propertyKeys.includes('html_attributes')) {
      return '';
    }

    if (type === 'url' && propertyKeys.includes('url')) {
      return media.custom_properties['url']
    }

    if (type === 'relative') {
      return new URL(media, this.document.location.origin).href;
    }
    
    return null;
  }

}
