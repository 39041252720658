import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormValidationService {

  constructor() { }

  static MatchPassword(abstractControl: AbstractControl) {
    const newPassword = abstractControl.get('password').value;
    const confirmPassword = abstractControl.get('password_confirmation').value;
    if (newPassword !== confirmPassword) {
      abstractControl.get('password_confirmation').setErrors({ MatchPassword: true });
    } else {
      if (newPassword && confirmPassword) {
        abstractControl.get('password_confirmation').setErrors(null);
      }
    }
  }

  static NoWhitespaceValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value.trim().length === 0) {
        return { required: true };
    }
    return null;
  }
}
