import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {

  state; // menu state if open or close
  tmpElement; // store sub element to be reset when navigate
  userData; // user datails and token

  _menus;
  get menus() {
    return this._menus;
  }

  @Input('menus')
  set menus(value) {
    this._menus = value;
  }

  @Input('menuState')
  set menuState(value) {
    this.state = value;
  }

  @Input('userData')
  set userDetails(value) {
    this.userData = value;
  }

  @Output() closeMenu = new EventEmitter<any>();

  @Output() logout = new EventEmitter<any>();

  activeSub: string =  '';

  constructor(
    public router: Router,
    private modalService: NgbModal
  ) { }

  ngOnInit() { }

  onCloseMenu() {
    this.state = false;
    this.closeMenu.emit(false);
  }

  navigate(slug) {
    this.router.navigate([slug]);
    this.onCloseMenu();
    this.activeSub = '';
    this.modalService.dismissAll();
  }

  logoutUser() {
    this.logout.emit(true);
  }

}
