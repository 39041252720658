/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./back-to-top.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./back-to-top.component";
import * as i4 from "../../services/scroll-listener/scroll-listener.service";
var styles_BackToTopComponent = [i0.styles];
var RenderType_BackToTopComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BackToTopComponent, data: {} });
export { RenderType_BackToTopComponent as RenderType_BackToTopComponent };
function View_BackToTopComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "back-to-top"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "d-none d-md-block": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["class", "btn btn-primary"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scrollTop() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [["class", "custom-icon-arrow-down rotate"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "back-to-top"; var currVal_1 = _ck(_v, 3, 0, _co.routerUrl.includes("menu")); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_BackToTopComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BackToTopComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.scrollTopValue > 200); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BackToTopComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-back-to-top", [], null, null, null, View_BackToTopComponent_0, RenderType_BackToTopComponent)), i1.ɵdid(1, 114688, null, 0, i3.BackToTopComponent, [i1.PLATFORM_ID, i4.ScrollListenerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BackToTopComponentNgFactory = i1.ɵccf("app-back-to-top", i3.BackToTopComponent, View_BackToTopComponent_Host_0, { routerUrl: "routerUrl" }, {}, []);
export { BackToTopComponentNgFactory as BackToTopComponentNgFactory };
