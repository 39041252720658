import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loaderSubject = new BehaviorSubject<any>({show: false});
  loaderState = this.loaderSubject.asObservable();

  private asyncLoaderSubject = new BehaviorSubject<any>({show: false});
  asyncLoaderState = this.asyncLoaderSubject.asObservable();
  constructor() { }

  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  show() {
    this.loaderSubject.next({ show: true });
  }
  hide() {
    this.loaderSubject.next({ show: false });
  }

  showAsyncLoader() {
    this.asyncLoaderSubject.next({ show: true });
  }

  hideAsyncLoader() {
    this.asyncLoaderSubject.next({ show: false });
  }
}
