import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class WindowResizeService {
  viewportWidth = null;

  private resizeSubject: Subject<Window>;

  get OnResize$(): Observable<Window> {
    return this.resizeSubject.asObservable();
  }

  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private eventManager: EventManager
  ) {
    this.resizeSubject = new Subject();
    this.eventManager.addGlobalEventListener('window', 'resize', this.onResize.bind(this));
    this.setViewportWidth(platformId);
  }

  private onResize(event: UIEvent) {
    this.resizeSubject.next(event.target as Window);
  }

  setViewportWidth(platformId) {
    if (isPlatformBrowser(platformId)) {
      this.viewportWidth = window.innerWidth;
    }
  }
}
