import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
export class WindowResizeService {
    constructor(platformId, eventManager) {
        this.eventManager = eventManager;
        this.viewportWidth = null;
        this.resizeSubject = new Subject();
        this.eventManager.addGlobalEventListener('window', 'resize', this.onResize.bind(this));
        this.setViewportWidth(platformId);
    }
    get OnResize$() {
        return this.resizeSubject.asObservable();
    }
    onResize(event) {
        this.resizeSubject.next(event.target);
    }
    setViewportWidth(platformId) {
        if (isPlatformBrowser(platformId)) {
            this.viewportWidth = window.innerWidth;
        }
    }
}
WindowResizeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WindowResizeService_Factory() { return new WindowResizeService(i0.ɵɵinject(i0.PLATFORM_ID), i0.ɵɵinject(i1.EventManager)); }, token: WindowResizeService, providedIn: "root" });
