import * as tslib_1 from "tslib";
import { ConfirmDialogComponent } from './../confirm-dialog/confirm-dialog/confirm-dialog.component';
import { Validators } from '@angular/forms';
import { OnInit, EventEmitter } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { AccountAddressesComponent } from '../account-addresses/account-addresses.component';
export class LocationWidgetComponent {
    constructor(apiService, storeService, modalService, formBuilder, loaderService, cartService, toastService, userService, branchLocationService, router, filterPipe, customModalService, platformId) {
        this.apiService = apiService;
        this.storeService = storeService;
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.loaderService = loaderService;
        this.cartService = cartService;
        this.toastService = toastService;
        this.userService = userService;
        this.branchLocationService = branchLocationService;
        this.router = router;
        this.filterPipe = filterPipe;
        this.customModalService = customModalService;
        this.platformId = platformId;
        this.deliveryType = 'delivery';
        this.fullAddress = '';
        this.disableDropdownButtons = false;
        this.savedSuccess = false;
        this.addresses = {
            delivery: null,
            billing: null
        };
        this.isOnMenu = false;
        this.isOnCheckout = false;
        this.onCloseWidget = new EventEmitter();
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.cartService.isBranchValid();
            this.branchForm = this.formBuilder.group({
                province: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
                city: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
                brgy: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
                branch: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
                selected_branch: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
            });
            // this.getBranches();
            if (isPlatformBrowser(this.platformId)) {
                this.asyncInit();
                this.userService.userSubject.subscribe((user) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    yield this.branchLocationService.setUserBranchLocation();
                    yield this.setChosenBranchData();
                }));
            }
        });
    }
    getBranches() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const branchList = yield this.apiService.request({
                    method: 'GET',
                    url: '/branches',
                    jsona: true
                });
                const sortedBranch = branchList.sort((a, b) => a.title.localeCompare(b.title));
                this.branches = sortedBranch;
                console.log('branches => ', this.branches);
            }
            catch (err) {
                console.log(err);
            }
        });
    }
    asyncInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const deliveryType = yield this.storeService.getItem('deliveryType');
            const branchStore = yield this.storeService.getItem('branchLocation');
            yield this.userService.getUser(true);
            const userStore = yield this.storeService.getItem('user');
            console.log(userStore);
            this.branch = branchStore;
            if (deliveryType) {
                this.deliveryType = deliveryType;
            }
            if (userStore) {
                this.user = true;
                if (userStore.addresses['length']) {
                    userStore.addresses.filter(address => {
                        if (address.type === 'delivery') {
                            this.addresses.delivery = address;
                        }
                        else {
                            this.addresses.billing = address;
                        }
                    });
                }
            }
            else {
                this.user = false;
            }
            // if (this.deliveryType === 'delivery') {
            //   await this.getProvinces();
            // } else {
            //   this.getBranches();
            // }
            this.addressDetails = userStore;
            yield this.setChosenBranchData();
            yield this.deliveryTypeChange(this.deliveryType);
            if (this.isOnCheckout) {
                this.disableDropdownButtons = false;
            }
        });
    }
    setChosenBranchData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const branchLocation = yield this.storeService.getItem('branchLocation');
            if (!branchLocation) {
                return;
            }
            // if (!branchLocation.city) {
            //   return;
            // }
            this.disableDropdownButtons = true;
            delete branchLocation.isUser;
            this.branchForm.setValue(Object.assign({}, branchLocation));
            this.branchForm.updateValueAndValidity();
        });
    }
    getProvinces() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // this.loaderService.showAsyncLoader();
                this.isLoading = true;
                const provinces = yield this.apiService.request({
                    method: 'GET',
                    url: '/provinces',
                    jsona: true
                });
                this.provinces = provinces;
                this.branchForm.get('province').setValue(provinces);
                this.branchForm.get('province').updateValueAndValidity();
                console.log(provinces);
                yield this.updateBranchLocationStore(provinces);
                // this.loaderService.hideAsyncLoader();
                this.isLoading = false;
            }
            catch (err) {
                // this.loaderService.hideAsyncLoader();
                this.isLoading = false;
                this.toastService.showDangerToast('Failed to load locations, Please try again.');
                this.modalService.dismissAll();
                console.log(err);
            }
        });
    }
    setBranch(branchObj, type) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.branchForm.get(type).value) {
                let keys = [];
                switch (type) {
                    case 'province':
                        keys = ['city', 'brgy', 'branch', 'selected_branch'];
                        break;
                    case 'city':
                        keys = ['brgy', 'branch', 'selected_branch'];
                        break;
                    case 'brgy':
                        keys = ['branch', 'selected_branch'];
                        break;
                    case 'branch':
                        keys = ['selected_branch'];
                        break;
                    case 'selected_branch':
                        keys = [];
                        break;
                    default:
                        break;
                }
                keys.map(key => {
                    this.branchForm.get(key).setValue(null);
                    this.branchForm.get(key).updateValueAndValidity();
                });
            }
            if (branchObj.type === 'provinces') {
                let provinces = yield this.apiService.request({
                    method: 'GET',
                    url: `/provinces/${branchObj.id}?include=cities`,
                    jsona: true
                });
                this.provinces = provinces;
                console.log(provinces);
                console.log(this.branchForm.value);
                this.branchForm.get('city').setValue(provinces);
                this.branchForm.get('city').updateValueAndValidity();
                yield this.updateBranchLocationStore(provinces);
                console.log(this.branchForm.value);
            }
            else if (branchObj.type === 'cities') {
                let city = yield this.apiService.request({
                    method: 'GET',
                    url: `/cities/${branchObj.id}?include=barangays`,
                    jsona: true
                });
                console.log(city);
                console.log(this.branchForm.value);
                this.branchForm.get(type).setValue(city);
                this.branchForm.get(type).updateValueAndValidity();
                console.log(this.branchForm.value);
            }
            else if (branchObj.type === 'barangays') {
                let barangay = yield this.apiService.request({
                    method: 'GET',
                    url: `/barangays/${branchObj.id}?include=branches`,
                    jsona: true
                });
                branchObj = barangay;
                console.log(barangay);
                console.log(this.branchForm.value);
                this.branchForm.get(type).setValue(barangay);
                this.branchForm.get(type).updateValueAndValidity();
                console.log(this.branchForm.value);
            }
            else {
                this.branchForm.get(type).setValue(branchObj);
                this.branchForm.get(type).updateValueAndValidity();
            }
            if (type === 'branch' && this.deliveryType === 'delivery') { // temporary fix only
                const firstBranch = branchObj.branches[0];
                console.log(firstBranch);
                this.branchForm.get('selected_branch').setValue(firstBranch);
                this.branchForm.get('selected_branch').updateValueAndValidity();
            }
            if (type === 'selected_branch' && this.deliveryType === 'pick-up') {
                this.branchForm.get('selected_branch').setValue(branchObj);
                this.branchForm.get('selected_branch').updateValueAndValidity();
            }
        });
    }
    setSelectedBranch() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.branchForm.valid && this.deliveryType === 'delivery') {
                return;
            }
            if (!this.branchForm.get('selected_branch').value && this.deliveryType === 'pick-up') {
                return;
            }
            try {
                console.log('branch form: ', this.branchForm.value);
                if (this.deliveryType === 'delivery') {
                    yield this.storeService.setItem('brgy_segment', this.branchForm.value.branch.segment);
                }
                const selectedBranch = this.branchForm.get('selected_branch').value;
                const user = yield this.storeService.getItem('user');
                yield this.storeService.setItem('branchLocation', Object.assign({}, this.branchForm.value, { isUser: false }));
                yield this.storeService.setItem('deliveryType', this.deliveryType);
                this.cartService.changeDeliveryType(this.deliveryType);
                this.loaderService.showAsyncLoader();
                const storedBranchSegment = yield this.storeService.getItem('branch');
                if (storedBranchSegment !== selectedBranch.segment) {
                    if (this.deliveryType === 'pick-up') {
                        const branchForm = this.branchForm.value;
                        ['branch', 'brgy', 'city'].filter(key => branchForm[key] = null);
                        yield this.storeService.setItem('branchLocation', Object.assign({}, branchForm, { isUser: false }));
                        yield this.storeService.removeItem('brgy_segment');
                    }
                    if (Array.isArray(this.cartService.cartStorage)) {
                        if (this.cartService.cartStorage.length) {
                            if (user) {
                                yield this.cartService.removeAllCartItems();
                            }
                            else {
                                yield this.cartService.emptyCart();
                            }
                        }
                    }
                }
                yield this.storeService.setItem('branch', selectedBranch.segment);
                yield this.storeService.removeItem('checkout_details');
                // await this.modifyCheckoutDetails(this.branchForm.value);
                this.savedSuccess = true;
                setTimeout(() => {
                    // this.modalService.dismissAll();
                    this.loaderService.hideAsyncLoader();
                    if (this.isOnCheckout) {
                        this.modalService.dismissAll();
                        if (storedBranchSegment !== selectedBranch.segment) {
                            this.router.navigate(['/menu/1']).then(() => {
                                window.location.reload();
                            });
                        }
                        else {
                            window.location.reload();
                        }
                        return;
                    }
                    // window.location.reload();
                    this.customModalService.openApcCardInputModal(true);
                }, 500);
            }
            catch (err) {
                this.loaderService.hideAsyncLoader();
                this.toastService.showDangerToast('Failed to change branch, Please try again.');
                console.log(err);
            }
        });
    }
    modifyCheckoutDetails(branchValue) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const checkoutDetails = yield this.storeService.getItem('checkout_details');
            if (!checkoutDetails) {
                return;
            }
            checkoutDetails.delivery_barangay = branchValue.branch;
            checkoutDetails.delivery_city = branchValue.brgy;
            checkoutDetails.delivery_state = branchValue.city;
        });
    }
    updateBranchLocationStore(provinces) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const branchLocation = yield this.storeService.getItem('branchLocation');
            if (!branchLocation) {
                return;
            }
            if (!provinces) {
                return;
            }
            if (!branchLocation.city) {
                return;
            }
            console.log(provinces);
            branchLocation.province = provinces;
            yield this.storeService.setItem('branchLocation', branchLocation);
        });
    }
    removeDisabledState(type) {
        if (Array.isArray(this.cartService.cartStorage)) {
            if (this.cartService.cartStorage.length) {
                this.disableDropdownButtons = false;
                return;
            }
        }
        this.disableDropdownButtons = false;
    }
    openSavedAddress() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (isPlatformBrowser(this.platformId)) {
                const modalComponent = this.modalService.open(AccountAddressesComponent, {
                    ariaLabelledBy: 'modal-basic-title',
                    windowClass: 'modal-cart',
                    backdropClass: 'menuitem-backdrop',
                    centered: true,
                });
                const newAddress = true;
                modalComponent.componentInstance.modalCtrl = modalComponent;
                modalComponent.componentInstance.newAddress = newAddress;
                modalComponent.componentInstance.addressDetails = this.addressDetails;
            }
        });
    }
    closeWidget() {
        this.onCloseWidget.emit('');
    }
    changeLocation(type) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const modalCtrl = this.modalService.open(ConfirmDialogComponent, {
                    ariaLabelledBy: 'modal-basic-title',
                    windowClass: 'postcode-confirmation-modal',
                    size: 'lg',
                    centered: true
                });
                // If you Change your location, all items in the cart might be <b>removed.</b>
                modalCtrl.componentInstance.confirmationMessage = `
        Product availability may vary if you change your current location.
        <br/><br/>
        Do you want to proceed?
      `;
                modalCtrl.componentInstance.title = 'Notice';
                modalCtrl.componentInstance.buttonText.accept = `Yes`;
                modalCtrl.result.then(res => {
                    if (!res) {
                        return;
                    }
                    this.router.navigate(['/saved-addresses']);
                    this.modalService.dismissAll();
                });
            }
            catch (err) {
                console.log(err);
            }
        });
    }
    deliveryTypeChange(ev) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // this.branchForm.reset();
            try {
                // this.loaderService.showAsyncLoader();      
                const token = yield this.storeService.getItem('token');
                const branchLocation = yield this.storeService.getItem('branchLocation');
                console.log('des', this.deliveryType, this.disableDropdownButtons, branchLocation);
                if (!branchLocation || !branchLocation['city']) {
                    this.disableDropdownButtons = false;
                }
                if (ev === 'delivery' && !this.provinces) {
                    console.log(this.branch, this.addressDetails);
                    yield this.getProvinces();
                    // branchLocation.branch = this.addressDetails.profile.barangay
                    // branchLocation.brgy = this.addressDetails.profile.city
                    // branchLocation.city = this.addressDetails.profile.province
                    // console.log('branch location => ', branchLocation, this.addressDetails);                 
                    // await this.storeService.setItem('branchLocation', branchLocation);
                    if (!this.branch.brgy && this.user) {
                        this.changeLocation();
                    }
                }
                if (ev === 'pick-up' && !this.branches) {
                    yield this.getBranches();
                    console.log('branch location => ', branchLocation, this.addressDetails);
                    let selectedBranchExists = false;
                    this.filterPipe.transform(this.branches, 'pick_up', true).forEach(b => {
                        console.log(b);
                        console.log(branchLocation.selected_branch.id);
                        console.log(b.id == branchLocation.selected_branch.id);
                        if (b.id == branchLocation.selected_branch.id) {
                            selectedBranchExists = true;
                        }
                    });
                    if (!selectedBranchExists) {
                        this.disableDropdownButtons = false;
                        this.branchForm.get('selected_branch').setValue(null);
                    }
                    else {
                        this.disableDropdownButtons = true;
                    }
                }
                if (this.provinces) {
                    this.branchForm.get('province').setValue(this.provinces);
                }
                yield this.storeService.setItem('deliveryType', this.deliveryType);
                this.cartService.changeDeliveryType(this.deliveryType);
                if ((!branchLocation || !branchLocation['city']) && this.deliveryType === 'delivery') {
                    return;
                }
                //await this.cartService.recalculateTotal();
                console.log('des', this.disableDropdownButtons);
            }
            catch (err) {
                console.log(err);
            }
            finally {
                // this.loaderService.hideAsyncLoader();
            }
            // console.log(ev);
        });
    }
}
